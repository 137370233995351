<template>
  <div class="loader">
    <div v-if="text==='Loading...'" class="anim-text">
      <span>Loading</span><span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span>
    </div>
    <span v-else class="custom-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Loading...',
    },
  },
  mounted() {
    this.$el.parentNode.style.position = 'relative'
  },
}
</script>

<style lang="stylus" scoped>
.loader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(248, 248, 248, 0.7)
  m-font-size(20, 22)

  .custom-text,
  .anim-text
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.dot1
  animation: visibility 2s linear infinite

  @keyframes visibility
    0%
      opacity: 1

    65%
      opacity: 1

    66%
      opacity: 0

    100%
      opacity: 0

.dot2
  animation: visibility2 2s linear infinite

  @keyframes visibility2
    0%
      opacity: 0

    21%
      opacity: 0

    22%
      opacity: 1

    65%
      opacity: 1

    66%
      opacity: 0

    100%
      opacity: 0

.dot3
  animation: visibility3 2s linear infinite

  @keyframes visibility3
    0%
      opacity: 0

    43%
      opacity: 0

    44%
      opacity: 1

    65%
      opacity: 1

    66%
      opacity: 0

    100%
      opacity: 0
</style>

// import { getSensorsList, getCountriesList } from '../services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
// export function getFilterDefs() {
//   let filterMetaDefs = [
//     {
//       id: 'sensors',
//       type: 'normal',
//       multiple: false,
//       optionsGetter: getSensorsList,
//     },
//     {
//       id: 'countries',
//       type: 'normal',
//       multiple: true,
//       optionsGetter: getCountriesList,
//     },
//     {
//       id: 'timePeriod',
//       type: 'date',
//       optionsGetter: () => {
//         return []
//       },
//     },
//   ]

//   let promises = filterMetaDefs.map(meta => {
//     return meta.optionsGetter()
//   })
//   return Promise.all(promises).then(responses => {
//     let filterDefs = {}
//     responses.forEach((resp, i) => {
//       filterDefs[filterMetaDefs[i].id] = {
//         ...filterMetaDefs[i],
//         options: resp.data || resp,
//       }
//     })
//     return Object.values(filterDefs)
//   })
// }

// // no se si es muy util en este proyecto (es para cuando hay "filtrso que dependen de filtros")
// // export function validateValues(value, defs) {
// //   let wasInvalid = false
// //   let valid = Object.keys(value).reduce((res, key) => {
// //     res[key] = value[key].filter(v => {
// //       let def = defs.find(fd => fd.id === key)
// //       return def.options.findIndex(o => o.id === v) >= 0
// //     })
// //     if (res[key].length === 0) delete res[key]
// //     if (!res[key] || res[key].length !== value[key].length) wasInvalid = true
// //     return res
// //   }, {})
// //   // hay que hacerlo "asi" porque sino entra en bucles infinitos!!!
// //   if (wasInvalid) return valid
// //   else return null //this.$emit('change', tmp)
// // }

// export function validateValues(value) {
//   return value
// }

// function uniq(array, getter = d => d.id) {
//   return array.filter((d, i) => array.findIndex(dd => getter(dd) === getter(d)) === i)
// }

export function transformToPreviousPeriod(filters) {
  let diff = dayjs(filters.to).diff(dayjs(filters.from), 'day')
  return {
    ...filters,
    from: dayjs(filters.from)
      .utc()
      .subtract(diff + 1, 'day')
      .startOf('day')
      .toDate(),
    to: dayjs(filters.from).utc().subtract(1, 'day').endOf('day').toDate(),
  }
}
/**
 * set UTC HH:MM:SS:SSSS to 0
 * @param {Date} date
 * @return {Date} same input object
 */
export function setUTCHHMMSSToZero(date) {
  date.setUTCHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

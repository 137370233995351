<template>
  <div class="sensor-ecostystem view">
    <sensor-network v-if="mode === 'network'" :dimension="currentDimension[0]" />
    <sensor-map v-else :dimension="currentDimension[0]" />
    <div class="ui">
      <div class="view-toggle" @click="toggleMode">{{ toggleText }}</div>
      <div class="select">
        <multi-select v-model="currentDimension" :options="dimensions" :multiple="false" />
        <div v-if="currentDimension.length > 0" class="kpi-info">
          <div class="info-icon" />
          <div class="kpi-info__text">{{ kpiInfoText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SensorNetwork from '../components/SensorNetwork.vue'
import MultiSelect from '../components/MultiSelect.vue'
import SensorMap from '../components/SensorMap.vue'
import IndicatorMixin from '../mixins/IndicatorMixin'
export default {
  components: { SensorNetwork, MultiSelect, SensorMap },
  mixins: [IndicatorMixin],
  props: {
    mode: {
      type: String,
      default: 'map',
    },
  },
  data() {
    return {
      currentDimension: ['vSessions'],
    }
  },
  computed: {
    dimensions() {
      return this.sessionKpis.map(id => {
        return { id: id, value: id, label: id }
      })
    },
    toggleText() {
      if (this.mode === 'map') return 'View as network'
      else return 'View as map'
    },
    kpiInfoText() {
      return this.$t(`kpi_info_${this.currentDimension}`)
    },
  },
  watch: {
    sessionKpis() {
      if (this.sessionKpis.length > 0 && this.currentDimension.some(kpi => !this.sessionKpis.includes(kpi)))
        this.currentDimension = ['vSessions']
    }
  },
  methods: {
    toggleMode() {
      if (this.mode === 'network') this.$router.push({ params: { mode: 'map' } })
      else this.$router.push({ params: { mode: 'network' } })
    },
  },
}
</script>

<style lang="stylus" scoped>
.sensor-ecostystem
  position: relative
  flex: 1 1 0%
  overflow: hidden
  background: #999

  .ui
    position: absolute
    top: 0
    left: 0
    display: flex
    align-items: center
    padding: vw(20px)
    width: 100%
    height: 100%
    pointer-events: none

    .view-toggle
      position: absolute
      top: vw(20px)
      left: 50%
      padding: vw(5px) vw(15px)
      padding-top: vw(7px)
      border: 1px solid
      border-radius: vw(20px)
      background-color: #444
      color: #fff
      cursor: pointer
      transform: translate(-50%, 0)
      pointer-events: all

    .select
      position: absolute
      top: vw(20px)
      left: vw(20px)
      pointer-events: all

      .multiselect
        margin-bottom: vw(10px)
        width: vw(200px)

      .kpi-info
        display: flex
        padding: vw(10px)
        width: vw(200px)
        border-radius: 5px
        background-color: rgba(#fff, 0.9)

        .kpi-info__text
          m-font-size(11, 14)

        .info-icon
          m-icon("info", 25)
          flex: 0 0 vw(25px)
          margin-top: -0.25em
          margin-right: vw(5px)
          margin-left: vw(-5px)

      .data-toggle
        margin-top: 10px
        color: #aaa
        text-align: center
        white-space: nowrap

        &.active
          // background: #444
          color: #444
</style>
<template>
  <div class="funnel-view" :class="{'comparing': comparing}">
    <div class="funnels-wrapper">
      <funnel-panel
        :id="'left'"
        v-model="mainFunnelFilters"
        class="left"
      />
      <div v-if="comparing" class="funnel-separator" />
      <funnel-panel
        v-if="comparing"
        :id="'right'"
        :starting-filters="mainFunnelFilters"
        class="right"
      />
    </div>
  </div>
</template>

<script>
import FunnelPanel from '@/components/funnel/FunnelPanel.vue'
// import Filters from '../components/Filters.vue'

export default {
  components: { FunnelPanel },
  data() {
    return {
      mainFunnelFilters: null,
      // mainFunnelConfig: { filters: [{ id: 1, value: 1 }] },
      // compareFunnelConfig: null,
      // comparing: true,
    }
  },
  computed: {
    comparing() {
      return this.$route.query.compare
    },
  },
  // methods: {
  //   toggleCompare() {
  //     this.comparing = !this.comparing
  //     if (this.comparing)
  //       this.compareFunnelConfig = JSON.parse(JSON.stringify(this.mainFunnelConfig))
  //   },
  // },
}
</script>

<style lang="stylus" scoped>
.funnels-wrapper
  display: flex
  overflow: hidden
  height: 100%
  // gap: vw(40px)

.funnel-separator
  width: 1px
  background-color: #000

.funnel-panel
  flex: 1 1 0%
  overflow-y: auto
  padding: vw(30px)
  padding-top: 0

  .comparing &
    padding-top: 0

.funnel-view
  height: 100%
</style>
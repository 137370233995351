<template>
  <multiselect
    id="id"
    v-model="internalValue"
    class="selector"
    :options="internalOptions"
    label="value"
    track-by="id"
    :show-labels="false"
    :multiple="multiple"
    :close-on-select="!multiple"
    :limit="1"
    :taggable="false"
    :loading="loading"
  >
    <template slot="selection" slot-scope="{ values,isOpen }"><span v-if="values.length " class="multiselect__single">{{ !isOpen ? selectionText : '' }}</span></template>
    <template v-if="multiple" slot="option" slot-scope="props">
      <div :ref="(props.option.id===null && selectAll) ? 'selectAll':'lastOption'" class="wrapper-option" :class="{['select-all']:props.option.id===null}">
        <checkbox v-if="!props.option.$isLabel && props.option.id!==null" :value="isSelected(props.option)" />
        <div class="option-text" :class="{['select-all-option']: props.option.id===null }">
          {{ props.option.$isLabel ? props.option.$groupLabel: props.option.value }}
        </div>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Checkbox from './Checkbox.vue'
const SELECT_ALL = -1
const UNSELECT_ALL = -2
export default {
  components: { Multiselect, Checkbox },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: [Array, String],
    options: Array,
    multiple: Boolean,
    loading: Boolean,
    checkox: { type: Boolean, default: true },
    selectAll: { type: Boolean, default: true },
    selectAllName: { type: String },
    
  },
  data() {
    return {
      // selectedAll: false,
    }
  },
  computed: {
    selectedAll(){
      return this.internalValue.length === this.nonDisabledOptions.length
    },
    hasDisableOption(){
      return this.nonDisabledOptions.length !== this.options.length
    },
    internalOptions() {
      if (this.selectAll && this.multiple)
        return [
          {
            id: !this.selectedAll ? SELECT_ALL : UNSELECT_ALL,
            value:
              this.selectAllName ?? !this.selectedAll
                ? this.$t('filter_select_all')
                : this.$t('filter_deselect_all'),
            fixed: true,
            $isDisabled:(this.hasDisableOption && this.internalValue.length===0)
          },
          ...this.options,
        ]
      return this.options
    },
    nonDisabledOptions() {
      return this.options.filter(o => o.id && !o.$isDisabled).map(o => o.id)
    },
    internalValue: {
      get() {
        let arr = this.value ? (Array.isArray(this.value) ? this.value : [this.value]) : []
        // if we dont have any selected and user click to selectedAll, we need to se all selected
        // if (arr.length === 0 && this.selectedAll) return this.internalOptions
        return this.options.filter(o => arr.indexOf(o.id) >= 0)
      },
      set(value) {
        // Is array and select all is activated
        if (Array.isArray(value) && this.selectAll) {
          // Click to Select All 
          if (value.some(d => d.id === SELECT_ALL)) {
            return this.$emit('change', this.nonDisabledOptions)
          }
          // Click Unselect all
          else if(value.some(d => d.id === UNSELECT_ALL)){
            return this.$emit('change', [])
          }
        }
        let arr = value ? (Array.isArray(value) ? value.map(d => d.id) : [value.id]) : []
        this.$emit('change', arr)
      },
    },
    selectionText() {
      if (this.internalValue.length === 1) {
        return this.internalValue[0].value
      }
      return `${this.selectedAll ? this.nonDisabledOptions.length : this.internalValue.length} ${this.$t('filter_options_selected')}`
    },
  },
  watch: {
    // internalValue(val, prev) {
    //   const div = this.$refs.selectAll
    //   if (JSON.stringify(val.map(({ id }) => id)) === JSON.stringify(prev.map(({ id }) => id)) ) return
    //   if (this.internalValue && JSON.stringify(this.internalValue.map(({ id }) => id)) === JSON.stringify(this.nonDisabledOptions)) {
    //     this.selectedAll = true
    //   } else {
    //     this.selectedAll = false
    //   }
    //   if (!this.selectAll && div) {
    //     div.parentElement.parentElement.classList.remove('select-all-wrapper')
    //   }
    // }
  },
  updated() {
    const div = this.$refs.selectAll
    if (this.selectAll && div) {
      div.parentElement.parentElement.classList.add('select-all-wrapper')
    }
  },
  methods: {
    isSelected(opt) {
      return !!this.internalValue.find(e => e.id === opt.id)
    },
    handleClickOption(opt) {
      const selectedIndex = this.internalValue.indexOf(e => e.id === e.opt.id)
      if (!selectedIndex < 0) this.internalValue = [...this.internalValue, opt]
      else {
        const copy = [...this.internalValue]
        copy.splice(selectedIndex, 1)
        this.internalValue = copy
      }
    },
  },
}
</script>
<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style lang="stylus" scoped>
.selector
  >>>.multiselect__content
    display: block !important

    .multiselect__element
      &.select-all-wrapper
        &:hover
          span
            background-color: $cararra !important

        span
          padding: vw(5px)
          border-bottom: 1px solid
          background-color: #fff !important
          color: #000 !important
          font-weight: normal

  .wrapper-option
    display: flex

    .option-text
      m-font-size(12, 16)
      overflow: hidden

      &.select-all-option
        margin: auto
        padding-top: vw(10px)
        padding-bottom: vw(10px)

    .fake-checkbox
      $size = vw(20px)
      width: $size
      height: $size
      border: 1px solid #000
      border-radius: 2px
      background-color: #fff

      &.selected
        background-color: #222
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
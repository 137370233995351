<template>
  <div class="interactive-graph" :class="{ isHighPositioned }">
    <svg
      ref="svg"
      :width="size.width"
      :height="size.height"
    />
    <div v-if="tooltipData" class="pop-tooltip" :style="{ top: tooltipData.y+'px', left: tooltipData.x+'px'}">
      <PopularityIndexDetails
        :date="tooltipData.date"
        :data="tooltipData.datum"
        :display-kpi="displayKpi"
      />
    </div>
  </div>
</template>

<script>
import PopularityIndexDetails from './PopularityIndexDetails.vue'
import Size from '@/mixins/Size'
import { select as d3_select, pointer as d3_pointer } from 'd3-selection'
import { scaleLinear as d3_scaleLinear, scaleTime as d3_scaleTime } from 'd3-scale'
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis'
import { timeDay as d3_timeDay } from 'd3-time'
import { max as d3_max, extent as d3_extent } from 'd3-array'
import { line as d3_line } from 'd3-shape'
// eslint-disable-next-line
import { transition } from 'd3-transition'
import IndicatorMixin from '../../mixins/IndicatorMixin'

function padLinear([x0, x1], k) {
  const dx = ((x1 - x0) * k) / 2
  return [x0 - dx, x1 + dx]
}
export default {
  components: {
    PopularityIndexDetails,
  },
  mixins: [Size, IndicatorMixin],
  props: {
    dataProps: Array,
    refDataProps: Array,
    backgroundLines: Boolean,
    showTickValues: Boolean,
    entityType: String,
    displayKpi: {
      type: String,
      required: false,
      default: 'vPopIndex',
    },
  },
  data() {
    return {
      tooltipData: null,
      isHighPositioned: false,
      latestFocus: null,
    }
  },
  computed: {
    data() {
      return [
        {
          id: 'only one',
          values: this.dataProps.map(d => {
            d.date.setHours(0) //forzamos hora 00:00 para que quede bien con los grid-lines
            return {
              x: d.date,
              y: d[this.displayKpi],
              originalData: d,
            }
          }),
        },
      ]
    },
  },
  watch: {
    data() {
      this.latestFocus = this.data[0].values.slice(-1)[0].datum
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  created() {
    window.addEventListener('wheel', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll)
  },
  mounted() {
    this.latestFocus = this.data[0].values.slice(-1)[0]
    this.$emit('move', this.latestFocus.x)
    this.handleScroll()
  },
  methods: {
    handleScroll() {
      const headerHeight = document.querySelector('.header').offsetHeight
      const graphYPosRelativeToHeader = this.$refs.svg.getBoundingClientRect().top - headerHeight
      const viewportHeightWithoutHeader = window.innerHeight - headerHeight
      this.isHighPositioned = graphYPosRelativeToHeader < viewportHeightWithoutHeader / 2
    },
    draw() {
      // let currentHeight = this.size.height
      let data = this.data
      let svg = d3_select(this.$refs.svg)
      let root = svg.selectAll('.root').data([null])
      let rootEnter = root.enter().append('g').attr('class', 'root')

      let padding = {
        top: this.backgroundLines ? 10 : 0,
        right: this.backgroundLines ? 5 : 0,
        bottom: this.backgroundLines ? 15 : 0,
        left: this.backgroundLines ? 20 : 0,
      }

      rootEnter.append('g').attr('class', 'xAxis')
      rootEnter.append('g').attr('class', 'yAxis')
      let focusEnter = rootEnter.append('g').attr('class', 'focus')
      focusEnter.append('line').attr('class', 'line-focus')
      focusEnter.append('circle').attr('class', 'circle-focus')
      focusEnter.style('pointer-events', 'none')

      rootEnter.append('g').attr('class', 'content')
      rootEnter
        .append('rect')
        .attr('class', 'sensor')
        .attr('fill', 'transparent')
        .on('mousemove', mousemove)
        .on('mouseover', mouseover)
        .on('mouseout', mouseout)

      root = root.merge(rootEnter)

      root.select('.sensor').attr('width', this.size.width).attr('height', this.size.height)
      let items = root
        .select('.content')
        .selectAll('.item')
        .data(this.data, d => d.id)
      let chartArea = {
        width: parseInt(this.size.width) - padding.left - padding.right,
        height: parseInt(this.size.height) - padding.top - padding.bottom,
      }
      let maxVal = d3_max(this.data[0].values, d => d.y)
      let extent = d3_extent(this.data[0].values, d => d.y)
      let yScale = d3_scaleLinear().domain(padLinear(extent, 0.2)).range([chartArea.height, 0])

      let xScale = d3_scaleTime()
        .domain(d3_extent(this.data[0].values, d => d.x))
        .range([0, chartArea.width])

      if (this.backgroundLines) {
        //xAxis
        root
          .select('.xAxis')
          .attr(
            'transform',
            'translate(' + padding.left + ',' + (chartArea.height + padding.top) + ')'
          )
          .call(
            d3_axisBottom(xScale)
              // .ticks(6)
              .ticks(d3_timeDay.every(1))
              //.tickFormat(d => (this.showTickValues ? d3_timeFormat('%d')(d) : ''))
              // .tickFormat(() => '')
              .tickSize(-chartArea.height)
          )

        // yAxis
        root
          .select('.yAxis')
          .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
          .call(d3_axisLeft(yScale).tickSize(-chartArea.width))
      }

      const xValue = d => d.x
      const yValue = d => d.y

      const lineGenerator = d3_line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))

      let itemsEnter = items.enter().append('g').attr('class', 'item')

      itemsEnter
        .append('path')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
        .attr('class', 'graph__line graph__line--blue')
        .attr('d', d => lineGenerator(d.values))

      const focus = root
        .select('.focus')
        // .style('opacity', 0)
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      // .attr('class', 'focus')

      focus
        .select('.line-focus')

        .attr('x1', 0)
        .attr('y1', yScale(0))
        .attr('x2', 0)
        .attr('y2', yScale(maxVal))
        .style('stroke-width', 1)
        .attr('stroke', '#000')
        .style('fill', 'none')
        .style('transition', 'stroke 0.3s linear')

      focus
        .select('.circle-focus')

        .style('fill', '#FFF')
        .attr('stroke', '#000')
        .attr('r', 3)

      let itemsExit = items.exit()
      itemsExit.remove()

      items = items.merge(itemsEnter)

      let itemsTrans = items.transition()
      itemsTrans.attr(
        'transform',
        d => `translate(${d.x ? d.x : 0 * this.size.width},${d.y ? d.y : 0 * this.size.height})`
      )

      itemsTrans.select('path').attr('d', d => lineGenerator(d.values))

      let self = this
      this.xScale = xScale
      this.yScale = yScale

      function updateFocus() {
        root
          .select('.focus')
          .select('.line-focus')
          .attr('transform', `translate(${self.xScale(self.latestFocus.x)},0)`)
        root
          .select('.focus')
          .select('.circle-focus')
          .attr(
            'transform',
            `translate(${self.xScale(self.latestFocus.x)},${self.yScale(self.latestFocus.y)})`
          )
      }

      updateFocus()
      function mouseover() {
        root.select('.focus').style('opacity', 1)
      }

      function getTooltipPosition(selectedData) {
        let tooltipX = self.xScale(selectedData.x)
        let tooltipY = yScale(selectedData.y)
        const tooltipWidth = (window.innerWidth * 200) / 1580
        if (tooltipX < 5) {
          tooltipX = 5
        }
        if (tooltipX + tooltipWidth > self.size.width) {
          tooltipX = self.size.width - tooltipWidth - 5
        }
        return {
          x: tooltipX,
          y: tooltipY,
        }
      }
      function mousemove(event) {
        const pointer = d3_pointer(event, this)[0]
        const x0 = self.xScale.invert(pointer - padding.left)
        // console.log(pointer, x0)

        let selectedData = data[0].values.reduce(
          (diff, d) => {
            let dist = Math.abs(d.x.getTime() - x0.getTime())
            if (diff.dist > dist) return { dist: dist, datum: d }
            else return diff
          },
          { dist: Infinity, datum: null }
        ).datum
        self.$emit('move', selectedData.x)
        if (selectedData) {
          self.tooltipData = {
            datum: self.getAllIndicatorsData(
              self.dataProps,
              self.refDataProps,
              selectedData.x,
              self.entityType,
              self.displayKpi !== 'vPopIndex'
            ),
            date: selectedData.x,
            ...getTooltipPosition(selectedData),
          }
          self.latestFocus = selectedData
        } else self.tooltipData = null

        updateFocus()
        // if (selectedData) {
        // root
        //   .select('.focus')
        //   .select('.line-focus')
        //   .attr('transform', `translate(${self.xScale(self.latestFocus.x)},0)`)
        // root
        //   .select('.focus')
        //   .select('.circle-focus')
        //   .attr(
        //     'transform',
        //     `translate(${self.xScale(self.latestFocus.x)},${self.yScale(self.latestFocus.y)})`
        //   )
        // }
      }
      function mouseout() {
        // root.select('.focus').style('opacity', 0)
        self.tooltipData = null
      }
    },
  },
}
</script>

<style lang="stylus">
.interactive-graph
  position: absolute
  // overflow: hidden
  // min-height: vw(30px)
  width: 100%
  height: 100%

  // height: vw(60px)
  .domain
    display: none

  .xAxis
    .tick
      color: #C0C0BB

      line
        opacity: 0.5
        stroke: #C0C0BB
        stroke-dasharray: 5 5

  .yAxis
    .tick
      color: #C0C0BB

      line
        opacity: 0.5
        stroke: #C0C0BB

    .tick:first-of-type
      line
        display: none

    .tick:last-of-type
      line
        display: none

  .pop-tooltip
    position: absolute
    z-index: 9
    border: 1px solid $concrete
    border-radius: 3px
    background-color: white
    box-shadow: 0 3px 7px $alto
    font-family: "Lelo"
    transition: top, left 0.2s
    transform: translateY(calc(-100% - 20px))
    pointer-events: none

  &.isHighPositioned
    .pop-tooltip
      transform: translateY(40px)

  &__line
    fill: none
    stroke-width: 1

    &--blue
      stroke: $blue

    &--red
      stroke: red

    &--green
      stroke: green

  &__area
    fill: $melrose
</style>
import { getSensors } from '../services/api'
import GetFilterBySetting from './GetFilterBySetting'

export default {
  mixins: [GetFilterBySetting],
  asyncComputed: {
    async filteredSensor() {
      const sensorsByTechnologies = await getSensors(this.topFilters)
      const sensorIds =
        !!this.topFilters.sensor && this.topFilters.sensor.length !== 0
          ? this.topFilters.sensor.filter(s => !!sensorsByTechnologies.find(t => t.id === s))
          : sensorsByTechnologies?.map(s => s.id)
      return sensorIds
    },
  },
}

<template>
  <div class="main-page">
    <div class="main-page__content">
      <div class="main-page__title">{{ $t('home_title') }}</div>
      <div class="main-page__description">{{ $t('home_description') }}</div>
      <div class="main-page__explore">
        <div>{{ $t('home_explore') }}</div>
        <div class="explore-icon" />
      </div>
    </div>
    <div class="button-container">
      <HomeButton 
        v-for="btn in homeButtons" 
        :key="btn.title" 
        :title="btn.title" 
        :icon="btn.icon" 
        :path="btn.to" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeButton from '../components/HomeButton.vue'
import { hasPermission } from '../settings/permissionSettings'
import { ROUTE_NAMES } from '../settings/routerSettings'
export default {
  components: {
    HomeButton,
  },
  computed: {
    ...mapGetters(['user']),
    homeButtons(){
      return [
        {
          title: this.$t('home_op_sensor'),
          icon: 'sensor',
          to: { name: ROUTE_NAMES.SensorEcosystem },
        },
        {
          title: this.$t('home_op_product'),
          icon: 'product',
          to: { name: ROUTE_NAMES.ProductInsights },
        },
        {
          title: this.$t('home_op_ingredients'),
          icon: 'ingredients',
          to: { name: ROUTE_NAMES.Ingredients },
        },
        { title: this.$t('home_op_funnel'), icon: 'funnel', to: { name: ROUTE_NAMES.Funnel } },
        {
          title: this.$t('home_op_topchosen'),
          icon: 'top-chosen',
          to: { name: ROUTE_NAMES.TopChosen },
        },
      ].filter(route => hasPermission(this.user, route.to.name))
    },
  },
}
</script>

<style lang="stylus" scoped>
.main-page
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: center
  overflow: hidden
  padding: vw(100px)
  width: 100%
  height: 100%

  &:before
    position: fixed
    top: 0
    right: 0
    z-index: -1
    width: 100%
    height: 100%
    background-image: url("~@/assets/main-background-2.jpg")
    background-position: right center
    background-size: cover
    background-repeat: no-repeat
    content: ""

.main-page__content
  margin-top: "calc(%s * -1)" % $header-height
  width: vw(500px)
  background-color: rgba(#fff, 0.5)

.main-page__title
  m-font-size(66, 86)
  margin-bottom: vw(10px)

.main-page__description
  m-font-size(14, 19)
  margin-bottom: vw(20px)
  width: vw(300px)
  font-family: "Lelo"

.main-page__explore
  m-font-size(10, 14)
  display: flex
  align-items: center
  font-family: "Lelo"

.explore-icon
  m-icon("right-arrow", 25)
  margin-left: vw(10px)

.button-container
  display: flex
  align-items: center
  margin-top: vw(70px)
  gap: vw(20px)
</style>

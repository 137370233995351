const shortRetailer = {
  PACO_RABANNE: 'PR',
  WIKIPARFUM: 'WIKIPARFUM',
  ARTISAN: "L'ARTISAN PARFUMEUR",
  SCENTICA: 'WIKIPARFUM',
  PENHALIGONS: 'PEN',
  SEPHORA: 'SEPHORA',
  DOUGLAS: 'DOUGLAS',
  NOCIBE: 'NOCIBE',
  MACYS: "MACY'S",
  NOTINO: 'NOTINO',
  BOOTS: 'BOOTS',
}
const shortTechnology = {
  AIR_PARFUM: 'AP',
  ONLINE_PROFILER: '',
  INSTORE_PROFILER: 'INSTORE',
  CONCEPTS: 'CONCEPTS',
  QR: 'QR',
  VISION: 'VISION',
  EAN_NOSE: 'EANNOSE',
  OTHER: '',
}

// CHANGE IT FOR REMOVE WORDS IN SENSOR NAME
const removedSensorNamesSetting = {
  PACO_RABANNE: ['Paco Rabanne'],
  WIKIPARFUM: ['WP', 'Wikiparfum'],
  ARTISAN: ["l'Artisan", 'Artisan', 'Lartisan'],
  PENHALIGONS: ['Penhaligons', "Penhaligon's"],
  SEPHORA: ['Sephora', 'SEPH'],
  DOUGLAS: ['DGL', 'Douglas'],
  NOCIBE: ['NOCIBE'],
  MACYS: ['MACYS', "Macy's"],
  NOTINO: ['NOTINO'],
}
const removedSensorNamesTechnologySetting = {
  AIR_PARFUM: ['Airparfum'],
  ONLINE_PROFILER: [],
  INSTORE_PROFILER: ['Instore'],
  VISION: ['VISION'],
}

/**
 *
 * @param {string} retailer
 * @returns
 */
export function getShortRetailer(retailer) {
  if (!retailer) return 'null'
  const key = shortRetailer[retailer]
  let retailerName = key
  if (!key) retailerName = retailer.toUpperCase().slice(0, 3)
  if (retailerName === 'AGG') retailerName = 'Retailers'
  return retailerName
}
/**
 *
 * @param {string} tech
 */
export function getShortTechnology(tech) {
  const key = shortTechnology[tech]
  if (key === undefined) return tech
  else return key
}

/**
 * Remove the options in removedSensorNamesTechnologySetting
 * @param {string} retailer Retailer name
 * @param {string} tech sensor type
 * @param {string} name sensor name
 * @returns {string}
 */
export function removeNames(retailer, tech, name) {
  /** @type {string[] | undefined} */
  const names = removedSensorNamesSetting[retailer]
  const namesTech = removedSensorNamesTechnologySetting[tech]
  var copy = name
  if (!name) copy = '-'
  if (names) copy = removeArrayStrings(names, copy)
  if (namesTech) copy = removeArrayStrings(namesTech, copy)
  return copy
}
/**
 * remove the array's strings in the string sts
 * UTILS
 * @private
 * @param {string[]} array
 * @param {string} str
 * @returns {string}
 */
const removeArrayStrings = (array, str) => {
  // console.log(str)
  return array.reduce((name, option) => name.replace(new RegExp(option, 'ig'), ''), str)
}

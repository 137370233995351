<template>
  <label class="container">
    <span :class="{checkmark:true,marked:value}" />
  </label>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>
<style lang="stylus" scoped>
.container
  position: relative
  display: block
  flex: 0 0 vw(15px)
  margin-right: vw(10px)
  width: vw(15px)
  height: vw(15px)
  cursor: pointer
  user-select: none

  input
    position: absolute
    width: 100%
    height: 100%
    opacity: 0
    cursor: pointer

.checkmark
  position: absolute
  top: 50%
  left: 50%
  width: 100%
  height: 100%
  border: 1px solid $aluminium
  background-color: #fff
  transform: translate(-50%, -50%)

  &.marked
    border-color: #000
    background-color: #fff

    &:after
      position: absolute
      top: calc(50% - 2px)
      left: 50%
      display: block
      width: vw(7px)
      height: vw(11px)
      border: solid grey
      border-width: 0 2px 2px 0
      content: ""
      transform: translate(-50%, -50%) rotate(45deg)

.label
  &.marked
    font-weight: bold
</style>
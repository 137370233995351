import { render, staticRenderFns } from "./HomeButton.vue?vue&type=template&id=72e0eef7"
import script from "./HomeButton.vue?vue&type=script&lang=js"
export * from "./HomeButton.vue?vue&type=script&lang=js"
import style0 from "./HomeButton.vue?vue&type=style&index=0&id=72e0eef7&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
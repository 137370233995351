<template>
  <InsightDisplay :insight="data" :compact="compact">
    <template v-slot:thumbnail>
      <img class="img-circle" :src="data.imageUrl">
    </template>
    <template v-slot:description>
      <div class="ing-name">
        <div>
          {{ data.name }}
        </div>
        <div class="ing-family">
          <div>
            <FamiliesDonut :data="data" size="xsm" />
          </div>
          <span v-if="data.family">{{ data.family.name }}</span>
        </div>
      </div>
    </template>
  </InsightDisplay>
</template>
<script>
import InsightDisplay from '../InsightDisplay'
import FamiliesDonut from './FamiliesDonut.vue'
export default {
  components: { InsightDisplay, FamiliesDonut },
  props: ['data', 'compact'],
}
</script>
<style lang="stylus" scoped>
.ing-family
  m-font-size(10, 13)
  display: flex
  margin-top: vw(2px)
  margin-left: vw(8px)
  font-weight: 300
  font-family: "Lelo"

  span
    margin-left: vw(10px)

  .ing-donut
    margin-right: 3px
    width: 14px

.ing-name
  m-font-size(22, 30)
  m-font-size(12, 16)
  text-transform: uppercase
  font-family: "Lelo"
</style>
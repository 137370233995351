<template>
  <div :class="getClass()">
    <header class="card__header">
      <div v-if="title" class="card__title">{{ title }}</div>
      <slot name="header" />
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    extended: Boolean,
  },
  methods: {
    getClass: function () {
      return `card ${this.extended ? 'card--extended' : ''}`
    },
  },
}
</script>

<style lang="stylus" scoped>
.card
  $margin = vw(10px)
  $percentage = percentage((1 / 4))
  $width = "calc(%s - (%s * 2))" % ($percentage $margin)
  position: relative
  display: flex
  flex: 0 0 $width
  flex-direction: column
  margin: $margin
  padding: vw(20px)
  width: $width
  border-radius: 5px
  box-shadow: 0 4px 8px rgba(#000, 0.1)

.card__header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: vw(30px)

  // height: "calc(%s + (%s * 2))" % (vw(14px) 1em)
  .card__title
    m-font-size(12, 14)
    font-weight: 300
    font-family: "Lelo"
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
// import SensorPerformance from '../views/SensorPerformance.vue'
import ProductInsights from '../views/ProductInsights.vue'
import IngredientsView from '../views/Ingredients.vue'
// import SensorOverviewView from '../views/SensorOverviewView.vue'
import SensorEcosystem from '../views/SensorEcosystem.vue'
import EntityCompareVue from '../components/productInsights/EntityCompare.vue'
import Funnel from '../views/Funnel.vue'
import TopChosen from '../views/TopChosen.vue'
import MainPage from '../views/MainPage.vue'
import Report from '../views/Report.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import { hasPermission, requiredAllowedViews } from '../settings/permissionSettings'
import { ROUTE_NAMES } from '../settings/routerSettings'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: ROUTE_NAMES.Login,
    component: Login,
  },
  {
    path: '/change-password',
    name: ROUTE_NAMES.ResetPassword,
    component: ResetPassword,
    props: route => ({ token: route.query.token, username: route.query.user }),
  },
  {
    path: '/sensor-ecosystem/:mode?',
    name: ROUTE_NAMES.SensorEcosystem,
    component: SensorEcosystem,
    props: true,
  },
  {
    path: '/product-insights',
    name: ROUTE_NAMES.ProductInsights,
    component: ProductInsights,
  },
  {
    path: '/entity-compare',
    name: ROUTE_NAMES.EntityCompareView,
    component: EntityCompareVue,
    props: route => Object.assign({}, route.query, route.params),
  },
  {
    path: '/ingredients',
    name: ROUTE_NAMES.Ingredients,
    component: IngredientsView,
  },
  {
    path: '/funnel',
    name: ROUTE_NAMES.Funnel,
    component: Funnel,
  },
  {
    path: '/top-chosen',
    name: ROUTE_NAMES.TopChosen,
    component: TopChosen,
  },
  {
    path: '/',
    name: ROUTE_NAMES.MainPage,
    component: MainPage,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAMES.MainPage },
  },
  // {
  //   path: '/example',
  //   name: 'Example',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Example.vue'),
  // },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (requiredAllowedViews[to.name]) {
    const user = await store.dispatch('getProfile')
    if (!hasPermission(user, to.name)) return next({ name: ROUTE_NAMES.MainPage })
    else next()
  } else {
    next()
  }
})

export default router

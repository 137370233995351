<template>
  <div class="evolutive-modal-content">
    <div class="close" @click="$modal.hide('evolutive')" />
    <div class="title">{{ translateKpi(evolConfig.kpi) }}</div>
    <div class="center">
      <div class="graph-container">
        <InteractiveGraph
          v-if="evolutiveData"
          ref="interactiveGraphRef"
          :data-props="evolutiveData"
          :ref-data-props="null"
          :display-kpi="evolConfig.kpi"
          :background-lines="true"
          :show-tick-values="true"
          :entity-type="evolConfig.type"
          @move="(value)=>date=value"
        />
        <Loader v-else class="loader" />
      </div>
      <div class="panel">
        <div class="content">
          <div v-if="evolConfig.type!=='SESSION'" class="card">
            <BrandDisplay
              v-if="evolConfig.type === 'BRAND'"
              :data="panelConfig.entity"
              :display-kpi="evolConfig.kpi"
              :compact="false"
            />
            <FragranceDisplay
              v-if="evolConfig.type === 'FRAGRANCE'"
              :data="panelConfig.entity"
              :display-kpi="evolConfig.kpi"
              :compact="false"
            />
            <FamilyDisplay
              v-if="evolConfig.type === 'FAMILY'"
              :data="panelConfig.entity"
              :display-kpi="evolConfig.kpi"
              :compact="false"
            />
            <IngredientDisplay
              v-if="evolConfig.type === 'INGREDIENT'"
              :data="panelConfig.entity"
              :display-kpi="evolConfig.kpi"
              :compact="false"
            />
          </div>
          <div v-else class="variations">
            <Percentage
              class="percentage-container insight-display__pop__percentage"
              :value="panelConfig.variations.vsLastPeriod"
              label=" vs last period"
            />
            <Percentage
              class="percentage-container insight-display__pop__percentage"
              :value="panelConfig.variations.trend"
              label=" trend"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="evolConfig.kpi==='vPopIndex'" class="bottom">
      <div v-for="kpi in evolConfig.values.filter((d)=>d!=='vPopIndex')" :key="kpi" class="related-kpi">
        <div class="title">{{ translateKpi(kpi) }}</div>
        <div class="content">
          <Graph
            :selected="date"
            :data-props="getSubEvol(kpi)"
            :area="false"
            :height="80"
            :background-lines="false"
            :color-graph="'blue'"
          />
          <div v-if="getSubEvol(kpi).length" class="value">{{ $prettyNumber(getSubEvolValue(kpi,date)) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEvolutive } from '@/services/api'
import InteractiveGraph from '@/components/productInsights/InteractiveGraph'
import Graph from '@/components/productInsights/Graph'
import { translateKpi } from '../mixins/IndicatorMixin'
import Percentage from '@/components/productInsights/Percentage.vue'
import FragranceDisplay from '@/components/productInsights/InsightDisplay/FragranceDisplay.vue'
import BrandDisplay from '@/components/productInsights/InsightDisplay/BrandDisplay.vue'
import FamilyDisplay from '@/components/productInsights/InsightDisplay/FamilyDisplay.vue'
import IngredientDisplay from '@/components/productInsights/InsightDisplay/IngredientDisplay.vue'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    InteractiveGraph,
    Graph,
    Percentage,
    FragranceDisplay,
    BrandDisplay,
    FamilyDisplay,
    IngredientDisplay,
    Loader,
  },
  props: ['evolConfig', 'panelConfig'],
  data() {
    return {
      graphValue: null,
      compact: false,
      date: null,
    }
  },
  asyncComputed: {
    evolutiveData() {
      return getEvolutive(
        this.evolConfig.type,
        this.evolConfig.id,
        this.evolConfig.filters,
        this.evolConfig.values
      ).then(response => {
        // return response[0].values
        return response
      })
    },
  },
  methods: {
    getSubEvol(kpi) {
      return (
        this.evolutiveData?.map(d => {
          return { ...d, value: d[kpi] }
        }) || []
      )
    },
    getSubEvolValue(kpi, date) {
      return date && this.evolutiveData?.find(d => d.date === date)[kpi]
    },
    translateKpi,
  },
}
</script>

<style lang="stylus" scoped>
.evolutive-modal-content
  position: relative
  display: flex
  flex-direction: column
  padding: vw(40px)
  background-color: #fff

  .close
    $size = 50
    m-icon("close", $size)
    position: absolute
    top: vw(($size / 2))
    right: vw(($size / 2))
    cursor: pointer

  .title
    m-font-size(30)

  .center
    display: flex
    flex: 1 1 0%
    // overflow: hidden

    .graph-container
      position: relative
      flex: 1 1 0%
      margin: vw(40px)

  .bottom
    display: flex
    gap: vw(10px)

    .related-kpi
      flex: 1 1 0%
      padding: vw(15px)
      border-radius: 5px
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1)
      font-family: "Lelo"

      .title
        m-font-size(12)

      .content
        display: flex
        align-items: center

        .graph
          flex: 1 1 0%
          height: vw(30px) !important

        .value
          m-font-size(40, 40)
          margin-left: vw(40px)
          font-weight: 300

  .panel
    display: flex
    flex: 0 0 20%
    align-items: center
    margin-bottom: vw(20px)

    .content
      width: 100%
      height: 100%

      // GRAN CHAPUZA sobreescribimos "todo"
      .card
        height: 100%

        .insight-display
          flex-direction: column
          align-items: center
          max-width: 100%
          height: 100%

          >>> .insight-display__thumbnail-container
            width: 100%

          >>> .insight-display__thumbnail
            flex: 1 1 0%
            overflow: hidden
            width: 100%
            height: 100%

            .img-circle
              height: auto

          >>> .insight-display__graph-container
            margin-top: vw(10px)
            width: 100%

          >>> .percentage-container
            .percentage
              m-font-size(20)

          >>> .evol-button
            display: none

      .variations
        display: flex
        flex-direction: column
        justify-content: flex-end
        height: 100%

      .insight-display__pop__percentage
        display: flex
        align-items: center
        padding: vw(15px) vw(10px)
        border-top: 1px solid #BDBDBD
        font-family: "Lelo"

        >>> .texts
          m-font-size(20, 27)
          font-weight: 300
</style>
/**
 * @typedef FilterSetting
 * @property {string} routeName
 * @property {FILTERS_ENUM[]} filters
 * @property {Object?} params
 * @property {FILTER_SETTING_ID} id
 */

import { ROUTE_NAMES } from './routerSettings'

/**
 * @enum {string}
 */
export const FILTER_SETTING_ID = {
  NETWORKING: 'NETWORKING',
  MAP: 'MAP',
  PRODUCT_INSIGHTS: 'PRODUCT_INSIGHTS',
  SENSOR_OVERVIEW: 'SensorOverviewView',
  INGREDIENTS: 'INGREDIENTS',
  COMPARE_ENTITY: 'COMPARE_ENTITY',
  FUNNEL: 'FUNNEL',
  TOPCHOSEN: 'TOP_CHOSEN',
}
/**
 * @enum {string}
 */
export const FILTERS_ENUM = {
  TECHNOLOGY: 'TECHNOLOGY',
  SENSORS: 'SENSORS',
  COUNTRIES: 'COUNTRIES',
  GENDERS: 'GENDERS',
  DATE_RANGE: 'DATE RANGE',
}

export const ACTIONS_ENUM = {
  COMPARE: 'COMPARE',
  DEEP_ANALYSIS: 'DEEP_ANALYSIS',
}
const fitterField = {
  [FILTERS_ENUM.COUNTRIES]: 'country',
  [FILTERS_ENUM.GENDERS]: 'gender',
  [FILTERS_ENUM.SENSORS]: 'sensor',
  [FILTERS_ENUM.TECHNOLOGY]: 'technology',
}
/**
 *
 * @param {any} filters Top Filter to apply
 * @param {FilterSettingIdEnum} settingId
 */
export function applyFilters(filters, settingId) {
  const selectedSetting = setting.find(sett => sett.id === settingId)
  if (!selectedSetting) throw new Error('[_B] setting not found')
  const requiredFields = ['to', 'from']
  const filterResult = {}
  requiredFields.concat(selectedSetting.filters.map(fil => fitterField[fil])).forEach(field => {
    if (filters[field]) filterResult[field] = filters[field] ?? null
  })
  return filterResult
}
/**
 *
 * @param {import('vue-router').Route} route
 */
export function getSettingByRoute(route) {
  const filteredSettings = setting.filter(sett => {
    var bool = sett.routeName === route.name
    if (sett.params)
      bool =
        bool &&
        Object.keys(sett.params).every(key =>
          Array.isArray(sett.params[key])
            ? sett.params[key].some(param => param === route.params[key])
            : sett.params[key] === route.params[key]
        )
    return bool
  })
  if (filteredSettings.length > 1)
    console.warn(
      '[_B] setting for route',
      route.name,
      'and params',
      JSON.stringify(route.params),
      'not found',
      filteredSettings,
      route
    )
  // if (filteredSettings.length === 0)
  //   console.error('[_B] filter setting ', route.name, ' not found', route.params)
  return filteredSettings[0]
}

/**
 * @type {FilterSetting[]}
 */
const setting = [
  {
    id: FILTER_SETTING_ID.NETWORKING,
    routeName: ROUTE_NAMES.SensorEcosystem,
    name: 'title_sensor_overview',
    params: {
      mode: ['network', undefined],
    },
    filters: [
      FILTERS_ENUM.TECHNOLOGY,
      FILTERS_ENUM.SENSORS,
      FILTERS_ENUM.COUNTRIES,
      FILTERS_ENUM.DATE_RANGE,
    ],
  },
  {
    id: FILTER_SETTING_ID.MAP,
    routeName: ROUTE_NAMES.SensorEcosystem,
    name: 'title_sensor_overview',
    params: {
      mode: 'map',
    },
    filters: [FILTERS_ENUM.TECHNOLOGY, FILTERS_ENUM.SENSORS, FILTERS_ENUM.DATE_RANGE],
  },
  {
    id: FILTER_SETTING_ID.PRODUCT_INSIGHTS,
    routeName: ROUTE_NAMES.ProductInsights,
    name: 'title_product_insights',
    filters: [
      FILTERS_ENUM.SENSORS,
      FILTERS_ENUM.COUNTRIES,
      FILTERS_ENUM.GENDERS,
      FILTERS_ENUM.DATE_RANGE,
    ],
  },
  {
    id: FILTER_SETTING_ID.COMPARE_ENTITY,
    routeName: ROUTE_NAMES.EntityCompareView,
    name: 'title_compare_entity',
    filters: [
      FILTERS_ENUM.SENSORS,
      FILTERS_ENUM.COUNTRIES,
      FILTERS_ENUM.GENDERS,
      FILTERS_ENUM.DATE_RANGE,
    ],
  },
  {
    // using!? router name not found...
    id: FILTER_SETTING_ID.SENSOR_OVERVIEW,
    routeName: 'SensorOverviewView',
    name: 'title_sensor_overview',
    filters: [
      FILTERS_ENUM.SENSORS,
      FILTERS_ENUM.COUNTRIES,
      FILTERS_ENUM.GENDERS,
      FILTERS_ENUM.DATE_RANGE,
    ],
  },
  {
    id: FILTER_SETTING_ID.INGREDIENTS,
    routeName: ROUTE_NAMES.Ingredients,
    name: 'title_ingredient_insights',
    filters: [FILTERS_ENUM.SENSORS, FILTERS_ENUM.COUNTRIES, FILTERS_ENUM.DATE_RANGE],
  },
  {
    id: FILTER_SETTING_ID.FUNNEL,
    routeName: ROUTE_NAMES.Funnel,
    name: 'funnel_title',
    filters: [FILTERS_ENUM.SENSORS, FILTERS_ENUM.COUNTRIES, FILTERS_ENUM.DATE_RANGE],
    actions: [ACTIONS_ENUM.COMPARE, ACTIONS_ENUM.DEEP_ANALYSIS],
  },
  {
    id: FILTER_SETTING_ID.TOPCHOSEN,
    routeName: ROUTE_NAMES.TopChosen,
    name: 'title_top_chosen',
    filters: [FILTERS_ENUM.SENSORS, FILTERS_ENUM.COUNTRIES, FILTERS_ENUM.DATE_RANGE],
  },
]
export default setting

export default {
  welcome_to_bestiario: 'This is Dashboard of Dashboards',
  header_title: 'Osmocosm',
  header_logout: 'Logout',
  home_title: 'Sensor Performance',
  home_description:
    "Welcome to the Dashboard of Dashboards. Here we gather the information we collect on the fragrance universe. What does the world smell like? Let's find out",
  home_explore: 'Explore',
  home_op_sensor: 'Sensor Ecosystem',
  home_op_product: 'Product Insights',
  home_op_ingredients: 'Ingredients',
  home_op_funnel: 'Funnel',
  home_op_topchosen: "What's the most chosen...?",

  section_title_trending_now: 'Most Popular',
  section_title_product_rankings: 'Product Rankings',
  section_title_evolutives: 'Popularity Index',
  section_title_spotlight_BRAND: 'Brand',
  section_title_spotlight_FAMILY: 'Family',
  section_title_spotlight_PERFUMIST: 'Perfumist',
  section_title_spotlight_INGREDIENT: 'Ingredient',

  technology_air_parfum: 'AirParfum',
  technology_vision: 'Ean Nose',
  technology_online_profiler: 'Online Profiler',
  technology_instore_profiler: 'Instore Profiler',
  technology_qr: 'QR',
  technology_concepts: 'Concepts',
  air_parfum: 'AirParfum',
  vision: 'Ean Nose',
  online_profiler: 'Online Profiler',
  instore_profiler: 'Instore Profiler',
  qr: 'QR',
  concepts: 'Concepts',

  sensor_name_all: 'All sensors',

  sensor_map_timelapse: 'Timelapse',

  title_product_insights: 'Product Insights',
  title_sensor_overview: 'Sensor Overview',
  title_ingredient_insights: 'Ingredient Insights',
  title_sensor_ecosystem: 'Sensor Ecosystem',
  title_compare_entity: 'Product Rankings',
  title_top_chosen: "What's the most chosen...?",

  kpi_info_vSessions:
    'A session is a group of events from the same user with a timelapse between consecutive events no longer than 5 minutes.',
  kpi_info_vDuration: 'Average duration is the average duration of a session. ',
  kpi_info_vPages:
    'Events per session is the average of events captured per session. Any event. Views events, recommendation events, add to profile (remove from), smell, etc.',
  kpi_info_vBounceRate:
    'Bounce rate is defined as sessions such that only have one view event. That is equivalent to the times a session ends before moving from the landing page.',
  kpi_info_vViews:
    'Views are the number of different views. Each different route path counts as a view. Plainly, each step on a profiler is a view.',
  kpi_info_vUniqueVisits:
    'Unique visitors within a period is the number of different uuid existing in that period.',
  kpi_info_vProfile:
    'Profiles created stands for the number of unique visitors that have indeed emitted at least one “add to profile” event. ',

  info_section_trending_now:
    'Here you will find the most popular element for each entity: Brand, Fragrance, Family Combination and ingredient',
  info_section_product_rankings:
    'Here you will find the most popular Top 5 elements for each entity: Brand, Fragrance, Family Combination and Ingredient',
  info_section_evolutives:
    'Here you will find the evolution of the most popular element or Top 5 elements: Brand, Fragrance, Family Combination and Ingredient',
  info_section_spotlight_BRAND:
    'Here you will find information on the most popular Top 5 elements for Brand. You can also view the most popular fragrances for each Top 5 Brands',
  info_section_spotlight_FAMILY:
    'Here you will find information on the most popular Top 5 elements for Family Combination. You can also view the most popular fragrances for each Top 5 Family Combinations',
  info_section_spotlight_PERFUMIST:
    'Here you will find information on the most popular Top 5 elements for every Perfumist. You can also view the most popular fragrances for each Top 5 Perfumists',
  info_section_spotlight_INGREDIENT:
    'Here you will find information on the most popular Top 5 elements for Ingredient. You can also view the most popular fragrances for each Top 5 Ingredients',
  filter_all_technologies: 'All Technologies',
  filter_all_countries: 'All Countries',
  filter_all_sensors: 'All Sensors',
  filter_no_options: 'List is empty',
  filter_all: 'All',
  filter_select_all: 'Select All',
  filter_deselect_all: 'Deselect All',
  filter_option_selected: 'option selected',
  filter_options_selected: 'options selected',
  no_data: 'No data for these filters',

  explore_this_relation: 'Explore this relation',
  selected_ingredients: 'Selected ingredients',
  ing_combos_title: 'Most Pop Fragrances within this relationship',

  funnel_title: 'Funnel',
  funnel_unique_users: 'Unique visitors',
  funnel_average_time: 'Average time',
  funnel_sessions: '#',
  funnel_arrival: '% Arrival',
  funnel_lost: '% Lost',
  funnel_step: 'Step %',
  funnel_no_data: 'This funnel has no data',
  funnel_data_loading: 'Loading funnel data...',
  funnel_no_funnel_selected: 'No funnel selected',
  funnel_landing_page: 'Landing page',
  funnel_landing_page_for_whom: 'Landing Page (I) For whom',
  funnel_landing_page_gender: 'Landing Page (II) Gender',
  funnel_dashboard: 'Smelling view',
  funnel_explore_families: 'Explore by families',
  funnel_explore_perfumes: 'Explore by perfumes',
  funnel_explore_concepts: 'Explore by concepts',
  funnel_explore_ingredients: 'Explore by ingredients',
  funnel_explore_family: 'Explore by family',
  funnel_explore_attitude: 'Explore by attitude',
  funnel_select_family: 'Select family',
  funnel_perfume_viewed: 'Perfume viewed',
  funnel_add_perfume_to_favorites: 'Add perfume to favorites',
  funnel_add_to_profile: 'Add perfume/ingredient to profile',
  funnel_explore_by: 'Explore by',
  funnel_explore_by_families: 'Explore by families',
  funnel_explore_by_subfamilies: 'Explore by sub  families',
  funnel_explore_by_ingredients_perfumes: 'Explore by ingredients/perfumes',
  funnel_explore_by_ingredients_attitude: 'Explore by ingredients/attitude',
  funnel_explore_by_attitude_family: 'Explore by family/attitude',
  funnel_explore_by_concepts_ingredients: 'Explore by ingredients/concepts',
  funnel_add_perfume_to_profile: 'Add perfume to profile',
  funnel_add_concept_to_profile: 'Add concept to profile',
  funnel_add_cartridge_to_profile: 'Add cartridge to profile',
  funnel_add_ingredient_to_profile: 'Add ingredient to profile',
  funnel_add_ingredient_or_perfume_to_profile: 'Add ingredient/perfume to profile',
  funnel_top_chosen_action_option_title: "What's the most chosen path?",
  funnel_set_intensity: 'Set intensity',
  funnel_recommendation_view: 'Recommendation view',
  funnel_click_to_buy: 'Click to buy',
  funnel_set_gender: 'Set gender',
  funnel_set_concepts: 'Set concepts',
  funnel_set_range_price: 'Set Range Price',
  funnel_shared_wishlist: 'Shared wishlist',
  funnel_profile_view: 'Profile view',
  funnel_attitude: 'Attitude',
  funnel_concepts: 'Concepts',
  funnel_ingredients: 'Ingredients',
  funnel_families: 'Families',
  funnel_perfumes: 'Perfumes',
  funnel_scan: 'Scan',
  funnel_male: 'Male',
  funnel_female: 'Female',
  funnel_unisex: 'Unisex',
  funnel_any: 'Skip',
  funnel_all: 'All',
  funnel_for_me: 'For me',
  funnel_for_them: 'For them',
  funnel_paco_branch_profiler: 'Quiz',
  funnel_paco_branch_air_parfum: 'Fragrance Discovery',
  funnel_collections: 'Franchise selection',
  funnel_collection: 'Perfumes selection within franchise',
  funnel_mass: 'Mass €',
  funnel_niche: 'Niche €€€',
  funnel_prestige: 'Prestige €€',
  funnel_range_price: 'Range price',
  funnel_evaluate: 'Evaluate',

  // funnel top chosen
  funnel_top_chosen_view_purchasing_title: 'Evaluate - Purchasing',
  funnel_top_chosen_view_cartridge_pair_title: 'Cartridge Pair',
  funnel_top_chosen_view_family_title: 'Family',
  funnel_top_chosen_view_subfamily_title: 'Sub Family',
  funnel_top_chosen_view_likely_title: 'Evaluate - Likely',
  funnel_top_chosen_view_lang_title: 'Language',
  funnel_top_chosen_view_addCartridgeToProfile_title: 'Selected Main Cartridges',
  funnel_top_chosen_view_classification_title: 'Classification/Price',
  funnel_top_chosen_view_removeCartridgeFromProfile_title: 'Removed Cartridges',
  funnel_top_chosen_view_addFamilyPairToProfile_title: 'Family Pair',
  funnel_top_chosen_view_smellPerfume_title: 'Most Smelled Perfumes',
  funnel_top_chosen_title: "What's the most chosen...?",
  funnel_top_chosen_gender_title: 'Gender',
  funnel_top_chosen_explore_by_title: 'Explore by',
  funnel_top_chosen_add_family_to_profile_title: 'Families added to profile',
  funnel_top_chosen_add_ingredient_to_profile_title: 'Ingredients added to profile',
  funnel_top_chosen_add_attitude_to_profile_title: 'Attitudes added to profile',
  funnel_top_chosen_add_concept_to_profile_title: 'Concepts added to profile',
  funnel_top_chosen_collection_viewed_title: 'Most viewed franchises',
  funnel_top_chosen_view_perfume_paco_title: 'Most viewed perfumes',
  funnel_top_chosen_buy_perfume_title: "What's the most clicked perfume?",
  funnel_top_chosen_set_intensity_title: 'Most chosen intensities',
  funnel_card_value_label_EXPLORE_BY_INGREDIENTS: 'Ingredients',
  funnel_card_value_label_EXPLORE_BY_CONCEPTS: 'Concepts',
  funnel_card_value_label_SELECT_FAMILY: 'Families',
  funnel_card_value_label_SELECT_ATTITUDE: 'Attitude',
  funnel_card_value_label_F: 'Female',
  funnel_card_value_label_M: 'Male',
  funnel_card_value_label_U: 'Unisex',
  funnel_card_value_label_FU: 'Female',
  funnel_card_value_label_MU: 'Male',
  funnel_card_value_label_FMU: 'All',
  funnel_card_value_label_TRUE: 'Yes',
  funnel_card_value_label_FALSE: 'No',
  funnel_card_value_label_MASS: 'Mass €',
  funnel_card_value_label_NICHE: 'Niche €€€',
  funnel_card_value_label_PRESTIGE: 'Prestige €€',
  funnel_card_value_label_EN: 'English',
  funnel_card_value_label_FR: 'French',
  funnel_card_value_label_ANY: 'Skip',
  funnel_card_value_label_PROFILER: 'Quiz',
  funnel_card_value_label_AIR_PARFUM: 'Fragrance Discovery',
  funnel_top_chosen_no_sensor_selected: 'No sensor selected',
  funnel_top_chosen_data_loading: 'Loading top chosen data...',
  funnel_top_chosen_no_data: 'No data found for the selected sensors',
  netPromoterScore: 'Net Promoter Score',
}

<template>
  <div class="separator" :style="{ margin: `${marginTop}px 0px ${marginBottom}px`, borderTop: `0.5px solid ${color}`}" />
</template>

<script>
export default {
  props: {
    marginTop: {
      type: Number,
      default: 0,
    },
    marginBottom: {
      type: Number,
      default: 8,
    },
    color: String,
  },
}
</script>

<style lang="stylus" scoped></style>
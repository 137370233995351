import { render, staticRenderFns } from "./BrandDisplay.vue?vue&type=template&id=734c3b28&scoped=true"
import script from "./BrandDisplay.vue?vue&type=script&lang=js"
export * from "./BrandDisplay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734c3b28",
  null
  
)

export default component.exports
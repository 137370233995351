<template>
  <div :class="mainClasses">
    <div class="indicator-card__header">
      <div class="indicator-card__header__title">{{ label }}</div>
      <!-- <Percentage v-if="compact && !minimized" :value="variation" /> -->
      <!-- <div v-if="compact && !minimized">A{{ vsLastPeriod }}{{ trend }}</div> -->
      <div v-if="!compact && !minimized" class="indicator-card__value">
        {{ type === "vDuration" ? $prettyDuration(value) : type === "vBounceRate" ? $prettyPercent(value) : $prettyNumber(value) }}
      </div>
    </div>
    <div class="indicator-card__content">
      <div v-if="compact && !minimized" class="indicator-card__value">
        {{ type === "vDuration" ? $prettyDuration(value) : type === "vBounceRate" ? $prettyPercent(value) : $prettyNumber(value) }}
      </div>
      <div v-if="!minimized" class="indicator-card__graph">
        <div class="indicator-card__pop">
          <!-- <div class="graph-text">PoP Index</div> -->
          <!-- <Percentage :value="variation" label="vs last period" /> -->
          <!-- B{{ vsLastPeriod }}{{ trend }} -->
          <Percentage :value="vsLastPeriod" label="vs last period" />
          <Percentage :value="trend" label="trend" @evol="showEvol" />
        </div>
        <!-- <Graph
          :width="width()"
          :selected="date"
          :data-props="evolutive"
          :area="false"
          :height="compact ? 35 : 100"
          :background-lines="compact ? false : true"
          :color-graph="getColorGraph()"
        /> -->
      </div>
      <div v-if="minimized" class="indicator-card__graph-min">
        <!-- <Graph
          :width="width()"
          :selected="date"
          :height="graphHeight || 15"
          :data-props="evolutive"
          :area="false"
          :background-lines="compact ? false : true"
          :color-graph="getColorGraph()"
        /> -->
        <div class="indicator-card__value">
          {{ value }}
        </div>
        <!-- <Percentage :value="variation" /> -->
        C{{ vsLastPeriod }}{{ trend }}
      </div>
    </div>
  </div>
</template>

<script>
// import Graph from './Graph.vue'
import Percentage from '../productInsights/Percentage.vue'
export default {
  components: { Percentage },
  props: {
    compact: Boolean,
    graphWidth: Number,
    graphHeight: Number,
    // data.variation: Number,
    label: String,
    // data: Object,
    type: String,
    value: Number,
    evolutive: Array,
    // variation: Number,
    vsLastPeriod: Number,
    trend: Number,
    minimized: Boolean,
    date: Date,
  },
  computed: {
    mainClasses: function () {
      return `indicator-card ${
        this.minimized
          ? 'indicator-card--minimized'
          : this.compact
          ? 'indicator-card--compact'
          : 'indicator-card--extended'
      }`
    },
  },
  // mounted() {
  //   // console.log('FUCK: mounted indicator card')
  // },
  methods: {
    // getvalue: data => data.reduce((curr, acc) => acc + curr, 0),

    width() {
      if (this.graphWidth) {
        return this.graphWidth
      }
      return this.compact ? 185 : 66
    },
    getColorGraph() {
      if (this.variation > 0) {
        return 'green'
      } else if (this.variation < 0) {
        return 'red'
      } else {
        return 'blue'
      }
    },
    showEvol() {
      this.$emit('showEvol')
      //(component, componentProps, modalProps, modalEvents)
      // this.$modal.show('evolutive', {
      //   evolConfig: {
      //     kpi: 'SESSION',
      //     type: 'SESSION',
      //     filters: this.filters, <-- MENTIRA, basura...
      //     id: [],
      //     values: [this.type],
      //   },
      // })
    },
  },
}
</script>

<style lang="stylus" scoped>
.indicator-card
  font-family: "Lelo"

.indicator-card--minimized
  position: relative
  display: flex
  flex-shrink: 0
  flex-direction: column
  width: 100%
  // height: 50px
  border-bottom: 1px solid $alto
  font-weight: 200

  .indicator-card__content
    display: flex
    flex-direction: column
    padding: 0

  .indicator-card__header__title
    m-font-size(10, 13)

.indicator-card__graph-min
  position: relative
  display: flex
  width: 100%

  .percentage
    display: flex
    align-items: center
    margin-right: 8px
    height: 35px

  .indicator-card__value
    flex: 0 0 vw(60px)
    text-align: right

.indicator-card--compact
  display: flex
  flex-direction: column
  border: 1px solid $alto

  .indicator-card__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: vw(5px)
    padding-top: vw(10px)
    padding-right: vw(10px)
    padding-left: vw(10px)

  .indicator-card__header__title
    m-font-size(13, 22)

  .indicator-card__pop
    flex-direction: column
    justify-content: end
    padding: 0
    border-top: none

    >>> .texts
      m-font-size(12, 16)
      justify-content: end
      font-weight: 300

.indicator-card--extended
  position: relative
  display: flex
  // flex-shrink: 0
  flex-direction: column
  min-height: 170px
  // width: 317px
  border: 1px solid $alto

  .indicator-card__pop
    display: flex
    justify-content: space-between
    m-font-size(11, 15)
    margin-bottom: 15px
    grid-column-start: 1
    grid-column-end: 3

    :nth-child(2)
      width: 50%
      text-align: right

    :nth-child(3)
      text-align: right

  // .indicator-card__graph
  // margin-top: 0
  // margin-left: 0
  // padding-top: 10px
  // width: 100%
  // border-top: 1px solid #E0E0E0
  .indicator-card__header
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 10px 10px 8px
    width: 100%
    height: auto
    background-color: white

    .indicator-card__value
      m-font-size(36, 50)

  .indicator-card__header__title
    m-font-size(22, 30)

.indicator-card__content
  padding-right: vw(10px)
  padding-bottom: vw(10px)
  padding-left: vw(10px)

.indicator-card__value
  m-font-size(28, 28)
  margin-bottom: vw(10px)

.graph-text
  m-font-size(15, 18)

.vs-text
  m-font-size(9)
  white-space: nowrap
</style>
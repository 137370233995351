<template>
  <div v-if="data.family" class="families-donut" :class="size">
    <div class="family" :style="{ backgroundColor: data.family.color, backgroundImage: showImages?`url(${data.family.imageUrl})`:''}" />
    <div v-if="data.subfamily" class="subfamily" :style="{ backgroundColor: data.subfamily.color, backgroundImage: showImages?`url(${data.subfamily.imageUrl})`:''}" />
  </div>
</template>

<script>
export default {
  props: {
    // familyColor: String,
    // subFamilyColor: String,
    // familyImage: String,
    // subFamilyImage: String,
    showImages: Boolean,
    data: Object,
    size: {
      type: String,
      default: 'xl',
    },
  },
}
</script>

<style lang="stylus" scoped>
.families-donut
  position: relative
  display: flex
  justify-content: center
  align-items: center
  padding: 0
  width: 100%
  height: 100%

.family,
.subfamily
  position: absolute
  border: 1px solid white
  border-radius: 50%
  background-position: center
  background-size: contain
  background-repeat: no-repeat

.family
  width: vw(120px)
  height: vw(120px)

.subfamily
  width: vw(73px)
  height: vw(73px)

.xl
  .family
    width: vw(160px)
    height: vw(160px)

  .subfamily
    width: vw(80px)
    height: vw(80px)

.md
  .family
    width: vw(54px)
    height: vw(54px)

  .subfamily
    width: vw(28px)
    height: vw(28px)

.sm
  .family
    width: vw(18px)
    height: vw(18px)

  .subfamily
    width: vw(8px)
    height: vw(8px)

.xsm
  .family
    width: vw(14px)
    height: vw(14px)

  .subfamily
    width: vw(6px)
    height: vw(6px)
</style>
import axios from 'axios'
import qs from 'qs'
import dayjs from 'dayjs'
import { getAvailableElements } from '@/utils/indicatorUtils'
import store from '../store'
import router from '../router'
import { ROUTE_NAMES } from '../settings/routerSettings'
import { setUTCHHMMSSToZero } from '../utils/filterUtils'
export function fetchIndicators() {
  return axios.get('fake_data/indicatorsList.json').then(resp => {
    return resp.data
  })
}

axios.interceptors.response.use(
  config => {
    return config
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
      router.push({ name: ROUTE_NAMES.Login }).catch(() => {
        console.warn('Error 401. Cant go to login page')
      })
    }
    return Promise.reject(error)
  }
)

// FOR TESTING, mientras no hay endpoints de verdad...
// function fakeDelay(response) {
//   return new Promise(res => {
//     setTimeout(() => {
//       res(response)
//     }, Math.random() * 2000)
//   })
// }
// function fakeReorder(response) {
//   Object.keys(response.data).forEach(k => {
//     if (Array.isArray(response.data[k])) {
//       response.data[k].sort(() => {
//         return Math.random() - 0.5
//       })
//     }
//   })
//   return response
// }

export function login(user, password) {
  const data = qs.stringify({
    user,
    password,
  })
  var config = {
    method: 'post',
    url: process.env.VUE_APP_API_URL + '/user/login',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  }
  return axios(config).catch(e => {
    if (e.response) {
      return Promise.reject(e.response)
    }
  })
}

export function startRecover(username) {
  const data = qs.stringify({
    username,
  })
  var config = {
    method: 'post',
    url: process.env.VUE_APP_API_URL + '/user/recover',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  }
  return axios(config)
}

export function resetPassword(username, password, token) {
  const data = qs.stringify({
    username,
    password,
    token,
  })
  var config = {
    method: 'post',
    url: process.env.VUE_APP_API_URL + '/user/resetPassword',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  }
  return axios(config)
}

export function logout() {
  var config = {
    method: 'get',
    url: process.env.VUE_APP_API_URL + '/user/logout',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  return axios(config)
}
/** Save Profile response in order to fetch it in router.js */
export async function profile() {
  var config = {
    method: 'get',
    url: process.env.VUE_APP_API_URL + '/user/profile',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  return axios(config)
}

export async function getRetailerData(hostId) {
  const response = await axios.get(`fake_data/retailers.json`)
  return response.data.find(retailer => retailer.id === hostId)
}

// export async function getProductsData(filters) {
//   // console.log('FETCHING DATA', { filters })
//   const response = await axios
//     .get(`fake_data/product-insights/productInsights.json`)
//     .then(fakeDelay)
//     .then(fakeReorder)
//   return response.data
// }

let cache = {}
let pendings = {}

function getCached(action, url, params) {
  let hash = url + '__' + JSON.stringify(params)

  if (!cache[hash]) {
    pendings[hash] = true
    window.document.getElementById('app').classList.add('loading')
    window.document.getElementById('app').classList.remove('load-complete')
    cache[hash] = axios[action](url, params).then(response => {
      delete pendings[hash]
      // console.debug('[getCached] pending: ', Object.keys(pendings).length)
      if (Object.keys(pendings).length === 0) {
        // console.debug('[getCached] COMPLETE!')
        window.document.getElementById('app').classList.add('load-complete')
        window.document.getElementById('app').classList.remove('loading')
      }
      return response
    })
  }
  return cache[hash]
}

export function clearCache() {
  cache = {}
}

function parseFilters(filters, type) {
  if (!filters || !filters.from || !filters.to) return {}
  return {
    ...filters,
    sensor: filters.sensor,
    country: (filters.country || []).map(d => d.id || d),
    from: filters.from.toISOString(), //.slice(0, -5) + 'Z',
    to: filters.to.toISOString(), //.slice(0, -5) + 'Z',
    gender: filters.gender || [],
    technology: filters.technology || [],
    id: filters.id || null,
    entityFilters: {
      ...(filters.entityFilters || {}),
      brands: filters.brands,
      familyPairs:
        filters.familyPairs ||
        (filters.perfumes || []).map(f => ({
          primary: f.family.id,
          secondary: f.subfamily.id,
        })),
      ingredients: filters.ingredients,
      perfumists: filters.perfumists,
    },
    onlyPrincipal:
      filters.onlyPrincipal !== undefined ? filters.onlyPrincipal : type === 'INGREDIENT', // set by default true if type is INGREDIENT ticket #127
    ingredientCombinations: filters.ingredientCombinations,
    classification: filters.classification
      ? Array.isArray(filters.classification)
        ? filters.classification
        : [filters.classification]
      : null,
  }
}
import { getVariations } from '@/utils/variationUtils'
export async function getTopEntitiesWithVariations(
  type,
  filters,
  previousFilters,
  values,
  config = {},
  variationsPagination = [0, Infinity]
) {
  const entityKpis = await getIndicators(filters)
  if (!values) values = getAvailableElements(entityKpis, type)

  return getTopEntities(type, filters, values, config).then(data => {
    //limpiaos "isPuig" "addRanking" y "filters.brands" pues vamos DIRECTO por id
    let simpleConfig = { ...config }
    delete simpleConfig.addRankingPosition
    delete simpleConfig.isPuig
    // bug #320 Add same limit to variations
    // delete simpleConfig.limit
    let ids = data
      .filter((d, i) => variationsPagination[0] <= i && variationsPagination[1] >= i)
      .map(d => d.id)
    return getTopEntities(
      type,
      {
        ...previousFilters,
        id: ids,
        brands: undefined,
      },
      values,
      simpleConfig
    ).then(prevData => {
      return data.map(datum => {
        let current = datum
        let previous = prevData.find(dd => dd.id === datum.id)
        let variations = getVariations(current, previous, filters, previousFilters, values)
        return {
          ...datum,
          // vsLastPeriod: variations['vPopIndex'].vsLastPeriod,
          // trend: variations['vPopIndex'].trend,
          variations: variations,
        }
      })
    })
  })
}
/**
 *
 * @param {string} type
 * @param {object} filters
 * @param {Date | string} filters.from
 * @param {Date | string} filters.to
 * @param {any[]} values Indicar que se quiere en al respuesta
 * @param {object} config
 * @param {any} config.addRankingPosition
 * @param {any} config.aggregations
 * @returns
 */
export async function getTopEntities(type, filters, values, config = {}) {
  // console.log('FETCHING DATA', filters)
  const entityKpis = await getIndicators(filters)
  if (!values) values = getAvailableElements(entityKpis, type)
  filters = parseFilters(filters, type)
  let query = `?type=${type}`
  const from = new Date(filters.from)
  const to = new Date(filters.to)
  // set to 0
  setUTCHHMMSSToZero(from)
  setUTCHHMMSSToZero(to)

  return getCached('post', process.env.VUE_APP_API_URL + `/data/top` + query, {
    mode: 'TOP', // Acepta TOP, EVOLUTIVE para conseguir evolutivos o top10
    entity: type, // Acepta FRAGRANCE,BRAND,FAMILY,PERFUMIST, INGREDIENT
    country: filters.country, // Paises en CODE ISO Alpha-3. Actualmente ESP
    sensor: filters.sensor,
    id: filters.id,
    from: from, // Fecha desde donde se quiere hacer la consulta
    to: to, // Fecha hasta donde se quiere hacer la consulta
    gender: filters.gender, // Genero del perfume U,M,F
    limit: config.limit || 20, // Limite de resultados para consultas top10
    values: values, //,  // Indicador que se quiere en la respuesta
    aggregations: config.aggregations,
    entityFilters: filters.entityFilters,
    isPuig: config.isPuig,
    ingredientCombinations: filters.ingredientCombinations,
    addRankingPosition: config.addRankingPosition,
    onlyPrincipal: filters.onlyPrincipal, // solo para ingredients: elimina los sinónimos, ticket #127
    groupByRetailer: config.groupByRetailer,
    classification: filters.classification,
  }).then(response => {
    return response.data.map(d => {
      let result = {
        ...d,
        ...d.info,
        entityType: type,
      }
      delete result.info
      return result
    })
  })
}

function convertDates(array) {
  if (array) array.forEach(d => (d.date = new Date(d.date)))
  return array
}
export async function getEvolutive(type, id, filters, values, config = {}) {
  const entityKpis = await getIndicators(filters)
  if (!values) values = getAvailableElements(entityKpis, type)
  if (!Array.isArray(id)) id = [id]
  // console.log('FETCHING DATA', filters)
  filters = parseFilters(filters)
  let dayCount = dayjs(filters.to).diff(dayjs(filters.from), 'day')
  let period
  if (dayCount > 700) period = 'years'
  else if (dayCount > 100) period = 'months'
  else period = 'days'

  let query = `?type=${type}`

  return getCached(
    'post',
    process.env.VUE_APP_API_URL + `/data/evol` + query,
    {
      entity: type, // Acepta FRAGRANCE,BRAND,FAMILY,PERFUMIST, INGREDIENT
      id: id, // Para consultas EVOLUTIVE, el id de la entidad
      country: filters.country, // Paises en CODE ISO Alpha-3. Actualmente ESP
      sensor: filters.sensor,
      from: filters.from, // Fecha desde donde se quiere hacer la consulta
      to: filters.to, // Fecha hasta donde se quiere hacer la consulta
      gender: filters.gender, // Genero del perfume U,M,F
      values: values, // Indicador que se quiere en la respuesta
      period: period, // Unidad temporal acepta ["days","weeks","months","years"]
      aggregations: config.aggregations,
    },
    1
  ).then(response => {
    let data = response.data.ids
    if (id.length === 1) return convertDates(data[Object.keys(data)[0]])
    else
      return Object.keys(data).map(key => {
        return {
          id: key,
          values: convertDates(data[key]),
        }
      })
  })
  // .then(response => {
  //   console.log('Evol response: ', response)
  //   return response
  // })
}

// export function getSensorsOverview(filters = {}) {
//   console.log('should use filters too', filters)
//   return axios.get('fake_data/product-insights/sensorsOverview.json').then(resp => {
//     return resp.data
//   })
// }

export function getSensors(topFilters) {
  return getCached('get', process.env.VUE_APP_API_URL + '/data/sensors')
    .then(resp => {
      let sensors = resp.data.reduce((acc, retailer) => {
        return [
          ...acc,
          retailer.hosts
            .flatMap(h => h.sensors)
            .map(s => {
              return { ...s, retailer: retailer.name.toUpperCase(), retailerInfo: retailer }
            }),
        ].flatMap(s => s)
      }, [])
      const filters = parseFilters(topFilters)

      sensors = sensors.filter(s => s.id !== 'wikiperfum2_local' && s.id !== 'wikipevvvvvvrfum2')

      if (filters.country && filters.country.length > 0) {
        sensors = sensors.filter(s => s.countries.some(l => filters.country.includes(l)))
      }
      if (filters.technology && filters.technology.length > 0) {
        sensors = sensors.filter(s => filters.technology.includes(s.type))
      }
      return sensors
    })
    .then(sensors => {
      sensors.forEach(s => {
        if (!s.from || !s.from.getDate) {
          s.from = new Date(s.from)
          s.to = new Date(s.to)
        }
      })

      return sensors
    })
}

export function getTopValues(filters) {
  return getCached('post', process.env.VUE_APP_API_URL + '/funnel/top', filters).then(
    res => res.data
  )
}

export function getBrands() {
  return getCached('get', process.env.VUE_APP_API_URL + '/brands/').then(res => res.data)
}

export function getPacoConcepts() {
  return getCached('get', process.env.VUE_APP_API_URL + '/paco/concepts').then(res => res.data)
}

export function getPacoCatalog() {
  return getCached('get', process.env.VUE_APP_API_URL + '/paco/catalog/all').then(res => res.data)
}

export function getPacoFamilies() {
  return getCached('get', process.env.VUE_APP_API_URL + '/families/?lang=en-US&hostId=9').then(
    res => res.data
  )
}
export function getFamilies() {
  return getCached('get', process.env.VUE_APP_API_URL + '/families/').then(res => res.data)
}
export function getPacoIntensities() {
  return getCached('get', process.env.VUE_APP_API_URL + '/paco/intensities').then(res => res.data)
}

export function getSensorsWithMoreFakes() {
  const types = [
    'AIR_PARFUM',
    'ONLINE_PROFILER',
    'INSTORE_PROFILER',
    'CONCEPTS',
    'VISION',
    'OTHER',
    'QR',
  ]
  const retailers = ['Notino', 'Nocibe', 'Sephora', 'WikiParfum']
  let extra_fake = Array(50)
    .fill()
    .map((x, i) => i)
    .map(i => {
      return {
        id: 'fake_sensor_' + i,
        label: 'Fake Sensor ' + i,
        type: types[i % types.length],
        retailer: retailers[i % retailers.length],
      }
    })
  return getSensors().then(resp => {
    return [...resp, ...extra_fake]
  })
}
export function getCountries() {
  return getCached('get', process.env.VUE_APP_API_URL + '/data/countries').then(resp => {
    return resp.data
  })
}

export function getFunnelNodeTrees() {
  // return getCached('get', `funnel-data/fake-funnel-data.json`).then(resp => {
  //   resp.data.forEach(row => {
  //     Object.keys(row).forEach(key => {
  //       if (key !== 'id') row[key] = Math.round(100 * Math.random())
  //     })
  //   })
  //   return JSON.parse(JSON.stringify(resp.data))
  return getCached('get', process.env.VUE_APP_API_URL + '/funnel/treeList').then(resp => {
    return resp.data
  })
}

export function getFakeFunnelData() {
  return getCached('get', `funnel-data/fake-funnel-data.json`).then(resp => resp.data)
}

export function getFunnelNodeTreesLocal() {
  return getCached(
    'get',
    process.env.BASE_URL + '/funnel-data/v2/nodeTrees/paco_rabanne.json'
  ).then(resp => {
    return [resp.data]
  })
}

export function getFunnelData(filters) {
  // const retailer = sensors.find(sensor => sensor.id === filters.sensor[0]).retailer
  // return getCached('get', `funnel-data/nodeTrees/${retailer.toLowerCase()}.json`).then(resp => {
  //   return resp.data
  // })
  return getCached('post', process.env.VUE_APP_API_URL + '/funnel/data', filters).then(resp => {
    return resp.data
  })
}

export function getIngredientsList(filters = {}, previousFilters = {}) {
  let proms = [
    // axios.get('fake_data/ingredients.json'),
    getCached('get', process.env.VUE_APP_API_URL + '/ingredients/'),
    getTopEntitiesWithVariations('INGREDIENT', filters, previousFilters, null, { limit: 100 }),
  ]
  return Promise.all(proms).then(resps => {
    return resps[0].data
      .map(ing => {
        let candidate = resps[1].find(d => d.id === ing.id)
        return (
          candidate && {
            ...ing,
            vPopIndex: candidate.vPopIndex,
            variations: candidate.variations,
          }
        )
      })
      .filter(d => d)
    // return resps[1]
  })
  // return axios.get('fake_data/ingredients.json').then(resp => {
  //   return resp.data.map(ing => {
  //     return {
  //       ...ing,
  //       vPopIndex: Math.random() * 100,
  //     }
  //   })
  // })
}

/**
 * Get the TOP fetch with ingredient combinations.
 * the last fake data: fake_data/ingredients_combinations.json
 * @param {Filters} filters
 * @param {Config} config
 * @returns
 */
export async function getIngredientsCombinations(filters, config) {
  // return axios.get('fake_data/ingredients_combinations.json').then(resp => {
  // return axios.get('fake_data/user_data_ingredients_combinations.json').then(resp => {
  //   return resp.data
  // })
  return getTopEntities('INGREDIENT', { ...filters, ingredientCombinations: true }, null, config)
}

/**
 *
 * @param {Ingredient[]} ings
 * @returns
 */
export function searchByIngredients(ings) {
  return axios
    .post(
      process.env.VUE_APP_API_URL + '/perfume_search/by_ingredients/',
      ings.map(i => {
        return { ingredientId: i.id, proportion: 10 }
      })
    )
    .then(resp => {
      return resp.data
    })
}

export async function getIngredientCombinationPerfumes(ings, filters = {}, previousFilters = {}) {
  return getTopEntitiesWithVariations(
    'FRAGRANCE',
    {
      ...filters,
      ingredients: ings.map(i => i.id),
    },
    {
      ...previousFilters,
      ingredients: ings.map(i => i.id),
    },
    null,
    { limit: 5 }
  )
}

// export async function getIngredientCombinationPerfumes(ings, filters = {}) {
//   let proms = [
//     getTopEntities('FRAGRANCE', filters, null, { limit: 100 }),
//     searchByIngredients(ings),<
//   ]
//   return Promise.all(proms).then(resps => {
//     // console.log('should match responses', resps[0], resps[1])
//     // return resps[0].slice(0, 5)
//     return resps[0].filter(p => resps[1].find(pp => pp.perfume.id === p.id))
//   })
// }
/**
 * Add filter by date: Issue #314 @see https://code.bestiario.org/devel/puig/DoD/-/issues/314
 * @param {Object} filters
 * @param {string | Date} filters.to
 * @param {string | Date} filters.from
 * @returns
 */
export async function getIndicators(filters) {
  var data = await getCached('get', process.env.VUE_APP_API_URL + '/data/indicators/')
  // infinite-loop with data = data.data or similar
  // some component set this cached data reactive and create a loop when we change it
  // same result if we change the return
  data = JSON.parse(JSON.stringify(data.data))
  // Issue #314: If time gap is greater than 3 month, then filter vUniqueVisitors
  if (
    filters &&
    filters.from &&
    filters.to &&
    dayjs(filters.to).diff(filters.from, 'month', true) > 3
  ) {
    data['SESSION'].available = data['SESSION'].available.filter(e => e !== 'vUniqueVisits')
  }

  return Object.keys(data).reduce((obj, key) => {
    obj[key.toLowerCase()] = data[key]
    return obj
  }, {})
}

export async function getCartridgeNames() {
  const response = await getCached('get', 'data/cartridges/cartridge_translations.json')
  return response.data
}

import { clearCache, profile } from '../../../services/api'
import { defaultAllowdViews } from '../../../settings/permissionSettings'

export default {
  state: {
    data: null,
  },
  getters: {
    user: state => state.data,
  },
  mutations: {
    setUser (state, user) {
      if (user && (!user.allowedViews || user.allowedViews.length === 0))
        user.allowedViews = defaultAllowdViews
      state.data = user
    },
  },
  actions: {
    async getProfile (context) {
      if (context.getters.user) return context.getters.user
      const data = await profile()
      await context.commit('setUser', data.data)
      return context.getters.user
    },
    login (context, user) {
      context.commit('setUser', user)
      return user
    },
    logout (context) {
      context.commit('setUser', null)
      clearCache()
    },
  },
}

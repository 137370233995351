/**
 *
 * @param {[number,number]} range1
 * @param {[number,number]} range2
 * @param {[number,number]} byDefault Default value if interseccion is empty
 * @returns
 */
export function findIntersection(range1, range2, byDefault = null) {
  // Get the maximum of the lower bounds
  var start = Math.max(range1[0], range2[0])
  // Get the minimum of the upper bounds
  var end = Math.min(range1[1], range2[1])

  // Check if there is an intersection
  if (start <= end) {
    return [start, end]
  } else {
    return byDefault // No intersection
  }
}

<template>
  <div v-if="!loading" class="page1 flex">
    <div class="left f-70">
      <div class="header">
        <div class="header-date">
          <div class="bold">Scentuary</div>
          <div class="date">{{ from }}/{{ to }}</div>
        </div>
        <div class="title">Dear John, welcome to your monthly report by Scenturay Insights</div>
        <div class="subtitle">
          We are pleased to share with you the key takeaways from {{ from }}
        </div>
      </div>
      <div class="main-info">
        <div class="flex">
          <div class="card f-50">
            <p class="card-name">Total visits</p>
            <p class="card-value bg">{{ sessionInfo.vViews }}</p>
            <percentage :value="percentage" />
          </div>
          <div class="card f-50">
            <p class="card-name">Conversion rate</p>
            <div class="flex">
              <p class="card-value-info f-50">
                <b>{{ highestConversionRate.name }}</b> has the highest conversion rate
              </p>
              <p class="card-value sm f-50">{{ highestConversionRate.value }}</p>
            </div>
            <hr />
            <div class="card-name">Purchase intention</div>
            <div class="card-value-info">
              <b>{{ purchaseIntention.name }}</b> has the greatest purchase intention with
              {{ purchaseIntention.value }} clicks to buy
            </div>
          </div>
        </div>
        <div v-if="trendingBand" class="flex">
          <div class="card f-50">
            <div class="card-name">Top trending Puig brands</div>
            <div class="card-value-info">
              <b>{{ trendingBand.name }}</b> is the highest rated and sits in the top 10 brands in
              terms of awareness
            </div>
          </div>
          <div v-if="trendingProduct" class="card f-50">
            <div class="flex">
              <div class="f-70">
                <div class="card-name">Top trending Puig product</div>
                <div class="card-value-info">
                  This past month <b>{{ trendingProduct.name }}</b> by
                  <b>{{ trendingProduct.brand.name }}</b
                  >is the highest rated and sits in the top 10 brands in terms of awareness
                </div>
              </div>
              <div class="f-30">
                <img :src="trendingProduct.imageUrl" :alt="trendingProduct.name" />
              </div>
            </div>
          </div>
        </div>
        <div class="top-trending">
          <div class="title">Top trending in the world and in your region</div>
          <div class="flex">
            <div v-if="topTrendingProductsWorld" class="card perfume f-50">
              <div class="flex">
                <div class="card">
                  <p class="card-name">Trending in top of mind</p>
                  <div class="flex">
                    <span>
                      <fragrance-description :data="topTrendingProductsWorld" />
                      <a :href="topTrendingProductsWorld.url">View More</a>
                    </span>
                    <img :src="topTrendingProductsWorld.imageUrl" />
                  </div>
                </div>
                <div class="card">
                  <p class="card-name">Trending in purchase intention</p>
                  <div class="flex">
                    <span>
                      <fragrance-description :data="topTrendingProductsPurchase" />
                      <a :href="topTrendingProductsPurchase.url">View More</a>
                    </span>
                    <img :src="topTrendingProductsPurchase.imageUrl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right f-30">
      <div class="title">These are your best performing products and brands.</div>
      <div class="section">
        <div class="title">Top-Mind</div>
        <div v-if="topAddToProfilePerfume" class="card">
          <div class="card-name">Added to profile</div>
          <div class="card-value">{{ topAddToProfilePerfume.value }}</div>
          <img :src="topAddToProfilePerfume.imageUrl" :alt="topAddToProfilePerfume.name" />
          <fragrance-description :data="topAddToProfilePerfume" />
        </div>
        <div v-if="topAddToProfileBrand" class="card">
          <div class="card-name">Added to profile</div>
          <div class="card-value">{{ topAddToProfileBrand.value }}</div>
          <img :src="topAddToProfileBrand.imageUrl" :alt="topAddToProfileBrand.name" />
        </div>
      </div>
      <div class="section">
        <div class="title">Purchase Intention</div>
        <div class="card">
          <div class="card-name">Click to buy</div>
          <div class="card-value">{{ clickToBuyPerfume.value }}</div>
          <img :src="clickToBuyPerfume.imageUrl" :alt="clickToBuyPerfume.name" />
          <fragrance-description :data="clickToBuyPerfume" />
        </div>
        <div class="card">
          <div class="card-name">Click to buy</div>
          <div class="card-value">{{ clickToBuyBrand.value }}</div>
          <img :src="clickToBuyBrand.imageUrl" :alt="clickToBuyBrand.name" />
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import dayjs from 'dayjs'
import Percentage from '../productInsights/Percentage.vue'
import FragranceDescription from '../productInsights/InsightDisplay/FragranceDescription.vue'
import { getTopEntities } from '../../services/api'
const perfumeFake = {
  id: 1,
  name: 'Good Girl',
  brand: { name: 'Carolina Herrera' },
  family: { name: 'Floral', color: 'red' },
  subfamily: { name: 'Musk', color: 'blue', value: 123999 },
}

export default {
  components: {
    Percentage,
    FragranceDescription,
  },
  asyncComputed: {
    trendingProduct() {
      // Realmente sería buscar que perfumes han aparecidos nuevos en el top 3
      return this.fetchTop({ type: 'FRAGRANCE', isPuig: true }).then(data => data[0])
    },
    trendingBand() {
      return this.fetchTop({ type: 'BRAND', isPuig: true }).then(data => data[0])
    },
    topTrendingProductsWorld() {
      const url = 'https://TODO'
      return this.fetchTop({ type: 'FRAGRANCE' }).then(data => ({ ...data[0], url }))
    },
    topTrendingProductsPurchase() {
      const url = 'https://TODO'
      return this.fetchTop({ type: 'FRAGRANCE' }).then(data => ({ ...data[0], url }))
    },
    sessionInfo() {
      return this.fetchTop({ type: 'SESSION' }).then(data => data[0])
    },
    lastSessionInfo() {
      return this.fetchTop({
        type: 'SESSION',
        to: this.from,
        from: this.from.clone().month(this.from.month() - 1),
      }).then(data => data[0])
    },
  },
  computed: {
    percentage() {
      if (!this.sessionInfo || !this.lastSessionInfo) return 0
      return this.sessionInfo.vViews / this.lastSessionInfo.vViews - 1
    },
    loading() {
      return Object.keys(this.$asyncComputed).some(field => this.$asyncComputed[field].updating)
    },
    from() {
      return dayjs('01-03-2023', 'DD-MM-YYYY')
    },
    to() {
      return dayjs('01-04-2023', 'DD-MM-YYYY')
    },
    highestConversionRate() {
      var value = 39.12
      return { ...perfumeFake.brand, value }
    },
    purchaseIntention() {
      var value = 8362
      return { ...perfumeFake.brand, value }
    },
    topAddToProfilePerfume() {
      return perfumeFake
    },
    topAddToProfileBrand() {
      return perfumeFake.brand
    },
    clickToBuyPerfume() {
      return perfumeFake
    },
    clickToBuyBrand() {
      return perfumeFake.brand
    },
  },
  methods: {
    fetchTop({ type, isPuig, to, from }) {
      return getTopEntities(
        type,
        { from: from || this.from, to: to || this.to },
        ['vBounceRate', 'vDuration', 'vPages', 'vProfile', 'vSessions', 'vUniqueVisits', 'vViews'],
        {
          limit: 1,
          isPuig,
        }
      )
    },
  },
}
</script>
  <style lang="stylus" scoped>
  .flex
    display: flex

    .f-50
      flex: 1 1 50%

    .f-70
      flex: 1 1 70%

    .f-30
      flex: 1 1 30%

  .page1
    .card
      border: 1px solid red

      img
        width: vw(100px)
</style>
<template>
  <div v-if="funnelNodeTrees" class="top-chosen-view">
    <div class="top-chosen-panel">
      <div class="top-chosen-header">
        <Filters v-model="filters" :global="false" />
      </div>
      <TopChosenCards v-if="currentFunnelTree" :filters="topChosenRequestFilters" :context-events="currentFunnelTree.contextEvents" />
      <div v-else>{{ $t('funnel_top_chosen_no_sensor_selected') }}</div>
    </div>
  </div>
</template>

<script>
import Filters from '../components/Filters.vue'
import TopChosenCards from '../components/funnel/TopChosen.vue'
import { getFunnelNodeTrees } from '../services/api'

export default {
  name: 'TopChosen',
  components: {
    Filters,
    TopChosenCards,
  },
  asyncComputed: {
    funnelNodeTrees() {
      return getFunnelNodeTrees()
    },
  },
  data() {
    return {
      filters: this.$store.getters.topFilters,
    }
  },
  computed: {
    funnelSensorsIds() {
      return this.funnelNodeTrees ? this.funnelNodeTrees.map(({ sensors }) => sensors).flat() : []
    },
    topChosenRequestFilters() {
      const { sensor, ...rest } = this.filters
      return { ...rest, sensors: sensor, id: this.currentFunnelTree.id }
    },
    currentFunnelTree() {
      return (
        this.funnelNodeTrees &&
        this.filters.sensor &&
        this.funnelNodeTrees.find(tree =>
          this.filters.sensor.some(sensor => tree.sensors.includes(sensor))
        )
      )
    },
  },
  watch: {
    funnelNodeTrees: {
      handler: function (val, oldVal) {
        if (val && !oldVal) {
          const firstTreeSensors = val.filter(t => t.allowTopChosenQuery)[0].sensors
          const currentSensors = this.filters.sensor
          const matchingSensors = currentSensors?.filter(s => firstTreeSensors.includes(s))
          if (matchingSensors?.length > 0) {
            this.filters.sensor = matchingSensors
          } else {
            this.filters.sensor = firstTreeSensors
          }
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.$store.dispatch('setTopFilters', this.filters)
  },
}
</script>

<style lang="stylus" scoped>
.top-chosen-view
  display: flex
  height: 100%

.top-chosen-panel
  flex: 1 1 0%
  overflow-y: auto
  padding: vw(30px)
  padding-top: 0

.top-chosen-header
  $gap = vw(15px)
  margin-bottom: vw(40px)

  &:empty
    display: none

  position: sticky
  top: 0
  z-index: 1
  padding-top: vw(30px)
  padding-bottom: vw(30px)
  border-bottom: 1px solid #f2f2f2
  background-color: #fff

  >>> .header-options__filters
    gap: $gap

  >>> .header-options__filters__filter
    flex: 1 1 "calc(50% - %s)" % $gap

  >>> .header-options__filters__filter__title
    text-align: right

  >>> .multiselect-container
    flex: 1 1 0%

  >>> .multiselect
    flex: 1 1 0%

  >>> .date-range-picker
    flex: 1 1 0%
</style>
<template>
  <div class="map">
    <div v-if="$store.state.debug" ref="debug" class="debug" />
    <canvas
      ref="canvas"
      :width="size.width"
      :height="size.height"
    />
  </div>
</template>

<script>
import { geoPath as d3_geoPath } from 'd3-geo'
// import { geoEqualEarth as my_projection } from 'd3-geo'
import { geoNaturalEarth1 as my_projection } from 'd3-geo'

// import { interpolate as d3_interpolate, piecewise as d3_piecewise } from 'd3-interpolate'
import world_features from '@/assets/geojson/world_states.geo.json'
import Size from '@/mixins/Size'

export default {
  mixins: [Size],
  props: {
    activeCountries: {
      type: Array,
      default: () => [],
    },
    mapState: {
      type: Object,
      default: () => ({ scale: 300, centerLat: 20, centerLon: 0 }),
    },
  },
  watch: {
    mapState() {
      this.redraw()
    },
    size() {
      this.$nextTick(this.redraw)
    },
  },
  mounted() {
    this._hiddenCanvas_ = document.createElement('canvas')
    if (this.$store.state.debug) this.$refs.debug.append(this._hiddenCanvas_)
  },
  methods: {
    redraw() {
      let newProj = my_projection()
        .scale(this.mapState.scale)
        // .translate([this.size.width / 2, this.size.height / 2])
        .translate([this.size.width / 2, this.size.height / 2])
        .center([this.mapState.centerLon, this.mapState.centerLat])
      // .rotate([newValue.state.rotateLambda, newValue.state.rotatePhi, newValue.state.rotateGamma])
      this.draw(newProj)
      this.$emit('update', newProj)
    },
    fillGridData() {
      let land = world_features.features

      let hiddenSize = {
        width: 1400,
        height: 1400,
      }

      let projection = my_projection()
        .scale(this.mapState.scale)
        // .translate([this.size.width / 2, this.size.height / 2])
        .translate([hiddenSize.width / 2, hiddenSize.height / 2])
        .center([0, 0])

      this._hiddenCanvas_.setAttribute('width', hiddenSize.width)
      this._hiddenCanvas_.setAttribute('height', hiddenSize.height)

      let context = this._hiddenCanvas_.getContext('2d')

      const path = d3_geoPath()
        .projection(projection)
        .context(context)
      // context.save()

      context.fillStyle = '#000'
      context.fillRect(0, 0, hiddenSize.width, hiddenSize.height)
      context.strokeStyle = '#fff'

      // context.fillStyle = '#fff'

      land.forEach(d => {
        // '#' + (((1 << 24) * Math.random()) | 0).toString(16)
        if (this.activeCountries.indexOf(d.properties.adm0_a3) >= 0) context.fillStyle = '#ff0000'
        else context.fillStyle = '#00ff00'
        context.strokeStyle = context.fillStyle
        context.beginPath()
        path(d)
        context.fill()
        context.stroke()
      })

      this._gridData_ = []
      let size = 6
      let gridSize = [size, size]
      if (size > 0) {
        var myGetImageData = context.getImageData(0, 0, hiddenSize.width, hiddenSize.height)
        var sourceBuffer8 = new Uint8Array(myGetImageData.data.buffer)
        for (var i = 0; i < hiddenSize.width / gridSize[0]; i++) {
          for (var j = 0; j < hiddenSize.height / gridSize[1]; j++) {
            let x = Math.round(i * gridSize[0])
            let y = Math.round(j * gridSize[1])
            if (
              sourceBuffer8[4 * (x + y * hiddenSize.width)] > 80 ||
              sourceBuffer8[4 * (x + y * hiddenSize.width) + 1] > 80
            ) {
              let color = {
                r: sourceBuffer8[4 * (x + y * hiddenSize.width)],
                g: sourceBuffer8[4 * (x + y * hiddenSize.width) + 1],
                b: sourceBuffer8[4 * (x + y * hiddenSize.width) + 2],
              }
              this._gridData_.push({
                i: i,
                j: j,
                color: color,
              })
            }
          }
        }
      }
      this._gridData_.proj = projection
      this._gridData_.gridSize = gridSize

      let r = 2.5
      var off = document.createElement('canvas')
      off.width = 2 * 2 * r
      off.height = 2 * r
      var ctx = off.getContext('2d')

      ctx.fillStyle = '#56a'
      ctx.beginPath()
      ctx.arc(r, r, r * 0.7, 0, 2 * Math.PI)
      ctx.closePath()
      ctx.fill()

      ctx.fillStyle = '#888'
      ctx.beginPath()
      ctx.arc(2 * r + r, r, r * 0.7, 0, 2 * Math.PI)
      ctx.closePath()
      ctx.fill()

      this._gridData_.circleImage = off
    },
    draw(projection) {
      // let projection = my_projection()

      // onto the real one
      let context = this.$refs.canvas.getContext('2d')
      if (!this.$store.state.debug) {
        context.fillStyle = '#fff'
        context.fillRect(0, 0, this.size.width, this.size.height)
      }

      if (!this._gridData_) this.fillGridData(projection)
      let dotR = this._gridData_.circleImage.height
      let drawDot = (coords, color) => {
        if (color === 'red') {
          context.drawImage(
            this._gridData_.circleImage,
            0,
            0,
            dotR,
            dotR,
            coords[0],
            coords[1],
            dotR,
            dotR
          )
        } else {
          context.drawImage(
            this._gridData_.circleImage,
            dotR,
            0,
            dotR,
            dotR,
            coords[0],
            coords[1],
            dotR,
            dotR
          )
        }
        // context.beginPath()
        // if (color === 'grey') context.fillStyle = '#dddddd'
        // else context.fillStyle = '#ff0000'
        // context.arc(coords[0], coords[1], 4, 0, 2 * Math.PI)
        // context.fill()
      }

      let size = this._gridData_.gridSize
      this._gridData_.forEach(gd => {
        let i = gd.i
        let j = gd.j
        let color = gd.color
        let proj = projection(this._gridData_.proj.invert([i * size[0], j * size[1]]))

        if (color.r < 80 && color.g > 80) drawDot(proj, 'grey')
        else if (color.r > 80 && color.g < 80) drawDot(proj, 'red')
      })

      // if (this.dataPoints) this.drawDataPoints(projection)
    },
    // drawDataPoints(projection) {
    //   let context = this.$refs.canvas.getContext('2d')
    //   let pointR = 20
    //   this.dataPoints.forEach(d => {
    //     let proj = projection(d.coords)
    //     context.beginPath()
    //     context.arc(proj[0], proj[1], pointR, 0, 2 * Math.PI)
    //     context.stroke()
    //     // context.drawImage(
    //     //   this._gridData_.circleImage,
    //     //   0,
    //     //   0,
    //     //   pointR,
    //     //   pointR,
    //     //   proj[0],
    //     //   proj[1],
    //     //   pointR,
    //     //   pointR
    //     // )
    //   })
    // },
  },
}
</script>

<style lang="stylus" scoped>
.map
  position: relative
  overflow: hidden
  width: 100%
  height: 100%

  // border: solid 2px
  canvas
    position: absolute
    top: 0
    // z-index: 999
    width: 100%
    height: 100%
</style>

<template>
  <div class="graph" :style="'height:' + height + 'px'">
    <svg
      ref="svg"
      :width="size.width"
      :height="size.height"
    />
  </div>
</template>

<script>
import Size from '@/mixins/Size'
import { select as d3_select } from 'd3-selection'
import { scaleLinear as d3_scaleLinear, scaleTime as d3_scaleTime } from 'd3-scale'
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis'
import { extent as d3_extent } from 'd3-array'
import { line as d3_line } from 'd3-shape'
import { timeDay as d3_timeDay } from 'd3-time'
// eslint-disable-next-line
import { transition } from 'd3-transition'

export default {
  mixins: [Size],
  props: {
    dataProps: Array,
    area: Boolean,
    height: Number,
    backgroundLines: Boolean,
    colorGraph: String,
    selected: Date,
  },
  computed: {
    data() {
      return [
        {
          id: 'only-line',
          values: this.dataProps.map(d => {
            return {
              id: d.date,
              x: d.date,
              y: d.value,
            }
          }),
        },
      ]
    },
    lineClasses() {
      return 'graph__line graph__line--' + this.colorGraph
    },
  },
  watch: {
    data() {
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  methods: {
    draw() {
      // console.log('DRAWGIN GRAPH')
      let svg = d3_select(this.$refs.svg)
      let root = svg.selectAll('.root').data([null])
      let rootEnter = root.enter().append('g').attr('class', 'root')

      rootEnter.append('g').attr('class', 'xAxis')
      rootEnter.append('g').attr('class', 'yAxis')

      root = root.merge(rootEnter)
      let items = root.selectAll('.item').data(this.data, d => d.id)

      let padding = {
        top: this.backgroundLines ? 10 : 4,
        right: this.backgroundLines ? 30 : 4,
        bottom: this.backgroundLines ? 20 : 4,
        left: this.backgroundLines ? 30 : 4,
      }
      let chartArea = {
        width: parseInt(this.size.width) - padding.left - padding.right,
        height: parseInt(this.size.height) - padding.top - padding.bottom,
      }

      //-----------
      let yScale = d3_scaleLinear()
        .domain(d3_extent(this.data[0].values, d => d.y))
        .range([chartArea.height, 0])
        .nice()

      let xScale = d3_scaleTime()
        .domain(d3_extent(this.data[0].values, d => d.x))
        .range([0, chartArea.width])

      const compositionScale = date => {
        let obj = this.data[0].values.find(e => e.x.getTime() === date.getTime())
        if (obj) return yScale(obj.y)
        return 0
      }
      if (this.backgroundLines) {
        //xAxis
        root
          .append('g')
          .classed('xAxis', true)
          .attr(
            'transform',
            'translate(' + padding.left + ',' + (chartArea.height + padding.top) + ')'
          )
          .call(d3_axisBottom(xScale).ticks(d3_timeDay.every(1)).tickSize(-chartArea.height))

        //yAxis
        root
          .append('g')
          .classed('yAxis', true)
          .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
          .call(d3_axisLeft(yScale).ticks(2).tickSize(-chartArea.width))
      }

      // Selected dot
      var selectedDate = []
      if (this.selected) selectedDate.push(this.selected)

      var itemsDots = root.selectAll('.dot').data(selectedDate, d => d)
      const itemsDotsEnter = itemsDots.enter().append('g').attr('class', 'dot')
      itemsDotsEnter
        .append('circle')
        .attr('r', 2)
        .attr('fill', 'none')
        .attr('stroke', this.colorGraph)
        .style('opacity', 1)
      itemsDots.exit().style('opacity', 0).remove()
      itemsDots = itemsDots.merge(itemsDotsEnter)
      itemsDots.attr(
        'transform',
        d => `translate(${xScale(d) + padding.left},${compositionScale(d) + padding.top})`
      )

      // Path
      const xValue = d => d.x
      const yValue = d => d.y

      const lineGenerator = d3_line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))

      let itemsEnter = items.enter().append('g').attr('class', 'item')

      itemsEnter
        .append('path')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
        .attr('class', this.lineClasses)
        // .attr('stroke', 'blue')
        .attr('d', d => lineGenerator(d.values.map(d => ({ x: d.x, y: 0 }))))
        // .attr('d', d => {
        //   console.log(
        //     'ENTER',
        //     d.values.map(d => d.x)
        //   )
        //   return lineGenerator(d.values.map(d => ({ x: d.x, y: 0 })))
        // })
        .style('opacity', 0)

      let itemsExit = items.exit()
      itemsExit.remove()

      items = items.merge(itemsEnter)

      items.attr(
        'transform',
        d => `translate(${d.x ? d.x : 0 * this.size.width},${d.y ? d.y : 0 * this.size.height})`
      )
      let itemsTrans = items.transition()

      itemsTrans
        .select('path')
        .style('opacity', 1)
        .attr('d', d => lineGenerator(d.values))
      // .attr('d', d => {
      //   console.log(
      //     'UPATE',
      //     d.values.map(d => d.x)
      //   )
      //   return lineGenerator(d.values.map(d => ({ x: d.x, y: 0 })))
      // })
    },
  },
}
</script>

<style lang="stylus">
.graph
  position: relative
  margin-top: 10px
  min-height: 30px
  width: 100%

  .domain
    display: none

  .xAxis
    .tick
      color: #C0C0BB

      line
        opacity: 0.5
        stroke: #C0C0BB
        stroke-dasharray: 5 5

  .yAxis
    .tick
      color: #C0C0BB

      line
        opacity: 0.5
        stroke: #C0C0BB

    .tick:first-of-type
      line
        display: none

    .tick:last-of-type
      line
        display: none

  svg
    position: absolute

  &__line
    fill: none
    stroke-width: 1

    &--blue
      stroke: $blue

    &--red
      stroke: red

    &--green
      stroke: green

  &__area
    fill: $melrose
</style>
<template>
  <div class="trending-now">
    <Section :id="'trending_now'" :name="'Latest News'">
      <div class="card-wrapper">
        <Card :title="'Brand'">
          <Loader v-if="$asyncComputed.brand.updating" />
          <InsightDisplay
            v-else-if="brand"
            :insight="brand"
          >
            <template v-slot:thumbnail>
              <div class="brand-name">{{ brand.name }}</div>
            </template>
          </InsightDisplay>
          <Loader v-else :text="$t('no_data')" />
        </Card>
        <Card :title="'Fragrance'">
          <Loader v-if="$asyncComputed.fragrance.updating" />
          <InsightDisplay
            v-else-if="fragrance"
            :insight="fragrance"
          >
            <template v-slot:thumbnail>
              <img class="thumbnail" :src="fragrance.imageUrl">
            </template>
            <template v-slot:description>
              <FragranceDescription :data="fragrance" />
            </template>
          </InsightDisplay>
          <Loader v-else :text="$t('no_data')" />
        </Card>
        <Card :title="'Family'">
          <Loader v-if="$asyncComputed.family.updating" />
          <InsightDisplay
            v-else-if="family"
            :insight="family"
          >
            <template v-slot:thumbnail>
              <FamiliesDonut :data="family" :size="'xl'" />
            </template>
            <template v-slot:description>
              <FamiliesDescription :data="family" />
            </template>
          </InsightDisplay>
          <Loader v-else :text="$t('no_data')" />
        </Card>
        <Card :title="'Ingredient'">
          <Loader v-if="$asyncComputed.ingredient.updating" />
          
          <InsightDisplay
            v-else-if="ingredient && $asyncComputed.ingredient.success"
            :insight="ingredient"
          >
            <template v-slot:thumbnail>
              <img class="img-circle" :src="ingredient.imageUrl">
            </template>
            <template v-slot:description>
              <div class="ing-name">{{ ingredient.name }}</div>
              <div class="ing-family">
                <div class="ing-donut">
                  <FamiliesDonut :data="ingredient" size="xsm" />
                </div>
                <span>{{ ingredient.family.name }}</span>
              </div>
            </template>
          </InsightDisplay>
          <Loader v-else :text="$t('no_data')" />
        </Card>
      </div>
    </Section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Section from './Section.vue'
import Card from './Card.vue'
import InsightDisplay from './InsightDisplay'
import FragranceDescription from './InsightDisplay/FragranceDescription.vue'
import FamiliesDescription from './InsightDisplay/FamiliesDescription.vue'
import FamiliesDonut from './InsightDisplay/FamiliesDonut.vue'
import { getTopEntitiesWithVariations } from '../../services/api'
import { transformToPreviousPeriod } from '@/utils/filterUtils'
// import { getVariations } from '@/utils/variationUtils'
import Loader from '../Loader.vue'
export default {
  components: {
    Section,
    Card,
    InsightDisplay,
    FragranceDescription,
    FamiliesDescription,
    FamiliesDonut,
    Loader,
  },
  computed: {
    // ...mapGetters(['trendingNow']),
    ...mapGetters(['filters']),
    previousFilters() {
      let tmp = transformToPreviousPeriod(this.filters)
      return tmp
    },
  },
  methods: {
    fetchInsight(type) {
      return getTopEntitiesWithVariations(type, this.filters, this.previousFilters).then(data => {
        return data[0]
      })
    },
  },
  asyncComputed: {
    brand() {
      return this.fetchInsight('BRAND')
    },
    fragrance() {
      return this.fetchInsight('FRAGRANCE')
    },
    family() {
      return this.fetchInsight('FAMILY')
    },
    ingredient() {
      return this.fetchInsight('INGREDIENT')
    },
    perfumist() {
      return this.fetchInsight('PERFUMIST')
    },
  },
}
</script>
<style lang="stylus" scoped>
.ing-family
  m-font-size(10, 13)
  display: flex
  font-weight: 300
  font-family: "Lelo"

.ing-donut
  margin-right: 3px
  width: 14px

.trending-now
  .section
    .card-wrapper
      $margin = vw(-10px)
      display: flex
      margin: $margin

      .card
        min-height: vw(500px)

      >>> .card__title
        m-font-size(18, 24)
</style>
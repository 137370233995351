<template>
  <div class="spotlight">
    <Section :id="'spotlight_'+type" :name="'Spotlight'">
      <div v-if="isInView" class="card-wrapper">
        <Card :extended="true">
          <template v-if="selected && $asyncComputed.tops.success">
            <SpotlightDisplay :spotlight="selected" :kpis="popRelatedKpis" :title="`Top ${tops.indexOf(selected)+1} Popular ${type}`">
              <template v-slot:thumbnail>
                <!-- BRAND -->
                <div v-if="type==='BRAND'" class="brand-name">{{ selected.name }}</div>
                <!-- PERFUMIST -->
                <div v-else-if="type==='PERFUMIST'" class="spotlight-thumbnail">
                  <div v-if="selected.image1Url" class="img-container" :style="{backgroundImage:`url(${selected.image1Url})`}" />
                  <div v-else class="initial-name">
                    <div class="name">
                      {{ getInitialsName(selected.name) }}
                    </div>
                  </div>
                  <div class="perfumist-name">
                    {{ selected.name }}
                  </div>
                </div>
                <!-- FAMILY -->
                <div v-else-if="type==='FAMILY'" class="spotlight-thumbnail">
                  <FamiliesDonut
                    :data="selected"
                    :show-images="true"
                    :size="'xl'"
                  />
                  <div class="element-description">{{ selected.family.name }} / {{ selected.subfamily.name }}</div>
                </div>
                <!-- INGREDIENT -->
                <div v-else-if="type==='INGREDIENT'" class="thumbnail-container">
                  <div class="spotlight-thumbnail">
                    <img class="img-circle" :src="selected.imageUrl">
                    <div class="thumbnail-desc">{{ selected.name }}</div>
                  </div>
                  <div class="spotlight-thumbnail">
                    <FamiliesDonut
                      :data="selected"
                      :size="'xl'"
                    />
                    <div v-if="selected.family" class="thumbnail-desc">{{ selected.family.name }}</div>
                  </div>
                </div>
              </template>
            </SpotlightDisplay>
            <div class="carousel-wrapper">
              <InsightDisplayCarousel
                v-if="relatedFragrances"
                :class="{loading:$asyncComputed.relatedFragrances.updating}"
                :insights="relatedFragrances"
                :title="`${type}'s Most Popular Fragrances`"
              />
              <Loader v-else />
            </div>
          </template>
        </Card>
        <Card :title="`Other ${type}s`">
          <ol v-if="selected && $asyncComputed.tops.success" class="rank-list">
            <li
              v-for="(other, i) in tops"
              :key="`${type}_${i}`"
              class="rank-item"
              :class="{selected: selected === other}"
              @click="selected = other"
            >
              <InsightDisplay
                :insight="other"
                :compact="true"
              >
                <template v-slot:thumbnail>
                  <div v-if="type==='BRAND'" class="brand-name">{{ other.name }}</div>
                  <img v-else-if="type==='INGREDIENT'" class="img-circle" :src="other.imageUrl">
                  <div v-else-if="type==='PERFUMIST'" class="perfumist-thumbnail">
                    <div v-if="other.image1Url" class="image" :style="{backgroundImage:`url(${other.image1Url})`}" />
                    <div v-else class="image initials">
                      <div class="name">
                        {{ getInitialsName(other.name) }}
                      </div>
                    </div>
                    <div class="name">{{ other.name }}</div>
                  </div>
                  <div v-else-if="type==='FAMILY'"><FamiliesDonut :data="other" :size="'md'" /></div>
                </template>
                <template v-slot:description>
                  <FamiliesDescription
                    v-if="type==='FAMILY'"
                    :data="other"
                    :compact="true"
                  />
                  <div v-else-if="type==='INGREDIENT'">
                    <div>
                      {{ other.name }}
                    </div>
                    <div class="ing-family">
                      <div>
                        <FamiliesDonut :data="other" size="xsm" />
                      </div>
                      <span>{{ other.family.name }}</span>
                    </div>
                  </div>
                </template>
              </InsightDisplay>
              <Separator v-if="i < tops.length - 1" :color="'#E4E4E4'" />
            </li>
          </ol>
          <Loader v-else />
        </Card>
      </div>
      <Loader v-else :text="$t('no_data')" />
    </Section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Section from './Section.vue'
import Card from './Card.vue'
import InsightDisplay from './InsightDisplay'
import SpotlightDisplay from './InsightDisplay/SpotlightDisplay.vue'
import Separator from './Separator.vue'
// import { getTopEntities } from '@/services/api'
import Loader from '../Loader.vue'
import InsightDisplayCarousel from './InsightDisplay/InsightDisplayCarousel.vue'
import FamiliesDonut from './InsightDisplay/FamiliesDonut.vue'
import FamiliesDescription from './InsightDisplay/FamiliesDescription.vue'
import inView from '@/mixins/InView.js'
import IndicatorMixin from '../../mixins/IndicatorMixin'
import { getTopEntitiesWithVariations } from '../../services/api'
import { transformToPreviousPeriod } from '@/utils/filterUtils'
// import { getPopRelatedKpis } from '@/utils/indicatorUtils'
export default {
  components: {
    Section,
    Card,
    InsightDisplay,
    SpotlightDisplay,
    Separator,
    Loader,
    InsightDisplayCarousel,
    FamiliesDonut,
    FamiliesDescription,
  },
  mixins: [inView, IndicatorMixin],
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapGetters(['filters']),
    previousFilters() {
      return transformToPreviousPeriod(this.filters)
    },
    loading() {
      return this.$asyncComputed.relatedFragrances.updating || this.$asyncComputed.tops.updating
    },
  },
  asyncComputed: {
    popRelatedKpis() {
      return this.indicators && this.getPopRelatedKpis(this.type)
    },
    tops() {
      if (!this.isInView) return null
      let kpis = this.popRelatedKpis
      if (!kpis || kpis.length === 0) return []
      return getTopEntitiesWithVariations(this.type, this.filters, this.previousFilters).then(
        data => {
          let tops = data
          if (this.type === 'PERFUMIST') {
            tops = tops.filter(t => t.id !== null)
          }
          return tops.slice(0, 5)
        }
      )
    },
    relatedFragrances() {
      // filtro para el get top entities dependiendo del tipo
      const applyFilter = (() => {
        if (!this.selected) return {}
        const defaultFilter = {}
        switch (this.type) {
          case 'BRAND':
            return { ...defaultFilter, brands: [this.selected.id] }
          case 'FAMILY':
            return { ...defaultFilter, perfumes: [this.selected] }
          case 'INGREDIENT':
            return { ...defaultFilter, ingredients: [this.selected.id] }
          case 'PERFUMIST':
            return { ...defaultFilter, perfumists: [this.selected.id] }
          default:
            return defaultFilter
        }
      })()
      return (
        this.isInView &&
        this.selected &&
        getTopEntitiesWithVariations(
          'FRAGRANCE',
          { ...this.filters, ...applyFilter },
          { ...this.previousFilters, ...applyFilter },
          null, //all values
          {
            limit: 3,
          }
        ).then(data => {
          return data
        })
      )
    },
  },
  watch: {
    tops() {
      this.selected = this.tops[0]
    },
  },
  methods: {
    /**
     *
     * @param {string} str
     */
    getInitialsName(str) {
      return str
        .split(' ')
        .map(name => name.charAt(0).toUpperCase() + '')
        .slice(0, 2)
        .join('')
    },
  },
}
</script>

<style lang="stylus" scoped>
.spotlight
  position: relative

  .spotlight-thumbnail
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: vh(10px)

    .initial-name
      display: flex
      width: vw(200px)
      height: vw(200px)
      border-radius: 100%
      background-color: #eee

      .name
        m-font-size(60, 72)
        margin: auto

    .img-container
      width: vw(200px)
      height: vw(200px)
      border-radius: 100%
      background-position: center center
      background-size: cover
      background-repeat: no-repeat

  .loader
    position: absolute
    z-index: 2

  .card-wrapper
    display: flex

    .card
      min-height: vw(500px)

    .loading
      opacity: 0.5
      transition: opacity 0.3s ease-in-out

    .carousel-wrapper
      flex: 1 1 0%
      width: auto

    .card--extended
      flex: 3 1 0%
      flex-direction: row
      width: auto

    .perfumist-thumbnail
      display: flex
      align-items: center
      padding-right: vw(5px)

      .image
        flex: 0 0 vw(54px)
        width: vw(54px)
        height: vw(54px)
        border-radius: 100%
        background-position: center center
        background-size: cover
        background-repeat: no-repeat

        &.initials
          display: flex
          margin: auto
          background-color: #eee

          .name
            m-font-size(20, 25)
            margin: auto

      .name
        margin-left: vw(10px)
        m-font-size(15, 20)

  .rank-list
    display: flex
    flex: 1 1 0%
    flex-direction: column
    margin-top: 0
    margin-bottom: 0
    padding: 0
    counter-reset: list

  .rank-item
    position: relative
    display: flex
    flex: 1 1 0%
    min-height: vw(80px)

    &:not(:last-child)
      margin-bottom: vw(20px)
      padding-bottom: vw(10px)
      border-bottom: 1px solid rgba(#000, 0.1)

    &:before
      m-font-size(14, 22)
      margin-right: vw(10px)
      padding-top: vw(6px)
      content: counter(list) "."
      counter-increment: list
      font-family: "Lelo"

    &:after
      position: absolute
      top: vw(-10px)
      left: vw(-10px)
      z-index: -1
      display: none
      width: "calc(100% + (%s * 2))" % vw(10px)
      height: "calc(100% + %s)" % vw(10px)
      background-color: rgba(#000, 0.1)
      content: ""

    &:hover,
    &.selected
      &:after
        display: block

    &:hover
      &:not(.selected)
        cursor: pointer

        &:after
          background-color: rgba(#000, 0.025)

    >>> .interactive-graph
      height: 100%

  .element-description
    m-font-size(12, 14)
    font-family: "Lelo"

.ing-family
  m-font-size(10, 13)
  display: flex
  margin-top: vw(2px)
  margin-left: vw(6px)
  font-weight: 300
  font-family: "Lelo"

  span
    margin-left: vw(10px)

  .ing-donut
    margin-right: 3px
    width: 14px

.thumbnail-container
  display: flex
  align-items: center
  text-align: center
  gap: vw(120px)
</style>
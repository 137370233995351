<template>
  <div class="ingredients-cloud">
    <canvas ref="canvas" :width="size.width" :height="size.height" />
    <div class="images" style="display: none">
      <img
        v-for="i in ingredients"
        :id="i.id"
        ref="images"
        :key="i.id"
        :src="i.data.imageUrl"
      >
    </div>
    <div
      v-if="tooltipData"
      class="tooltip-wrapper"
      :class="{'has-button':tooltipData.showButton}"
      :style="tooltipData.style"
    >
      <div class="tooltip">{{ tooltipData.label }}</div>
      <div v-if="tooltipData.showButton" class="details-btn" @click="onDetailsClick">{{ $t('explore_this_relation') }}</div>
    </div>
    <Loader v-if="!$asyncComputed.ingredientsData.success || $asyncComputed.selectedRelated.updating" />
  </div>
</template>

<script>
import { forceSimulation as d3_forceSimulation } from 'd3-force'
import {
  // forceManyBody as d3_forceManyBody,
  // forceCollide as d3_forceCollide,
  // forceLink as d3_forceLink,
  // forceCenter as d3_forceCenter,
  // forceRadial as d3_forceRadial,
  forceX as d3_forceX,
  forceY as d3_forceY,
} from 'd3-force'
import { group as d3_group } from 'd3-array'
import Size from '@/mixins/Size'
import { forceCollideOptional, forceCluster, distance } from '@/utils/simulationUtils'
import { common_tangent_line } from '@/utils/circleUtils'
// import { getTopEntities } from '@/services/api'
import { getIngredientsList } from '@/services/api'
import { pointer as d3_pointer } from 'd3-selection'
import { quadtree as d3_quadtree } from 'd3-quadtree'
import { mapGetters } from 'vuex'
import { extent as d3_extent } from 'd3-array'
import { scaleLinear as d3_scaleLinear } from 'd3-scale'
import Loader from '../components/Loader.vue'
import { getRelated } from '@/utils/ingredientUtils'
import { transformToPreviousPeriod } from '@/utils/filterUtils'

export default {
  components: { Loader },
  asyncComputed: {
    ingredientsData() {
      return (
        this.topFilters.from &&
        getIngredientsList(this.topFilters, transformToPreviousPeriod(this.topFilters))
      )
    },
    selectedRelated() {
      return (
        this.selectedIngredient &&
        getRelated(this.topFilters, this.selectedIngredient.data).then(combintations => {
          let rels = combintations
            .map(c => {
              let candidate = this.ingredients.find(r => r.id === c.ingredientId)
              // if (candidate) candidate.link_r = candidate.r + c.perfumeCount / 10
              if (candidate) candidate.relatedCount = c.count
              return candidate
            })
            .sort((a, b) => b.relatedCount - a.relatedCount)
            .filter(d => d)
            .slice(0, 8)
          let extent = d3_extent(rels, d => d.relatedCount)
          let rScale = d3_scaleLinear().domain(extent).range([0, 30])
          rels.forEach(d => {
            d.link_r = d.r + rScale(d.relatedCount)
          })
          return rels
        })
      )
    },
  },
  mixins: [Size],
  props: {
    selectedPair: Array,
  },
  data() {
    return {
      selectedIngredient: null,
      hoveredIngredient: null,
      tooltipData: null,
    }
  },
  computed: {
    ...mapGetters(['topFilters']),
    clusters() {
      let clusters = []
      d3_group(this.ingredientsData, d => d.family.id).forEach((value, key) => {
        value.sort((a, b) => b.vPopIndex - a.vPopIndex)
        clusters.push({
          id: key,
          family: JSON.parse(JSON.stringify(value[0].family)),
          ingredients: value,
        })
      })
      clusters.sort((a, b) => {
        return b.family.intensity - a.family.intensity
      })

      let total = this.ingredientsData.length

      clusters.forEach((g, i) => {
        g.arcSize = (Math.PI * 2 * g.ingredients.length) / total
        g.startAngle = clusters[i - 1] ? clusters[i - 1].endAngle : Math.PI
        g.endAngle = g.startAngle + g.arcSize
        // let midAngle = g.startAngle + g.arcSize / 2
        // g.x =
        //   Math.sin(midAngle) * (200 + (200 * g.ingredients.length) / total) + this.size.width / 2
        // g.y =
        //   Math.cos(midAngle) * (200 + (200 * g.ingredients.length) / total) + this.size.height / 2
      })
      return clusters
    },
    ingredients() {
      if (!this.ingredientsData) return []

      let extent = d3_extent(this.ingredientsData, d => d.vPopIndex)
      if (extent[0] === extent[1]) extent[1] = extent[0] + 1
      return (this.ingredientsData || []).map(d => {
        let unitPopIndex = d.vPopIndex / extent[1]
        let r = 15 + 45 * unitPopIndex //20 + Math.random() * 20
        let cluster = this.clusters.find(c => c.id === d.family.id)
        // let indexInGroup = cluster.ingredients.findIndex(i => i.id === d.id)
        // let midAngle = cluster.startAngle + cluster.arcSize / 2
        return {
          data: d,

          clusterId: cluster.id,

          // x: Math.sin(midAngle) * (50 + (1 - unitPopIndex) * 260) + this.size.width / 2,
          // y: Math.cos(midAngle) * (50 + (1 - unitPopIndex) * 260) + this.size.height / 2,
          r: r,
          unitPopIndex: unitPopIndex,
          // link_r: r + 10 + Math.random() * 20,
          id: d.id,
        }
      })
    },
    activeRelated() {
      if (!this.selectedRelated) return null
      let activeRelated = this.$asyncComputed.selectedRelated.updating
        ? []
        : [...this.selectedRelated]
      activeRelated.sort((a, b) => {
        let atheta = Math.atan2(a.y - this.size.height / 2, a.x - this.size.width / 2) + Math.PI
        let btheta = Math.atan2(b.y - this.size.height / 2, b.x - this.size.width / 2) + Math.PI
        return btheta - atheta
      })

      return activeRelated
    },
  },
  watch: {
    ingredients() {
      // this.selectedIngredient = this.ingredients[0]
      if (this.ingredients.length) {
        this.selectedIngredient = null
      }
      this.draw()
    },
    selectedRelated() {
      this.draw()
    },
    '$asyncComputed.selectedRelated.updating'() {
      this.draw()
    },
    selectedIngredient() {
      this.$emit('select-pair', null)
    },
    size() {
      this.draw()
    },
  },
  mounted() {},
  methods: {
    draw() {
      let canvas = this.$refs.canvas
      if (!canvas) return

      const context = canvas.getContext('2d')
      const simulation =
        canvas.simulation || d3_forceSimulation().velocityDecay(0.5).alphaDecay(0.005)
      canvas.simulation = simulation
      let activeLinks
      this.ingredients.forEach(d => {
        d.linked = false
        d.forcedX = null
        d.forcedY = null
        // d.sortOrder = null
      })
      const SELECTION_RADIUS = 160
      if (this.selectedIngredient) {
        this.selectedIngredient.forcedX = this.size.width / 2
        this.selectedIngredient.forcedY = this.size.height / 2
      }
      if (this.selectedIngredient && this.activeRelated) {
        let dtheta = (2 * Math.PI) / this.activeRelated.length
        activeLinks = this.activeRelated.map((t, i) => {
          t.linked = true
          t.forcedX = this.size.width / 2 + SELECTION_RADIUS * Math.sin(i * dtheta)
          t.forcedY = this.size.height / 2 + SELECTION_RADIUS * Math.cos(i * dtheta)
          return {
            source: this.selectedIngredient,
            target: t,
          }
        })
      } else {
        activeLinks = []
      }

      this.ingredients.forEach(d => {
        if (!d.x || !d.y) {
          let g = this.clusters.find(c => c.id === d.clusterId)
          let midAngle = g.startAngle + g.arcSize / 2
          d.x = Math.sin(midAngle) * (50 + (1 - d.unitPopIndex) * 260) + this.size.width / 2
          d.y = Math.cos(midAngle) * (50 + (1 - d.unitPopIndex) * 260) + this.size.height / 2
        }
      })
      let self = this
      simulation.nodes(this.ingredients)
      simulation
        // .force(
        //   'charge',
        //   d3_forceManyBody().strength(d => {
        //     if (d.linked) return -200
        //     else return -10
        //   })
        // )
        .force(
          'collide',
          forceCollideOptional()
            .radius(d => {
              if (d === this.selectedIngredient) return SELECTION_RADIUS * 1.5
              else if (d.linked) return d.link_r * 1
              else return d.r + 10
              // return d.r * 1.2
            })
            .strength((a, b) => {
              if (
                (a.linked && b === this.selectedIngredient) ||
                (b.linked && a === this.selectedIngredient)
              )
                return 0
              else return 0.8
              // return 0
            })
          // .strength((a, b) => {
          //   if (
          //     a.linked ||
          //     b === this.selectedIngredient ||
          //     b.linked ||
          //     a === this.selectedIngredient
          //   )
          //     return 0
          //   else return 0.6
          //   // return 0
          // })
        )
        // .force(
        //   'radial',
        //   d3_forceRadial()
        //     .x(this.size.width / 2)
        //     .y(this.size.height / 2)
        //     .radius(SELECTION_RADIUS * 1.5)
        //     .strength(d => {
        //       if (!this.selectedIngredient || d === this.selectedIngredient || d.linked) return 0
        //       else return 0.01
        //     })
        // )
        .force(
          'forceX',
          d3_forceX()
            .x(d => d.forcedX || this.size.width / 2)
            .strength(d => (d.forcedX !== null ? 0.7 : 0))
        )
        .force(
          'forceY',
          d3_forceY()
            .y(d => d.forcedY || this.size.height / 2)
            .strength(d => (d.forcedY !== null ? 0.7 : 0))
        )
        .force(
          'cluster',
          forceCluster()
            .centers(function (d) {
              let g = self.clusters.find(c => c.id === d.clusterId)
              let midAngle = g.startAngle + g.arcSize / 2
              return {
                x:
                  Math.sin(midAngle) * (50 + (1 - d.unitPopIndex) * self.size.width * 0.2) +
                  self.size.width / 2,
                y:
                  Math.cos(midAngle) * (50 + (1 - d.unitPopIndex) * self.size.height * 0.2) +
                  self.size.height / 2,
              }
            })
            .strength(d => (d.forcedX !== null ? 0 : 2))
            .centerInertia(1)
        )
      // .force(
      //   'link',
      //   d3_forceLink(activeLinks)
      //     .distance(() => SELECTION_RADIUS)
      //     .strength(0.1)
      //     .iterations(10)
      // )
      let renderTooltip = () => {
        let tooltipTarget = this.hoveredIngredient
        if (tooltipTarget) {
          this.tooltipData = {
            label: tooltipTarget.data.name,
            style: { left: tooltipTarget.x + 'px', top: tooltipTarget.y + 'px' },
            showButton: this.hoveredIngredient.linked,
          }
        } else {
          this.tooltipData = null
        }
      }
      let renderLinks = () => {
        activeLinks.forEach(link => {
          let c1 = link.source
          let c2 = link.target
          c2 = { ...c2, r: c2.link_r }
          let tangents = common_tangent_line(c1.x, c1.y, c1.r, c2.x, c2.y, c2.r)
          let center = {
            x: c1.x + (c2.x - c1.x) * (c1.r / (c1.r + c2.r)),
            y: c1.y + (c2.y - c1.y) * (c1.r / (c1.r + c2.r)),
          }
          let path = []
          path.push('M', tangents[0][0][0], tangents[0][0][1])
          path.push(
            'C',
            center.x,
            center.y,
            center.x,
            center.y,
            tangents[1][1][0],
            tangents[1][1][1]
          )
          path.push('A', c2.r, c2.r, 0, 1, 1, tangents[0][1][0], tangents[0][1][1])
          path.push(
            'C',
            center.x,
            center.y,
            center.x,
            center.y,
            tangents[1][0][0],
            tangents[1][0][1]
          )
          path.push('A', c1.r, c1.r, 0, 1, 1, tangents[0][0][0], tangents[0][0][1])
          context.beginPath()
          context.fillStyle = link.target.data.family.color
          // context.globalAlpha =
          //   this.hoveredIngredient === link.target ||
          //   (this.selectedPair && this.selectedPair[0] === link.target)
          //     ? 1
          //     : 0.3
          if (this.hoveredIngredient === link.target) context.globalAlpha = 1
          else if (this.selectedPair && this.selectedPair[1].id === link.target.id)
            context.globalAlpha = 1
          else context.globalAlpha = 0.3

          context.fill(new Path2D(path.join(' ')))

          if (this.selectedPair && this.selectedPair[1].id === link.target.id) {
            context.strokeStyle = '#000'
            context.lineWidth = 5
            context.stroke(new Path2D(path.join(' ')))
          }
          context.globalAlpha = 1
          // context.stroke(new Path2D(path.join(' ')))
        })
      }
      let renderIngredients = () => {
        simulation.nodes().forEach(node => {
          if (!this.selectedIngredient || node.linked || node === this.selectedIngredient)
            context.globalAlpha = 1
          else context.globalAlpha = 0.3
          let paddedR = node.r
          context.beginPath()
          context.moveTo(node.x + paddedR, node.y)
          context.arc(node.x, node.y, paddedR, 0, 2 * Math.PI)
          context.strokeStyle = node.data.family.color
          // context.fillStyle = '#fff'
          context.lineWidth = 1
          context.stroke()
          // context.fill()
          paddedR = node.r - 5
          let image = this.$refs.images.find(el => +el.id === +node.id)
          if (image) {
            context.save()
            context.beginPath()
            context.moveTo(node.x + paddedR, node.y)
            context.arc(node.x, node.y, paddedR, 0, 2 * Math.PI)
            context.clip()
            context.drawImage(image, node.x - paddedR, node.y - paddedR, paddedR * 2, paddedR * 2)
            context.restore()
            // if (node.sortOrder) {
            //   context.font = '48px serif'
            //   context.fillText(node.sortOrder, node.x, node.y)
            // }
          }
        })
      }
      let render = function () {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)
        renderLinks()
        renderIngredients()
        renderTooltip()
      }
      simulation.on('tick', render)
      simulation.alpha(0.2).restart()
      let qt = d3_quadtree()
        .extent([
          [0, 0],
          [this.size.width, this.size.height],
        ])
        .x(d => d.x)
        .y(d => d.y)
      ;(this.$refs.canvas.onclick = e => {
        qt.addAll(this.ingredients)
        // console.log('e', e)
        // qt.addAll(this.ingredients)
        let coords = d3_pointer(e)
        // console.log(coords)
        let node = qt.find(coords[0], coords[1], 150)
        if (node && distance(node, { x: coords[0], y: coords[1] }) > node.r) node = null
        if (node && node !== this.selectedIngredient) {
          this.selectedIngredient = node
          // this.draw()
        } else {
          this.selectedIngredient = null
          // this.draw()
        }
        simulation.restart()
      }),
        (this.$refs.canvas.onmousemove = e => {
          qt.addAll(this.ingredients)
          // console.log('e', e)
          // qt.addAll(this.ingredients)
          let coords = d3_pointer(e)
          // console.log(coords)
          let node = qt.find(coords[0], coords[1], 150)
          if (node && distance(node, { x: coords[0], y: coords[1] }) > node.r) node = null
          if (this.hoveredIngredient !== node) simulation.restart()
          if (node) {
            this.$refs.canvas.style.cursor = 'pointer'
            this.hoveredIngredient = node
          } else {
            this.$refs.canvas.style.cursor = 'inherit'
            this.hoveredIngredient = null
          }
        })
    },
    onDetailsClick() {
      this.$emit('select-pair', [this.selectedIngredient.data, this.hoveredIngredient.data])
      this.$refs.canvas.simulation.restart()
    },
  },
}
</script>

<style lang="stylus" scoped>
.ingredients-cloud
  position: relative
  flex: 1 1 0%
  overflow: hidden

  .tooltip-wrapper
    position: absolute

    &.has-button
      &:before
        position: absolute
        width: vw(100px)
        height: vw(100px)
        border-radius: 50%
        background-color: #000
        content: ""
        opacity: 0
        transform: translate(-50%, -50%)

    .tooltip
      m-font-size(18, 24)
      position: absolute
      padding: 5px
      box-shadow: none
      white-space: nowrap
      transition: none
      transform: translate(-50%, -200%)

    .details-btn
      m-font-size(16)
      position: absolute
      padding: 5px
      background-color: #000
      color: #fff
      white-space: nowrap
      cursor: pointer
      transition: none
      transform: translate(-50%, 150%)
</style>
<template>
  <div v-if="filters && sensors" class="funnel-panel">
    <div class="funnel-header">
      <Filters v-model="filters" :global="false" />
    </div>
    <div v-if="funnelData && funnelData.length > 0" class="funnel-main">
      <div v-if="filters.sensor && funnelNodeTrees" class="funnel-content">
        <FunnelTable
          v-if="dataReady"
          :filters="filters"
          :funnel-data="funnelData"
          :funnel-node-tree="currentFunnelTree"
          :sensors="sensors"
        />
        <Loader v-if="!$asyncComputed.funnelData.success || $asyncComputed.funnelData.updating" />
      </div>
    </div>
    <div v-else>
      {{ !currentFunnelTree ? $t('funnel_no_funnel_selected') : $asyncComputed.funnelData.updating ? $t('funnel_data_loading') : $t('funnel_no_data') }}
    </div>
  </div>
</template>
<script>
import Filters from '../Filters.vue'
import _ from 'lodash'
import FunnelTable from './FunnelTable.vue'
import { getFunnelData, getFunnelNodeTrees, getSensors, getCountries } from '@/services/api'
import Loader from '../Loader.vue'
export default {
  components: { Filters, Loader, FunnelTable },
  props: {
    startingFilters: { default: null },
    id: { type: String, required: true },
  },
  data() {
    return {
      minDate: null,
      filters: null,
    }
  },
  asyncComputed: {
    funnelData() {
      return this.funnelNodeTrees && this.filters.sensor && getFunnelData(this.funnelRequestFilters)
    },
    funnelNodeTrees() {
      return getFunnelNodeTrees()
    },
    sensors() {
      return getSensors()
    },
    countries() {
      return getCountries()
    },
  },
  computed: {
    selectedSensors(){
      return this.sensors?.filter(sensor => this.filters?.sensor?.includes(sensor.id)) ?? []
    },
    dataReady() {
      return !!this.funnelData && !!this.funnelNodeTrees
    },
    funnelTreesSensors() {
      return this.funnelNodeTrees && this.funnelNodeTrees.map(tree => tree.sensors).flat()
    },
    currentFunnelTree() {
      return (
        this.funnelNodeTrees &&
        this.filters.sensor &&
        this.funnelNodeTrees.find(tree =>
          this.filters.sensor.some(sensor => tree.sensors.includes(sensor))
        )
      )
    },
    currentFunnelTreeDefaultDateRange() {
      if (!this.sensors) {
        const from = new Date(this.currentFunnelTree.from)
        const to = new Date(from.getTime())
        return {
          from,
          to: to.setDate(to.getDate() + 1),
        }
      }
      return this.sensors
        .filter(s => this.currentFunnelTree.sensors.includes(s.id))
        .reduce(
          (acc, s) => {
            if (acc.to < s.to) {
              acc.to = s.to
            }
            return acc
          },
          {
            from: new Date(this.currentFunnelTree.from),
            to: new Date(this.currentFunnelTree.from),
          }
        )
    },
    comparing() {
      return this.$route.query.compare !== undefined
    },
    funnelRequestFilters() {
      const { sensor, ...rest } = this.filters
      return { ...rest, sensors: sensor, id: this.currentFunnelTree.id }
    },
  },
  watch: {
    funnelTreesSensors(sensors) {
      if (sensors && sensors.length > 0) {
        this.setDefaultFilters(sensors)
      }
    },
    filters() {
      this.$emit('input', this.filters)
    },
    currentFunnelTree(val, prev) {
      if (!val) return
      if (val.id !== (prev ? prev.id : null) && this.currentFunnelTree.from) {
        const defaultFrom = this.currentFunnelTreeDefaultDateRange.from
        const nextFrom = new Date(val.from).getTime()
        const defaultTo = this.currentFunnelTreeDefaultDateRange.to
        if (this.filters.from < nextFrom) {
          this.filters.from = defaultFrom
        }
        if (this.filters.to < nextFrom) {
          this.filters.to = defaultTo
        }
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('setTopFilters', this.filters)
  },
  methods: {
    setDefaultFilters(sensors) {
      let sensor
      if (this.startingFilters && this.startingFilters.sensor) {
        this.filters = { ...this.startingFilters }
        return
      }
      const globalFilters = this.$store.getters.topFilters
      const sensorsIntersection = _.intersection(globalFilters.sensor, sensors)
      if (sensorsIntersection.length > 0) {
        sensor = sensorsIntersection.reduce((acc, curr) => {
          if (acc.length === 0) {
            return [curr]
          }
          const currTree = this.funnelNodeTrees.find(tree => tree.sensors.includes(curr))
          if (currTree.sensors.includes(acc[0])) {
            return [...acc, curr]
          }
          return acc
        }, [])
      } else {
        sensor = sensors.includes(9) ? [9] : this.funnelNodeTrees[0].sensors
      }
      this.filters = { ...globalFilters, sensor }
    },
  },
}
</script>

<style lang="stylus" scoped>
.funnel-panel
  overflow: hidden

.funnel-header
  $gap = vw(15px)
  margin-bottom: vw(40px)

  &:empty
    display: none

  position: sticky
  top: 0
  z-index: 1
  padding-top: vw(30px)
  padding-bottom: vw(30px)
  border-bottom: 1px solid #f2f2f2
  background-color: #fff

  >>> .header-options__filters
    gap: $gap

  >>> .header-options__filters__filter
    flex: 1 1 "calc(50% - %s)" % $gap

  >>> .header-options__filters__filter__title
    text-align: right

  >>> .multiselect-container
    flex: 1 1 0%

  >>> .multiselect
    flex: 1 1 0%

  >>> .date-range-picker
    flex: 1 1 0%

.funnel-main
  margin-right: auto
  margin-left: auto
  max-width: 1280px

button
  padding: 5px
  border: 1px solid black
  opacity: 0.5
  cursor: pointer
  all: unset

  &.selected
    opacity: 1
</style>
<template>
  <div class="percentage-container">
    <div v-if="value !== Infinity && value !== -Infinity" class="texts">
      <div class="percentage" :class=" value ? value > 0 ? 'percentage--positive' : 'percentage--negative': ''"> {{ number }} </div>
      <span class="percentage">
        {{ label }}
      </span>
    </div>
    <div v-else class="texts">
      <div class="percentage" :class=" value ? value === Infinity ? 'percentage--positive' : 'percentage--negative': ''"> {{ Infinity }} </div>
      <span class="percentage">
        {{ label }}
      </span>
    </div>
    <div v-if="$listeners.evol" class="evol-button" @click="$emit('evol')" />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    label: String,
  },
  computed: {
    number() {
      return this.value === 0 ? '0%' : this.$prettyPercent(this.value * 100)
    },
  },
}
</script>
<style lang="stylus" scoped>
.percentage-container
  display: flex
  // align-items: center
  gap: 0.4em

.texts
  display: flex
  gap: 0.4em

.percentage
  // m-font-size(10, 14)
  white-space: nowrap

.percentage--positive
  color: $shamrock

.percentage--negative
  color: $flush-mahogany

.evol-button
  m-icon("evol", 20)
  flex: 0 0 vw(20px)
  margin-left: auto
  cursor: pointer
</style>
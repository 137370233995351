<template>
  <modal
    name="evolutive"
    :width="'80%'"
    :height="'80%'"
    @before-open="load"
  >
    <evolutive-modal-content v-bind="componentProps" />
  </modal>
</template>

<script>
import EvolutiveModalContent from './EvolutiveModalContent.vue'
export default {
  components: { EvolutiveModalContent },
  data() {
    return {
      componentProps: {},
    }
  },
  methods: {
    load(e) {
      // console.log(args.params)
      this.componentProps = e.params
    },
  },
}
</script>

<style lang="stylus" scoped>
.evolutive-modal-content
  display: flex
  width: 100%
  height: 100%
</style>
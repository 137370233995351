<template>
  <InsightDisplay
    :insight="data"
    :compact="compact"
    :dark-background="darkBackground"
    :evolutive-kpis="evolutiveKpis"
    :display-kpi="displayKpi"
  >
    <template v-slot:thumbnail>
      <img class="thumbnail" :src="data.imageUrl">
    </template>
    <template v-slot:description>
      <FragranceDescription
        :data="data"
        :compact="compact"
      />
    </template>
  </InsightDisplay>
</template>
<script>
import InsightDisplay from '../InsightDisplay'
import FragranceDescription from './FragranceDescription.vue'
export default {
  components: { InsightDisplay, FragranceDescription },
  props: ['data', 'darkBackground', 'evolutiveKpis', 'displayKpi', 'compact'],
}
</script>
<style lang="stylus" scoped></style>
export default {
  methods: {
    $prettyNumber(value) {
      //si te decimals els posa i sino no
      return Math.round(value * 100) / 100
    },
    $prettyPercent(value) {
      //   if (value === 0) return '0%'
      return value ? `${value < 0 ? '-' : '+'} ${Math.abs(parseFloat(value).toFixed(2))}%` : ''
    },
    $prettyDuration(value) {
      value = parseInt(value)
      let seconds = value % 60
      let minutes = parseInt(value / 60) % 60
      let hours = parseInt(parseInt(value / 60) / 60)
      if (seconds < 10) seconds = '0' + seconds
      if (minutes < 10) minutes = '0' + minutes
      if (hours < 10) hours = '0' + hours
      // return `${hours}:${minutes}:${seconds}`
      return `${minutes}:${seconds}`
    },
  },
}

<template>
  <div>
    <FunnelCards
      v-if="funnelHierarchy && pathNodes"
      :funnel-hierarchy="funnelHierarchy"
      :branch-matching-nodes="branchMatchingNodes"
      :funnel-node-tree="funnelNodeTree"
      :table-values="values"
      :funnel-data="funnelData"
      :filters="filters"
      :is-instore="isInstore"
      :success-rate="successRate"
    />
    <div v-if="dataReady" class="funnel-table-container" :class="{'deep': deepMode}">
      <span v-if="selectedChoices && selectedBranches && pathNodes">
        <transition-group name="row" tag="table" class="funnel-table">
          <thead key="header">
            <tr>
              <th />
              <th v-for="(header, i) in headers" :key="i">{{ header }}</th>
            </tr>
          </thead>
          <tbody
            v-for="(step,i) in steps"
            :key="step.id"
            :step-id="step.id"
            class="row-item"
          >
            <tr :class="{'has-deep-buttons': step.choices && deepMode, highlight: step.highlighted}">
              <td>
                <span class="step-title">{{ $t('funnel_step').replace('%', i+1) }}:</span><b>{{ step.label }}</b>
                <div v-if="step.choices && deepMode" class="buttons-container">
                  <button
                    v-for="(choice,j) in step.choices"
                    :key="j"
                    class="btn"
                    :class="selectedChoices[choice.key] === choice.value ? 'selected btn--primary' : 'btn--secondary'"
                    @click="handleChoice(choice)"
                  >
                    {{ $t(choice.label) }}
                  </button>
                </div>
              </td>
              <td v-for="(value,j) in values[i]" :key="j">{{ parseValue(value !== null ? Number(value) : null, j) }}</td>
            </tr>
            <tr v-if="step.branches" class="tr-buttons">
              <div class="branch-buttons">
                <button
                  v-for="(branch,j) in step.branches"
                  :key="j"
                  class="btn branch-button"
                  :class="selectedBranches[step.level] === branch.id ? 'selected btn--primary' : 'btn--secondary'"
                  @click="handleBranch(branch.id, step.level)"
                >
                  {{ $t(branch.label) }}
                </button>
              </div>
            </tr>
          </tbody>
        </transition-group>

      </span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { hierarchy as d3_hierarchy } from 'd3-hierarchy'
// import { getFakeFunnelData, getFunnelNodeTreesLocal } from '@/services/api'
import FunnelCards from './FunnelCards.vue'
import { getColumns,FunnelTableColumns, findIndexField, getFieldFromIndex} from '../../settings/funnelSettings'
export default {
  components: { FunnelCards },
  props: {
    global: {
      default: true,
    },
    funnelData: {
      type: Array,
      required: true,
    },
    funnelNodeTree: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    sensors:{
      type:Array,
      required:true
    }
  },
  data() {
    return {
      selectedChoices: {},
      selectedBranches: {},
    }
  },
  // asyncComputed: {
  //   async funnelData() {
  //     return getFakeFunnelData()
  //   },
  //   async funnelNodeTrees() {
  //     return getFunnelNodeTreesLocal()
  //   },
  // },
  computed: {
    /**
     * Task #294: If any sensor on the funnel has technology AIR_PARFUM
     * @see https://code.bestiario.org/devel/puig/DoD/-/issues/294
     * (SI ALMENOS EXISTE UN AIRPARFUM EN EL MISMO FUNNEL (encara q no estigui seleccionat))
     */
    isInstore(){
      const funnelsIds = this.selectedSensors.map(sensor => sensor.funnelId).filter((id,index,array)=>index === array.indexOf(id))
      const sensorWithSameFunnelId = this.sensors.filter(sensor => funnelsIds.includes(sensor.funnelId))
      const someAirParfum = sensorWithSameFunnelId.some(sensor => sensor.type === 'AIR_PARFUM' || sensor.type === 'INSTORE_PROFILER' )
      console.debug('some sensor with type Airparfum = ',someAirParfum )
      return someAirParfum
    },
    selectedSensors(){
      return this.sensors?.filter(sensor => this.filters?.sensor?.includes(sensor.id)) ?? []
    },
    dataReady() {
      return !!this.funnelData && !!this.funnelNodeTree
    },
    // funnelNodeTree() {
    //   return this.funnelNodeTrees && this.funnelNodeTrees[0]
    // },
    headers() {
      return getColumns({
        [FunnelTableColumns.Sessions]: this.$t('funnel_sessions'),
        [FunnelTableColumns.Users]: this.$t('funnel_unique_users'),
        [FunnelTableColumns.Arrival]: this.$t('funnel_arrival'),
        [FunnelTableColumns.Lost]: this.$t('funnel_lost'),
        [FunnelTableColumns.SessionDuration]: this.$t('funnel_average_time')
      }, { isInstore: this.isInstore })
    },
    funnelHierarchy() {
      return this.funnelNodeTree && d3_hierarchy(this.funnelNodeTree.rootStep)
    },
    steps() {
      return (
        this.pathNodes &&
        this.pathNodes.map(step => {
          const choices = step.data.multipleChoice?.choices || []
          return {
            label:
              !this.deepMode && step.data.nonDeepModeLabel
                ? this.$t(step.data.nonDeepModeLabel)
                : this.$t(step.data.label),
            choices: choices.length > 1 ? choices : null,
            branches: step.data.branching,
            highlighted: step.data.highlighted,
            level: step.data.id,
            id: step.data.id,
          }
        })
      )
    },
    comparing() {
      return this.$route.query.compare !== undefined
    },
    deepMode() {
      return this.$route.query.deep !== undefined
    },
    branchMatchingNodes() {
      return (
        this.funnelHierarchy && this.funnelHierarchy.descendants().filter(this.nodeMatchesBranches)
      )
    },
    nodesByLevel() {
      return this.branchMatchingNodes.reduce((acc, node) => {
        acc[node.data.level] = [...(acc[node.data.level] || []), node]
        return acc
      }, {})
    },
    leaves() {
      return this.funnelHierarchy?.leaves()
    },
    endLeaf() {
      if (!this.leaves) return null
      if (this.leaves.length === 1) return this.leaves[0]
      return this.leaves.find(this.nodeMatchesChoices)
    },
    pathNodes() {
      return this.endLeaf && this.funnelHierarchy && this.funnelHierarchy.path(this.endLeaf)
    },
    emptyNodeData() {
      return {
        avgTime: null,
        sessions: 0,
        users: 0,
      }
    },
    deepValues() {
      return this.pathNodes
        .map(n => {
          return n.data.id
        })
        .map(id => {
          const data = this.funnelData.find(d => d.id === id) || { ...this.emptyNodeData, id }
          return data
        })
        .map(data => {
          if (!data) {
            return [null, null, null, null, null]
          }
          return this.getDeepComputedValues(data)
        })
    },
    nonDeepValues() {
      const values = []
      Object.keys(this.nodesByLevel).forEach(level => {
        values[level - 1] = this.getNonDeepComputedValues(
          this.combineLevelNodesData(this.nodesByLevel[level]),
          level
        )
      })
      return values
    },
    rootData() {
      return this.funnelData.find(d => d.id === this.funnelHierarchy.data.id)
    },
    values() {
      return this.deepMode ? this.deepValues : this.nonDeepValues
    },
    successRate() {
      var index = findIndexField(FunnelTableColumns.Arrival,{isInstore: this.isInstore})
      const successRate = Number(this.values[this.values.length - 1][index])
      console.debug('successRate =',successRate)
      return successRate
    },
  },
  mounted() {
    this.setDefaultChoices()
  },
  methods: {
    setDefaultChoices() {
      const { conditions, branches } = this.leaves[0].data
      conditions?.forEach(({ key, value, isChoice }) => {
        if (isChoice) {
          Vue.set(this.selectedChoices, key, value)
        }
      })
      branches?.forEach(({ id, level }) => {
        Vue.set(this.selectedBranches, level, id)
      })
    },
    combineLevelNodesData(levelNodes) {
      return levelNodes
        .map(({ data }) => {
          const nodeData = this.funnelData.find(d => d.id === data.id) || {
            ...this.emptyNodeData,
            id: data.id,
          }

          return nodeData
        })
        .reduce(
          (acc, curr, ri, ra) => {
            const out = { ...acc }
            for (const key in curr) {
              const value = curr[key]
              switch (key) {
                case 'avgTime':
                  if (ri === ra.length - 1) {
                    const nullLength = ra.filter(d => d.avgTime === null).length
                    if (nullLength === ra.length) {
                      out[key] = null
                    } else {
                      out.avgTime = (out.avgTime + (value || 0)) / (ra.length - nullLength)
                    }
                  } else {
                    out.avgTime = out.avgTime + (value || 0)
                  }
                  break
                case 'id':
                  out.ids = [...out.ids, value]
                  break
                default:
                  out[key] = out[key] + value
                  break
              }
            }
            return out
          },
          { ids: [], avgTime: 0, users: 0, sessions: 0 }
        )
    },
    getDeepComputedValues(data) {
      // Task #294  If any sensor on the funnel has technology AIR_PARFUM,%ARRIVAL AND %LOST with respect to # Sessions
      const field = this.isInstore ? "sessions" : "users"
      const value = data[field]
      const arrival = Number(this.getNodeArrivalValue(value,field))
      const parent = this.funnelHierarchy.find(n => n.data.id === data.id).parent
      let lost = 0
      if (parent) {
        let parentData = this.funnelData.find(d => d.id === parent.data.id)
        if (!parentData) {
          parentData = { sessions: 0, avgTime: 0, users: 0, id: parent.data.id }
        }
        const parentValue = this.isInstore ? parentData.sessions : parentData.users
        lost = 100 - (value * 100) / parentValue
      }
      return getColumns({
        [FunnelTableColumns.Sessions]:data.sessions,
        [FunnelTableColumns.Users]:data.users,
        [FunnelTableColumns.Arrival]: arrival ? arrival.toFixed(1) : 0,
        [FunnelTableColumns.Lost]: lost ? lost.toFixed(1) : 0,
        [FunnelTableColumns.SessionDuration]: data.avgTime
      }, { isInstore: this.isInstore })
    },
    getNonDeepComputedValues(data, level) {
      // Task #294  If any sensor on the funnel has technology AIR_PARFUM,%ARRIVAL AND %LOST with respect to # Sessions
      const field = this.isInstore ? "sessions" : "users"
      const value = data[field]
      const arrival = this.getNodeArrivalValue(value,field)
      let lost = 0
      if (level > 1) {
        const prevLevelData = this.combineLevelNodesData(this.nodesByLevel[level - 1])
        const prevValue = this.isInstore ? prevLevelData.sessions : prevLevelData.users
        lost = 100 - (value * 100) / prevValue
      }
      return getColumns({
        [FunnelTableColumns.Sessions]:data.sessions,
        [FunnelTableColumns.Users]:data.users,
        [FunnelTableColumns.Arrival]: arrival ? arrival.toFixed(1) : 0,
        [FunnelTableColumns.Lost]: lost ? lost.toFixed(1) : 0,
        [FunnelTableColumns.SessionDuration]: data.avgTime
      }, { isInstore: this.isInstore })
    },
    getNodeArrivalValue(users, rootField = 'users') {
      return this.rootData ? (100 * users) / this.rootData[rootField] : null
    },
    handleChoice({ key, value }) {
      this.selectedChoices[key] = value
    },
    handleBranch(id, level) {
      this.selectedBranches[level] = id
    },
    parseValue(value, index) {
      if(!value) return '-'
      switch(getFieldFromIndex(index,{isInstore:this.isInstore})){
        case FunnelTableColumns.Arrival:
        case FunnelTableColumns.Lost:
          return `${value.toFixed(2)}%`
        case FunnelTableColumns.SessionDuration:
          return `${value.toFixed(2)}s`
        default:
          return value
      }
    },
    nodeMatchesChoices(node) {
      return this.nodeMatchesConditions(node) && this.nodeMatchesBranches(node)
    },
    nodeMatchesBranches(node) {
      if (!node.data.branches) {
        return true
      }
      return node.data.branches.every(branch => this.selectedBranches[branch.level] === branch.id)
    },
    nodeMatchesConditions(node) {
      return node.data.conditions.every(
        condition => this.selectedChoices[condition.key] === condition.value || !condition.isChoice
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.funnel-table-container
  overflow-x: hidden

.funnel-table
  border-collapse: separate
  border-spacing: 0 vw(10px)
  position: relative
  width: 100%

  .row-move,
  .row-enter-active,
  .row-leave-active
    transition: all 0.5s ease

  .row-enter-active
    transition-delay: 0.5s

  .row-enter-ac,
  .row-enter,
  .row-leave-to
    opacity: 0

  .row-leave-to
    transform: translateX(50px)

  .row-enter
    transform: translateX(-50px)

  /* ensure leaving items are taken out of layout flow so that moving
  animations can be calculated correctly. */
  th
    m-font-size(15, 20)
    font-weight: 300
    padding: vw(5px)

    &:first-child
      width: vw(300px)

  tr
    border: 1px solid #000

    &.highlight
      background-color: rgba(111, 207, 151, 0.2)

    &.has-deep-buttons
      .deep &
        vertical-align: top

        td
          &:nth-child(2)
            m-font-size(18, 40)

  td
    m-font-size(20, 40)
    border-bottom: 1px solid
    border-top: 1px solid
    padding: vw(5px)
    text-align: center
    white-space: nowrap

    .comparing &
      m-font-size(16, 40)

    &:first-child
      border-left: 1px solid #000

    &:last-child
      border-right: 1px solid #000

    &:nth-child(1)
      m-font-size(14, 40)
      text-align: left

      > b
        m-font-size(16, 40)

        .comparing &
          m-font-size(16, 22)

  .tr-buttons
    > td
      border: none

  .step-title
    margin-left: vw(5px)
    margin-right: vw(5px)
    text-transform: uppercase

  .buttons-container
    align-items: center
    display: flex
    width: 0

    > .btn
      m-font-size(14, 40)
      height: $multiselect-height
      padding-bottom: 0
      padding-top: 0
      white-space: nowrap

.branch-buttons
  align-items: center
  display: flex

.branch-button
  white-space: nowrap
</style>
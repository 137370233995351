<template>
  <div class="pop-details">
    <div class="pop-details__title">Date: {{ new Intl.DateTimeFormat('es-ES').format(date) }}</div>
    <div class="pop-index">
      <div class="pop-index__title">{{ translateKpi(displayKpi) }}: {{ $prettyNumber(kpi.value) }}</div>
      <Percentage :value="kpi.variation" label=" vs last day" />
    </div>
    <!-- <div v-if="displayKpi === 'vPopIndex'" class="pop-details__cards">
      <IndicatorCard
        v-for="indicator in others"
        :key="indicator.id"
        :type="indicator.id"
        :compact="true"
        :label="indicator.label"
        :value="indicator.value"
        :evolutive="indicator.evolutive"
        :variation="null"
        :minimized="true"
        :date="date"
      />
    </div> -->
  </div>
</template>

<script>
// import IndicatorCard from './IndicatorCard.vue'
import Percentage from './Percentage.vue'
import { translateKpi } from '../../mixins/IndicatorMixin'
export default {
  components: { Percentage },
  props: {
    date: Date,
    // popIndex: Number,
    data: Array,
    // searches: Array,
    // addedToProfile: Array,
    // recommended: Array,
    // interactions: Array,
    // variation: Number,
    anchorPosition: Object,
    displayKpi: String,
  },
  computed: {
    popIndex() {
      return this.data.find(d => d.id === 'vPopIndex')
    },
    kpi() {
      return this.data.find(d => d.id === this.displayKpi)
    },
    others() {
      return this.data.filter(d => d.id !== 'vPopIndex')
    },
  },
  // mounted() {
  //   console.log('FUCK: mounted pop dtails')
  // },
  methods: {
    translateKpi,
  },
}
</script>
<style lang="stylus" scoped>
.pop-details
  padding: vw(10px)
  width: vw(300px)
  height: auto

  &__cards
    display: flex
    flex-wrap: wrap

.pop-details__title
  m-font-size(12, 14)
  margin-bottom: vw(5px)
  font-weight: 400

.pop-index
  m-font-size(20, 24)
  margin-bottom: vw(20px)

.pop-index__title
  margin-bottom: vw(5px)

.pop-details__cards
  $margin = vw(10px)
  $margin-negative = vw(-10px)
  display: flex
  flex-wrap: wrap
  margin-left: $margin-negative

  .indicator-card
    flex: 0 0 "calc(50% - %s)" % $margin
    margin-left: $margin

    &:only-child
      flex: 0 0 "calc(100% - %s)" % $margin
      margin-right: $margin

    &.indicator-card--minimized
      &:not(:last-child)
        margin-bottom: vw(20px)

      &:last-child
        border-bottom: none

  >>> .indicator-card__header
    margin-bottom: 5px

  >>> .indicator-card__graph-min
    align-items: flex-end

    .graph
      flex: 2 1 0%
      margin-top: 0

    // .indicator-card__value
    // flex: 1 1 0%
    .percentage
      // flex: 1 1 0%
      margin-right: 0
      margin-left: 5px
      height: 28px
      font-weight: 500
</style>
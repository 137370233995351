<template>
  <div class="families-description" :class="{ 'families-description--compact': compact}">
    <div class="family">{{ data.family.name }}</div>
    <div class="subfamily">{{ data.subfamily.name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    compact: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.families-description
  font-family: "SangBleu"

  .family
    m-font-size(22, 30)

  .subfamily
    m-font-size(12, 14)

.families-description--compact
  font-family: "Lelo"

  .family
    m-font-size(12, 16)
    margin-bottom: vw(5px)
    text-transform: uppercase

  .subfamily
    m-font-size(12, 14)
</style>
<template>
  <div class="multi-graph-tooltip">
    <div class="left">
      <template v-if="data.entity.entityType==='FRAGRANCE'">
        <div class="image" :style="{backgroundImage: `url(${data.entity.imageUrl})`}" />
        <!-- <img :src="data.entity.imageUrl"> -->
        <FragranceDescription :data="data.entity" />
      </template>
      <template v-else>
        <!-- a rellenar... -->
        <div v-if="data.entity.entityType==='FAMILY'" class="family-container">
          <families-donut :data="data.entity" size="sxm special" />
          <div class="family-inner">
            <div class="family">{{ data.entity.family.name }}</div>
            <div class="subfamily">{{ data.entity.subfamily.name }}</div>
          </div>
        </div>
        <div v-if="data.entity.entityType==='INGREDIENT'" class="ingredient">
          <div class="image ingredient" :style="{backgroundImage: `url(${data.entity.imageUrl})`}" />
          <div class="titles">
            <div class="title">{{ data.entity.name }}</div>
            <div class="family-wrapper">
              <div class="name">{{ data.entity.family.name }}</div>  
              <families-donut :data="data.entity" size="sxm special-sm" />
            </div>
          </div>
        </div>
        <div v-else class="title">{{ data.entity.name }}</div>
      </template>
    </div>
    <div class="right">
      <PopularityIndexDetails :data="data.indicators" :date="data.date" :display-kpi="'vPopIndex'" />
    </div>
  </div>
</template>

<script>
import PopularityIndexDetails from './productInsights/PopularityIndexDetails.vue'
import FragranceDescription from './productInsights/InsightDisplay/FragranceDescription.vue'
import FamiliesDonut from './productInsights/InsightDisplay/FamiliesDonut.vue'
export default {
  components: { PopularityIndexDetails, FragranceDescription, FamiliesDonut },
  props: {
    data: Object,
  },
}
</script>

<style lang="stylus" scoped>
.multi-graph-tooltip
  display: flex
  padding: vw(15px)
  width: vw(350px)
  background-color: #fff

  .left
    display: flex
    flex: 1 1 0%
    flex-direction: column
    justify-content: center
    align-items: center
    padding-right: vw(10px)

    .family-container
      display: flex
      align-items: center

      .family-inner
        m-font-size(16, 20)
        margin-left: vw(10px)

        .family
          margin-bottom: vw(5px)
          text-transform: uppercase

    .ingredient
      display: flex
      justify-content: center
      align-items: center

      .image
        margin-right: vw(10px)
        margin-bottom: 0

    .titles
      display: flex
      flex-direction: column
      justify-content: center

    .title
      m-font-size(16, 20)
      text-transform: uppercase

    >>> .families-donut
      &.special
        flex: 0 0 vw(80px)
        width: vw(80px)
        height: vw(80px)

        .family
          flex: 0 0 vw(80px)
          width: vw(80px)
          height: vw(80px)

        .subfamily
          flex: 0 0 vw(40px)
          width: vw(40px)
          height: vw(40px)

      &.special-sm
        flex: 0 0 vw(20px)
        margin-right: vw(5px)
        width: vw(20)
        height: vw(20px)

        .family
          flex: 0 0 vw(20px)
          width: vw(20)
          height: vw(20px)

        .subfamily
          flex: 0 0 vw(10px)
          width: vw(10px)
          height: vw(10px)

    .family-wrapper
      display: flex
      flex-direction: row-reverse
      align-items: center

  .right
    flex: 1 1 0%
    padding-left: vw(10px)

  .image
    flex: 0 0 vw(60px)
    align-self: center
    margin-bottom: vw(10px)
    width: vw(60px)
    height: vw(60px)
    background-position: center center
    background-size: contain
    background-repeat: no-repeat

    &.ingredient
      border-radius: 100%

  >>> .fragrance-description
    .fragrance-description__name
      m-font-size(14, 20)
      height: vw(40px)

    .fragrance-description__donut
      flex: 0 0 vw(18px)
      width: vw(18px)
      height: vw(18px)

  .pop-details
    padding: 0
    width: auto

  >>> .pop-index
    margin-bottom: 0

  >>> .pop-details__cards
    $margin = vw(20px)
    $margin-negative = vw(-20px)
    display: none
    margin-right: $margin-negative

    .indicator-card
      flex: 0 0 "calc(50% - %s)" % $margin
      margin-right: $margin
      margin-left: 0
</style>
<template>
  <div class="ingredient-pair-panel">
    <div class="close" @click="$emit('close')">&times;</div>
    <div class="title">{{ $t('selected_ingredients') }}</div>
    <InsightDisplay
      v-for="ing in pair"
      :key="ing.id"
      :insight="{...ing,entityType:'INGREDIENT'}"
      :compact="true"
    >
      <!-- <template v-slot:thumbnail>
        <img class="img-circle" :src="ing.imageUrl">
      </template> -->
      <template v-slot:description>
        <div>
          <div>
            {{ ing.name }}
          </div>
          <div class="ing-family">
            <div>
              <FamiliesDonut :data="ing" size="xsm" />
            </div>
            <span>{{ ing.family.name }}</span>
          </div>
        </div>
      </template>
    </InsightDisplay>
    <div class="combos-title title">{{ $t('ing_combos_title') }}</div>
    <div class="combinations-wrapper">
      <div v-if="combinationPerfumes && $asyncComputed.combinationPerfumes.success" class="combinations">
        <div
          v-for="(perf,i) in combinationPerfumes"
          :key="perf.id"
          class="combo-item"
        >
          <span class="number">{{ i+1 }}.</span>
          <InsightDisplay
            :insight="{...perf,entityType:'FRAGRANCE'}"
            :compact="true"
          >
            <template v-slot:thumbnail>
              <img class="thumbnail" :src="perf.imageUrl" @click="()=>openWikiparfum(perf)">
            </template>
            <template v-slot:description>
              <FragranceDescription
                :data="perf"
                :compact="true"
              />
            </template>
          </InsightDisplay>
        </div>
      </div>
      <Loader v-else />
    </div>
  </div>
</template>

<script>
import InsightDisplay from './productInsights/InsightDisplay'
import FamiliesDonut from './productInsights/InsightDisplay/FamiliesDonut.vue'
import { getIngredientCombinationPerfumes } from '@/services/api'
import { transformToPreviousPeriod } from '@/utils/filterUtils'

import { mapGetters } from 'vuex'
import FragranceDescription from './productInsights/InsightDisplay/FragranceDescription.vue'
import Loader from './Loader.vue'
export default {
  components: { InsightDisplay, FamiliesDonut, FragranceDescription, Loader },
  props: {
    pair: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters(['topFilters']),
    previousFilters() {
      return transformToPreviousPeriod(this.topFilters)
    },
  },
  asyncComputed: {
    combinationPerfumes() {
      return (
        this.topFilters &&
        getIngredientCombinationPerfumes(this.pair, this.topFilters, this.previousFilters)
      )
    },
  },
  methods: {
    openWikiparfum(perfume) {
      window.open(
        `https://wikiparfum.bestiario.org/explore/by-name?query=&selection=${perfume.id}`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.ingredient-pair-panel
  display: flex
  flex-direction: column
  padding: vw(20px)
  padding-top: vw(40px)
  width: vw(400px)
  background-color: #fff

  .close
    m-font-size(30, 30)
    position: absolute
    top: 0
    right: vw(10px)
    font-weight: 300
    cursor: pointer

  .title
    m-font-size(14, 22)
    m-font("Lelo", "regular")
    margin-bottom: vw(15px)
    padding-top: vw(5px)
    width: 100%
    border-top: 1px solid rgba(#000, 0.15)

    &.combos-title
      margin-top: vw(15px)

  .insight-display
    &:not(:last-child)
      marign-bottom: vw(20px)

    .ing-family
      m-font-size(10, 13)
      display: flex
      margin-top: vw(2px)
      margin-left: vw(6px)
      font-weight: 300
      font-family: "Lelo"

      span
        margin-left: vw(10px)

      .ing-donut
        margin-right: 3px
        width: 14px

  .combinations-wrapper
    flex: 1 1 0%
    min-height: vw(200px)

  .combinations
    .combo-item
      display: flex
      margin: vw(10px)

      .number
        flex: 0 0 vw(10px)

      >>> .insight-display
        margin: 0
</style>
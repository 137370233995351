<template>
  <div id="app" :class="deviceClass">
    <div id="measure" />
    <div class="main-container">
      <Header v-if="showHeader" />
      <router-view class="view" />
    </div>

    <evolutive-modal />
  </div>
</template>


<script>
import device from '@/utils/device'
import ResponsiveCss from './mixins/ResponsiveCss'
import Header from './components/Header.vue'
import EvolutiveModal from '@/components/EvolutiveModal'
import { ROUTE_NAMES } from './settings/routerSettings'
import { mapActions } from 'vuex'

export default {
  components: { Header, EvolutiveModal },
  mixins: [ResponsiveCss],
  computed: {
    deviceClass() {
      return device
    },
    showHeader() {
      return (
        this.$route.name !== ROUTE_NAMES.Login &&
        this.$route.name !== ROUTE_NAMES.MainPage &&
        this.$route.name !== ROUTE_NAMES.ResetPassword
      )
    },
  },
  mounted() {
    if (this.$route.name !== ROUTE_NAMES.ResetPassword) {
      this.getProfile()
    }
  },
  methods: {
    ...mapActions(['getProfile']),
  },
}
</script>

<style lang="stylus">
// @import "./styles/main"
#app
  height: 100%
  overflow: hidden
  width: 100%

.main-container
  display: flex
  flex-direction: column
  height: 100%
  overflow: hidden
  // margin: 0 auto
  width: 100%

#measure
  height: $inner-height
  overflow: hidden
  pointer-events: none
  position: absolute
  visibility: hidden
  width: 100vw

.view
  overflow-x: hidden
  overflow-y: auto
</style>

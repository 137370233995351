/*
vsLastPeriod = PoPIndex[periode] - PoPIndex[periode-1] / PopIndex[periode-1]
Trend = PoPIndex[periode] - PoPIndex[periode-1] / logintud_periode
*/
import dayjs from 'dayjs'
export function getVariations(value, prevValue, currentFilters, prevFilters, kpis = ['vPopIndex']) {
  return kpis.reduce((dict, kpi) => {
    if (!prevValue) {
      dict[kpi] = {
        trend: Infinity,
        vsLastPeriod: Infinity,
      }
      return dict
    }
    let v = value[kpi]
    let pv = prevValue[kpi]
    let vsLastPeriod = (v - pv) / pv
    let len = dayjs(currentFilters.to).diff(dayjs(currentFilters.from), 'day')
    let trend = (v - pv) / len
    dict[kpi] = {
      vsLastPeriod,
      trend,
    }
    return dict
  }, {})
}

/** @enum {typeof FunnelTableColumns} */
export const FunnelTableColumns = /** @type {const} */ ({
  Sessions: 'sessions',
  Users: 'users',
  Arrival: 'arrival',
  Lost: 'lost',
  SessionDuration: 'avgTime',
})
export const SORT_COLUMNS = [
  FunnelTableColumns.Sessions,
  FunnelTableColumns.Users,
  FunnelTableColumns.Arrival,
  FunnelTableColumns.Lost,
  FunnelTableColumns.SessionDuration,
]
export const SORT_COLUMNS_AIRPARFUM = [
  FunnelTableColumns.Sessions,
  FunnelTableColumns.Arrival,
  FunnelTableColumns.Lost,
  FunnelTableColumns.SessionDuration,
]
/**
 * @typedef {object} Options
 * @property {boolean} isInstore
 */
/**
 *
 * @param {Record<FunnelTableColumns,T>} columns
 * @param {Options} options
 * @template T
 */
export function getColumns(columns, options) {
  const array = options.isInstore ? SORT_COLUMNS_AIRPARFUM : SORT_COLUMNS
  return array.map(key => columns[key])
}
/**
 *
 * @param {FunnelTableColumns} columnField
 * @param {Options} options
 */
export function findIndexField(columnField, options) {
  const array = options.isInstore ? SORT_COLUMNS_AIRPARFUM : SORT_COLUMNS
  return array.indexOf(columnField)
}

/**
 *
 * @param {FunnelTableColumns} columnField
 * @param {Options} options
 */
export function getFieldFromIndex(index, options) {
  const array = options.isInstore ? SORT_COLUMNS_AIRPARFUM : SORT_COLUMNS
  return array[index]
}

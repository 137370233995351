<template>
  <div class="insight-display-carousel">
    <div v-if="title" class="insight-display-carousel__title">{{ title }}</div>
    <InsightDisplay
      :insight="insight"
    >
      <template v-slot:thumbnail>
        <Carousel
          :navigation-enabled="true"
          :navigation-next-label="nextLabel"
          :navigation-prev-label="prevLabel"
          :pagination-active-color="'#B7B7B7'"
          :pagination-color="'#DDDDDD'"
          :pagination-padding="0"
          :per-page="1"
          :value="selectedSlide"
          @page-change="pageChange"
        >
          <Slide v-for="(element, i) in insights" :key="`insight_${i}`">
            <div class="image-thumbnail">
              <img :src="element.imageUrl">
            </div>
          </Slide>
        </Carousel>
      </template>
      <template v-slot:description>
        <FragranceDescription
          :data="insight"
          :compact="true"
        />
      </template>
    </InsightDisplay>
  </div>
</template>
<script>
import InsightDisplay from '../InsightDisplay'
import FragranceDescription from './FragranceDescription.vue'
import { Carousel, Slide } from 'vue-carousel'
import chevron_right from '@/assets/icons/chevron-right.svg'
import chevron_left from '@/assets/icons/chevron-left.svg'
export default {
  components: {
    InsightDisplay,
    FragranceDescription,
    Carousel,
    Slide,
  },
  props: {
    insights: Array,
    title: String,
  },
  data() {
    return {
      selectedSlide: 0,
      nextLabel: `<img src='${chevron_right}' />`,
      prevLabel: `<img src='${chevron_left}' />`,
    }
  },
  computed: {
    insight() {
      return this.insights && this.insights[this.selectedSlide]
    },
  },
  watch: {
    insights() {
      this.pageChange(0)
    },
  },
  methods: {
    pageChange(i) {
      this.selectedSlide = i
    },
  },
}
</script>
<style lang="stylus">
.insight-display-carousel
  display: flex
  flex: 1 1 0%
  flex-direction: column
  margin-left: vw(60px)
  height: 100%

  .insight-display
    .insight-display__thumbnail
      flex: 1 1 0%
      margin-bottom: vw(30px)

  .insight-display__graph-container
    margin-top: vw(10px)

.insight-display-carousel__title
  m-font-size(12, 14)
  margin-bottom: vw(30px)
  font-family: "Lelo"

.image-thumbnail
  width: vw(250px)
  height: vw(250px)
  text-align: center

  img
    height: 100%
    object-fit: contain

.VueCarousel
  width: vw(250px)
  height: vw(250px)

.VueCarousel-pagination
  height: vw(20px)

.VueCarousel-dot
  &:not(:first-child)
    margin-left: 5px

.VueCarousel-navigation-button
  width: 20px
  height: 20px
  border-radius: 50%
  background-color: $alto !important
  color: white !important
  opacity: 1 !important

  &:first-child
    margin-left: 20px

  &:last-child
    margin-right: 20px

.VueCarousel-navigation--disabled
  background-color: $mercury !important
  opacity: 0.5 !important
  pointer-events: none
</style>
<template>
  <div class="entity-compare">
    <div v-if="kpisOptions && brandsOptions && finalTopEntities" class="entity-compare__header">
      <div class="header-options__filters__filter">
        <div class="header-options__filters__filter__title">KPI:</div>
        <multi-select
          v-model="currentKpi"
          :multiple="false"
          :options="kpisOptions"
        />
      </div>
      <div v-if="type === 'FRAGRANCE'" class="header-options__filters__filter">
        <div class="header-options__filters__filter__title">Brand:</div>
        <multi-select
          v-model="selectedBrands"
          :multiple="true"
          :options="brandsOptions"
        />
      </div>
      <div class="pagination">
        <div v-if="lastPage" class="pagination__wrapper">
          <div
            class="pagination__prev"
            :class="{ 'pagination--disabled': currentPage===1 }"
            @click="handlePrev"
          />
          <div class="pagination__numbers">{{ currentPage }} / {{ lastPage }}</div>
          <div
            class="pagination__next"
            :class="{ 'pagination--disabled': currentPage===lastPage }"
            @click="handleNext"
          />
        </div>
      </div>
      <div class="close" @click="onClose" />
    </div>
    <div v-if="kpisOptions && brandsOptions && finalTopEntities" class="lists-wrapper multi">
      <ol
        v-for="(card) in cards"
        :key="card.type"
        class="rank-list"
      >
        <li v-for="(item, i) in card.items" :key="item.id" class="rank-item">
          <div class="rank-item__index">{{ item.rankingPosition || i+1 }}.</div>
          <BrandDisplay
            v-if="type === 'BRAND'"
            :data="item"
            :dark-background="!isPuig(item)"
            :evolutive-kpis="kpis"
            :display-kpi="currentKpi[0]"
            :compact="true"
          />
          <FragranceDisplay
            v-if="type === 'FRAGRANCE'"
            :data="item"
            :dark-background="!isPuig(item)"
            :evolutive-kpis="kpis"
            :display-kpi="currentKpi[0]"
            :compact="true"
          />
        </li>
        <Loader v-if="card.items && card.items.length===0" :text="$t('no_data')" />
        <Loader v-else-if="!card.items" />
      </ol>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BrandDisplay from './InsightDisplay/BrandDisplay.vue'
import MultiSelect from '../MultiSelect.vue'
import FragranceDisplay from './InsightDisplay/FragranceDisplay.vue'
import IndicatorMixin from '../../mixins/IndicatorMixin'
import Loader from '../Loader.vue'
import InView from '@/mixins/InView.js'
import { getBrands, getTopEntitiesWithVariations } from '../../services/api'
import { transformToPreviousPeriod } from '@/utils/filterUtils'
import { ROUTE_NAMES } from '../../settings/routerSettings'

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export default {
  components: {
    BrandDisplay,
    MultiSelect,
    FragranceDisplay,
    Loader,
  },
  mixins: [InView, IndicatorMixin],
  props: {
    topEntities: {
      types: Array,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'FRAGRANCE',
    },
  },
  data() {
    return {
      currentKpi: ['vPopIndex'],
      selectedBrands: null,
      currentPage: 1,
      puigEntities: [],
    }
  },
  computed: {
    ...mapGetters(['filters']),
    kpis() {
      return this.getAvailableElements(this.type.toLowerCase())
    },
    kpisOptions() {
      return this.kpis
        ?.map(id => {
          return { id: id, value: id, label: id }
        })
        .sort((a, b) => {
          return a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        })
    },
    brandsOptions() {
      return this.puigBrands?.map(b => {
        return { id: b.id, value: b.name, label: b.name }
      })
    },
    cards() {
      const top = this.$asyncComputed.finalTopEntities.success ? this.finalTopEntities : null
      if (!this.pagePuigEntities || this.slicedPuigEntities.length === 0) {
        return [top, null, null, null].map(items => ({ items }))
      }
      const entities = [
        top,
        ...this.slicedPuigEntities.slice(
          3 * (this.currentPage - 1),
          3 * (this.currentPage - 1) + 3
        ),
      ]
      return entities?.map(items => ({ items }))
    },
    lastPage() {
      return this.slicedPuigEntities && Math.min(Math.ceil(this.slicedPuigEntities.length / 3), 5)
    },
    filteredPuigEntities() {
      return this.puigEntities?.filter(p => !this.finalTopEntities.map(e => e.id).includes(p.id))
    },
    slicedPuigEntities() {
      return this.puigEntities ? sliceIntoChunks(this.filteredPuigEntities, 5) : null
    },
    allPuigBrandsSelected() {
      return (
        !this.selectedBrands ||
        this.selectedBrands.length === this.puigBrands.length ||
        this.selectedBrands.length === 0
      )
    },
    previousFilters() {
      return transformToPreviousPeriod(this.filters)
    },
  },
  watch: {
    currentKpi() {
      this.currentPage = 1
      this.puigEntities = []
    },
    selectedBrands() {
      this.currentPage = 1
    },
    pagePuigEntities() {
      // mergeamos las de esta page, con las totales que quizas aun no tenian variation
      this.puigEntities = this.pagePuigEntities.map(d => {
        let oldCandidate = this.puigEntities.find(dd => dd.id === d.id)
        if (oldCandidate && oldCandidate.variations[this.currentKpi] !== null) {
          return oldCandidate
        } else {
          return d
        }
        // return this.pagePuigEntities.find(dd => dd.id === d.id) || d
      })
      // this.pagePuigEntities.forEach(d => {
      //   let candidate = this.puigEntities.find(dd => dd.id === d.id)
      //   if (candidate) this.puigEntities.splice(this.puigEntities.indexOf(candidate), 1, d)
      //   else this.puigEntities.push(d)
      // })
    },
  },
  methods: {
    handleNext() {
      this.currentPage = this.currentPage + 1
    },
    handlePrev() {
      this.currentPage = this.currentPage - 1
    },
    isPuig(entity) {
      if (this.type === 'FRAGRANCE') {
        return this.puigBrands.map(({ id }) => id).includes(entity.brand.id)
      } else if (this.type === 'BRAND') {
        return this.puigBrands.map(({ id }) => id).includes(entity.id)
      }
      return null
    },
    onClose() {
      this.$router.push({ name: ROUTE_NAMES.ProductInsights })
    },
    getPuigEntities(variationsPagination) {
      return (
        this.puigBrands &&
        getTopEntitiesWithVariations(
          this.type,
          {
            ...this.filters,
            brands:
              !this.selectedBrands || this.selectedBrands.length === 0
                ? this.puigBrands.map(b => b.id)
                : this.selectedBrands,
          },
          {
            ...this.previousFilters,
            brands:
              !this.selectedBrands || this.selectedBrands.length === 0
                ? this.puigBrands.map(b => b.id)
                : this.selectedBrands,
          },
          // this.kpisOptions.map(k => k.id),
          this.currentKpi,
          {
            limit: 100,
            addRankingPosition: true,
            isPuig: this.allPuigBrandsSelected || null,
          },
          variationsPagination
        )
      )
    },
  },
  asyncComputed: {
    pagePuigEntities() {
      //no esta bien, porque se salta las que ya son del top 5, por eso "tengo qeu ponerle un 20"...
      return this.getPuigEntities([15 * (this.currentPage - 1), 15 * (this.currentPage - 1) + 20])
    },
    puigBrands() {
      return getBrands().then(brands => {
        return brands.filter(b => b.isPuig).map(b => ({ name: b.name, id: b.id }))
      })
    },
    finalTopEntities() {
      return (
        this.topEntities ||
        getTopEntitiesWithVariations(
          this.type,
          this.filters,
          this.previousFilters,
          this.currentKpi,
          {
            limit: 5,
          }
        )
      )
    },
  },
}
</script>
<style lang="stylus" scoped>
.entity-compare
  display: flex
  flex: 1 1 0%
  flex-direction: column

.entity-compare__header
  position: relative
  display: flex
  align-items: center
  padding: vw(30px)

  .close
    m-icon("close", 30)
    position: absolute
    top: 50%
    right: vw(30px)
    cursor: pointer
    transform: translateY(-50%)

.lists-wrapper
  display: flex
  flex: 1 1 0%
  padding-right: vw(30px)
  padding-left: vw(30px)
  gap: vw(20px)

.rank-list
  display: flex
  flex: 0 0 "calc(25% - %s)" % vw(20px)
  flex-direction: column
  margin-top: 0
  margin-bottom: 0
  padding: vw(20px) vw(15px)
  max-width: "calc(25% - %s)" % vw(20px)
  border: 1px solid transparent
  border-radius: 10px
  counter-reset: list

  &:first-child
    border-color: #E0E0E0

  >>> .loader
    border-radius: 10px

.rank-item
  position: relative
  display: flex
  // flex: 1 1 0%
  flex: 0 0 "calc(20% - %s)" % vw(15px)
  padding-bottom: vw(10px)
  min-height: vw(80px)
  border-bottom: 1px solid rgba(#000, 0.1)

  &:not(:last-child)
    margin-bottom: vw(20px)

  >>> .interactive-graph
    height: 100%

.rank-item__index
  m-font-size(14, 22)
  flex: 0 0 vw(24px)
  padding-right: vw(6px)
  padding-left: vw(6px)
  font-family: "Lelo"

.pagination
  margin-right: vw(60px)
  margin-left: auto

.pagination__wrapper
  display: flex
  justify-content: center
  align-items: center

.pagination__prev
  m-icon("chevron-left", 15)
  cursor: pointer

.pagination__next
  m-icon("chevron-right", 15)
  cursor: pointer

.pagination__numbers
  m-font("SangBleu", 500)
  m-font-size(18, 26)
  margin-right: vw(10px)
  margin-left: vw(10px)

.pagination--disabled
  opacity: 0.25
  pointer-events: none

.header-options__filters__filter
  display: flex
  align-items: center
  gap: vw(10px)

  &:not(:last-child)
    margin-right: vw(30px)

.header-options__filters__filter__title
  m-font-size(12, 16)
  font-family: "Lelo"

.multiselect-container,
.multiselect,
.date-range-picker
  min-height: $multiselect-height
  width: $multiselect-width
  height: $multiselect-height
  font-family: "Lelo"

.insight-display--dark-background
  position: relative
  padding-right: vw(5px)
  background: none

  &:before
    position: absolute
    top: vw(-10px)
    left: vw(-5px - 24px)
    z-index: -1
    width: "calc(100% + (%s * 2) + %s)" % (vw(5px) vw(24px))
    height: "calc(100% + %s)" % vw(20px)
    border-radius: 8px
    background-color: #F5F5F5
    content: ""
</style>
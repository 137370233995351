<template>
  <InsightDisplay :insight="data" :compact="compact">
    <template v-slot:thumbnail>
      <FamiliesDonut
        :data="data"
        :size="size"
      />
    </template>
    <template v-slot:description>
      <FamiliesDescription
        :data="data"
        :compact="compact"
      />
    </template>
  </InsightDisplay>
</template>
<script>
import InsightDisplay from '../InsightDisplay'
import FamiliesDonut from './FamiliesDonut.vue'
import FamiliesDescription from './FamiliesDescription.vue'
export default {
  components: { InsightDisplay, FamiliesDonut, FamiliesDescription },
  props: ['data', 'compact', 'size'],
}
</script>
<style lang="stylus" scoped></style>
<template>
  <div class="range-input">
    <toggle-button
      :value="enable"
      class="toggle"
      :label="$t('sensor_map_timelapse')"
      @change="$emit('toggle',$event)"
    />
    <div class="slider-container">
      <div class="play-btn" :class="{'pause': playing, 'disabled': !enable}" @click="handlePlay" />
      <div class="wrapper-input">
        <input
          :value="range"
          :class="{disabled:!enable}"
          class="slider"
          type="range"
          step="1"
          min="0"
          :max="diferenceDays"
          @change="$emit('range',Number($event.target.value))"
        >
        <div class="input-date" :class="{'disabled': !enable}">{{ currentRangeDateString }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import GetFilterBySetting from '../mixins/GetFilterBySetting'
import ToggleButton from './ToggleButton.vue'
dayjs.extend(utc)
export default {
  components: {
    ToggleButton,
  },
  mixins: [GetFilterBySetting],
  props: {
    range: [Number, String],
    enable: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      playing: false,
    }
  },
  computed: {
    diferenceDays() {
      if (!this.topFilters.to || !this.topFilters.from) return 0
      return Math.trunc(
        (this.topFilters.to.getTime() - this.topFilters.from.getTime()) / (1000 * 3600 * 24)
      )
    },

    currentRangeDate() {
      const playingDate = new Date(0)
      playingDate.setDate(this.topFilters.from.getDate())
      playingDate.setMonth(this.topFilters.from.getMonth())
      playingDate.setFullYear(this.topFilters.from.getFullYear())
      playingDate.setDate(playingDate.getDate() + Number(this.range))
      return playingDate
    },
    currentRangeDateString() {
      return dayjs(this.currentRangeDate).utc().format('DD/MM/YYYY')
    },
  },
  watch: {
    topFilters() {
      this.resetPlay()
    },
  },
  methods: {
    resetPlay() {
      this.playing = false
      this.$emit('range', 0)
      clearInterval(this._timer)
    },
    handlePlay() {
      this.playing = !this.playing
      clearInterval(this._timer)
      if (this.playing)
        this._timer = setInterval(() => {
          if (this.loading) return
          if (this.range === this.diferenceDays) {
            return this.resetPlay()
          }
          this.$emit('range', Number(this.range) + 1)
        }, 1000)
      //this.playing = true
    },
  },
}
</script>

<style lang="stylus" scoped>
.range-input
  position: absolute
  top: vw(20px)
  left: vw(300px)
  display: flex
  flex-direction: column
  padding: vw(5px) vw(10px)
  height: vw(40px)

  .wrapper-input
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: vw(20px)
    margin-left: vw(10px)

  .play-btn
    m-icon("play", 40)
    flex: 0 0 vw(40px)
    cursor: pointer

    &.pause
      m-icon("pause", 40)

    &.disabled
      opacity: 0.3
      pointer-events: none

  .tougle
    display: flex
    align-items: center
    margin-right: vw(20px)

  .input-date
    m-font-size(14, 20)
    m-font("Lelo", "regular")
    m-letter-spacing(20)

    &.disabled
      opacity: 0

.slider-container
  display: flex
  flex: 1 1 0%
  align-items: center
  margin-right: vw(10px)
  margin-left: vw(10px)

  .slider
    $size = 10px
    $height = 5px
    margin-right: vw(10px)
    width: 100%
    height: $height
    outline: none
    border-radius: 5px
    background: #d3d3d3
    opacity: 0.77
    transition: opacity 0.2s ease-in-out
    -webkit-appearance: none

    &.disabled
      opacity: 0.3
      pointer-events: none

    &:hover
      opacity: 1

    &::-webkit-slider-thumb
      width: $size
      height: $size
      border-radius: 50%
      background: #777
      cursor: pointer
      appearance: none
      -webkit-appearance: none

    &::-moz-range-thumb
      width: $size
      height: $size
      border-radius: 50%
      background: #777
      cursor: pointer
</style>
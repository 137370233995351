<template>
  <div v-if="title" :class="getMainClasses()" @click="$emit('click')">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: String,
    selected: Boolean,
  },
  methods: {
    getMainClasses: function() {
      return `button-filter${this.selected ? ' selected' : ''}`
    },
  },
}
</script>

<style lang="stylus" scoped>
.button-filter
  m-font-size(13, 22)
  m-letter-spacing(5)
  display: flex
  justify-content: center
  align-items: center
  padding: vw(8px) vw(20px)
  border: 1px solid rgba(#000, 0.2)
  border-radius: 25px
  background-color: #fff
  text-transform: uppercase
  font-family: "Lelo"
  cursor: pointer

  &:hover
    background-color: rgba(#f2f2f2, 0.25)

  &.selected
    background-color: #f2f2f2
</style>

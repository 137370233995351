<template>
  <div class="home-button" @click="changeRoute(path)">
    <div v-if="icon" :class="['home-button__icon ' + icon + '-icon']" />
    <v-clamp
      v-if="title"
      autoresize
      :max-lines="2"
      class="home-button__text"
    >
      {{ title }}
    </v-clamp>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
export default {
  components: { VClamp },
  props: {
    title: String,
    icon: String,
    path: [String, Object],
  },
  methods: {
    changeRoute(route) {
      this.$router.history.current.path !== route && this.$router.push(route)
    },
  },
}
</script>

<style lang="stylus">
.home-button
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: vw(20px)
  width: vw(220)
  height: vw(175px)
  background-color: #fff
  box-shadow: 0 4px 8px rgba(#000, 0.1)
  cursor: pointer
  transition: transform 0.25s ease-in-out

  &:hover
    transform: translateY(vw(-35px))

.home-button__text
  m-font-size(18, 25)
  text-align: center
  font-weight: 700

.home-button__icon
  margin-bottom: vw(15px)

  &.sensor-icon
    m-icon("sensor", 50)

  &.product-icon
    m-icon("product", 50)

  &.ingredients-icon
    m-icon("ingredients", 50)

  &.profile-icon
    m-icon("profile", 50)

  &.funnel-icon
    m-icon("funnel", 50)

  &.top-chosen-icon
    m-icon("top-chosen", 50)
</style>
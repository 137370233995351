export function common_tangent_line(x1, y1, r1, x2, y2, r2) {
  // var dx = x2 - x1
  // var dy = y2 - y1
  // var dist = Math.sqrt(dx * dx + dy * dy)

  // if (dist <= Math.abs(r2 - r1)) return // no valid tangents

  // // Rotation from x-axis
  // var angle1 = Math.atan2(dy, dx)
  // var angle2 = Math.acos((r1 - r2) / dist)

  // return [
  //   [
  //     [x1 + r1 * Math.cos(angle1 + angle2), y1 + r1 * Math.sin(angle1 + angle2)],
  //     [x2 + r2 * Math.cos(angle1 + angle2), y2 + r2 * Math.sin(angle1 + angle2)],
  //   ],
  //   [
  //     [x1 + r1 * Math.cos(angle1 - angle2), y1 + r1 * Math.sin(angle1 - angle2)],
  //     [x2 + r2 * Math.cos(angle1 - angle2), y2 + r2 * Math.sin(angle1 - angle2)],
  //   ],
  // ]

  var dx = x2 - x1
  var dy = y2 - y1
  var hypotenuse = Math.sqrt(dx * dx + dy * dy)
  let c1 = { x: x1, y: y1, r: r1 }
  let c2 = { x: x2, y: y2, r: r2 }
  let short = r1 + r2
  let π = Math.PI
  let φ = Math.atan2(c2.y - c1.y, c2.x - c1.x) + Math.asin(short / hypotenuse) - π / 2
  let t1x = c1.x + c1.r * Math.cos(φ)
  let t1y = c1.y + c1.r * Math.sin(φ)
  let t2x = c2.x + c2.r * Math.cos(φ + π)
  let t2y = c2.y + c2.r * Math.sin(φ + π)

  let φ2 = Math.atan2(c2.y - c1.y, c2.x - c1.x) - Math.asin(short / hypotenuse) + π / 2
  let s1x = c1.x + c1.r * Math.cos(φ2)
  let s1y = c1.y + c1.r * Math.sin(φ2)
  let s2x = c2.x + c2.r * Math.cos(φ2 + π)
  let s2y = c2.y + c2.r * Math.sin(φ2 + π)
  return [
    [
      [t1x, t1y],
      [t2x, t2y],
    ],
    [
      [s1x, s1y],
      [s2x, s2y],
    ],
  ]
}

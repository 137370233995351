<template>
  <div class="search-wrapper">
    <div class="search-input">
      <input v-model="value" placeholder="Search...">
      <div class="search-icon" @click="search()" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    value: '',
  }),
  methods: {
    search() {
      console.log(`i'm searching ${this.value}`)
    },
  },
}
</script>

<style lang="stylus">
.search-wrapper
  position: absolute
  top: 15px
  right: 20px

.search-input
  position: relative
  display: flex
  align-items: center
  width: vw(200px)

  input
    width: vw(200px)
    font-family: "Lelo"
    m-font-size(14, 19)
    padding-right: vw(50px)
    padding-left: 16px
    height: 34px
    border: 1px solid $aluminium
    border-radius: 40px
    color: $gray

.search-icon
  cursor: pointer
  m-icon("search", 30)
  position: absolute
  right: 0
  margin-right: 4px
</style>
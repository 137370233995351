<template>
  <div class="d3-component">
    <svg
      ref="svg"
      class="multi-graph"
      :width="size.width"
      :height="size.height"
    />
    <transition name="fade-tooltip">
      <div v-if="tooltipData" class="tooltip" :style="{top:tooltipData.y+'px',left:tooltipData.x+'px'}">
        <MultiGraphTooltip :key="tooltipData.data.date.toString()" :data="tooltipData.data" />
      </div>
    </transition>
  </div>
</template>

<script>
import Size from '@/mixins/Size'
import { select as d3_select, pointer as d3_pointer } from 'd3-selection'
import { scaleLinear as d3_scaleLinear, scaleTime as d3_scaleTime } from 'd3-scale'
import { axisBottom as d3_axisBottom, axisLeft as d3_axisLeft } from 'd3-axis'
import { max as d3_max, extent as d3_extent } from 'd3-array'
import { line as d3_line } from 'd3-shape'
import { curveMonotoneX as d3_curve } from 'd3-shape'
import { schemeCategory10 as d3_schemeCategory10 } from 'd3-scale-chromatic'
// eslint-disable-next-line
import { transition } from 'd3-transition'

import MultiGraphTooltip from '../MultiGraphTooltip.vue'
import IndicatorMixin from '../../mixins/IndicatorMixin'

const COLORS = d3_schemeCategory10
export default {
  components: { MultiGraphTooltip },
  mixins: [Size, IndicatorMixin],
  props: {
    dataProps: Array,
    refDataProps: Array,
    width: Number,
    height: Number,
    area: Boolean,
    backgroundLines: Boolean,
    showBestMark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipData: null,
    }
  },
  computed: {
    data() {
      return this.dataProps.map((x, i) => {
        return {
          id: x.id,
          datum: x.datum,
          values: x.values,
          prevValues: this.refDataProps ? this.refDataProps[i].values : null,
          color: COLORS[Math.floor(Math.random() * COLORS.length)],
        }
      })
    },
  },
  watch: {
    data() {
      this.draw()
    },
    size() {
      this.draw()
    },
  },
  methods: {
    draw() {
      let svg = d3_select(this.$refs.svg)
      let root = svg.selectAll('.root').data([null])
      let rootEnter = root
        .enter()
        .append('g')
        .attr('class', 'root')
      root = root.merge(rootEnter)
      rootEnter
        .append('g')
        .attr('class', 'xAxis')
        .style('pointer-events', 'none')
      rootEnter
        .append('g')
        .attr('class', 'yAxis')
        .style('pointer-events', 'none')
      rootEnter.append('g').attr('class', 'content')
      let padding = { top: 20, right: 30, bottom: 30, left: 50 }
      let chartArea = {
        width: parseInt(this.size.width) - padding.left - padding.right,
        height: parseInt(this.size.height) - padding.top - padding.bottom,
      }
      let allValues = []
      this.data.forEach(element => {
        allValues = allValues.concat([...element.values])
      })
      let yScale = d3_scaleLinear()
        .domain([0, d3_max(allValues, d => d.value) * 1.2])
        .range([chartArea.height, 0])
        .nice()
      let xScale = d3_scaleTime()
        .domain(d3_extent(allValues, d => d.date))
        .range([0, chartArea.width])
      //xAxis
      root
        .select('.xAxis')
        .attr(
          'transform',
          'translate(' + padding.left + ',' + (chartArea.height + padding.top) + ')'
        )
        .call(d3_axisBottom(xScale))
      //yAxis
      root
        .select('.yAxis')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
        .call(d3_axisLeft(yScale).tickSize(-chartArea.width))
      root
        .select('.content')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      const xValue = d => d.date
      const yValue = d => d.value
      const lineGenerator = d3_line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))
        .curve(d3_curve)
      let items = root
        .select('.content')
        .selectAll('.item')
        .data(this.data, d => d.id)
      let itemsEnter = items
        .enter()
        .append('g')
        .attr('class', 'item')
      itemsEnter
        .append('path')
        .attr('class', 'line')
        .attr('d', d => lineGenerator(d.values))
        .attr('stroke', d => d.color)
        .attr('fill', 'none')
        .attr('stroke-width', 1)
        .style('pointer-events', 'none')
      itemsEnter
        .append('g')
        .attr('class', 'breakpoints')
        .style('opacity', 0)

      let self = this

      function getTooltipPosition(selectedData) {
        let tooltipX = xScale(selectedData.date) + 70
        let tooltipY = yScale(selectedData.value) + 60
        const tooltipWidth = (window.innerWidth * 400) / 1580
        if (tooltipX + tooltipWidth > self.size.width) {
          tooltipX = self.size.width - tooltipWidth
        }
        return {
          x: tooltipX,
          y: tooltipY,
        }
      }

      itemsEnter
        .on('mouseover mousemove', (event, d) => {
          // let rect = this.$el.getBoundingClientRect()
          // this.tooltipData = {
          //   datum: d.datum,
          //   x: e.clientX - rect.left,
          //   y: e.clientY - rect.top,
          // }
          const pointer = d3_pointer(event)[0]
          const x0 = xScale.invert(pointer)
          let selectedData = d.values.reduce(
            (diff, d) => {
              let dist = Math.abs(d.date.getTime() - x0.getTime())
              if (diff.dist > dist) return { dist: dist, datum: d }
              else return diff
            },
            { dist: Infinity, datum: null }
          ).datum
          if (
            !this.tooltipData ||
            this.tooltipData.data?.entity !== d.datum ||
            this.tooltipData.data?.date !== selectedData.date
          ) {
            this.tooltipData = {
              data: {
                entity: d.datum,
                indicators: this.getAllIndicatorsData(
                  d.values,
                  d.prevValues,
                  selectedData.date,
                  d.datum.entityType
                ),
                date: selectedData.date,
              },
              ...getTooltipPosition(selectedData),
            }
          }
          setHighlighted(d)
        })
        .on('mouseout', () => {
          this.tooltipData = null
          setHighlighted(null)
        })

      let setHighlighted = function(d) {
        let tr = items.transition()
        tr.attr('opacity', dd => {
          if (!d) return 1
          else return dd.id === d.id ? 1 : 0.4
        })
          .select('.line')
          .attr('stroke-width', dd => {
            if (!d) return 1
            else return dd.id === d.id ? 2 : 1
          })
        tr.select('.breakpoints').style('opacity', dd => {
          if (!d) return 0
          else return dd.id === d.id ? 1 : 0
        })
      }
      itemsEnter
        .append('path')
        .attr('class', 'ghost-line')
        .attr('fill', 'none')
        .attr('stroke', 'rgba(0, 0, 0, 0)')
        .attr('stroke-width', 10)
        .style('cursor', 'pointer')

      let itemsExit = items.exit()
      itemsExit.remove()
      items = items.merge(itemsEnter)
      let breakpoints = items
        .select('.breakpoints')
        .selectAll('.breakpoint')
        .data(
          d => d.values,
          (dd, index) => index
        )
      let breakpointsEnter = breakpoints
        .enter()
        .append('g')
        .attr('class', 'breakpoint')
        .attr('pointer-events', 'none')
      breakpointsEnter
        .append('circle')
        .attr('r', 3)
        .attr('fill', '#fff')
        .attr('stroke', function() {
          return d3_select(this.parentNode.parentNode).datum().color
        })
      breakpoints = breakpoints.merge(breakpointsEnter)
      breakpoints.attr('transform', dd => `translate(${xScale(dd.date)},${yScale(dd.value)})`)

      items.select('.ghost-line').attr('d', d => lineGenerator(d.values))
      let itemsTrans = items.transition()
      itemsTrans.attr(
        'transform',
        d => `translate(${d.x ? d.x : 0 * this.size.width},${d.y ? d.y : 0 * this.size.height})`
      )
      itemsTrans.select('.line').attr('d', d => lineGenerator(d.values))

      let bests = this.data.reduce((bests, d) => {
        let max = d3_max(d.values, dd => dd.value)
        // if (!best || max > best.value)
        bests.push({
          datum: d.datum,
          date: d.values.find(dd => dd.value === max).date,
          value: max,
        })
        return bests
      }, [])
      bests.sort((a, b) => b.value - a.value)
      bests = bests.slice(0, 3)

      let marks = items.selectAll('.best-mark').data(
        d => (bests.find(b => b.datum === d.datum) ? [bests.find(b => b.datum === d.datum)] : []),
        d => d.datum.id
      )
      let marksEnter = marks
        .enter()
        .append('g')
        .attr('class', 'best-mark')
      if (this.showBestMark)
        if (this.data[0]?.datum.entityType === 'FRAGRANCE') {
          marksEnter
            .append('svg:image')
            .attr('width', 40)
            .attr('href', d => d.datum.imageUrl)
            .attr('transform', 'translate(-20,-60)')
        } else if (this.data[0]?.datum.entityType === 'FAMILY') {
          const wrapper = marksEnter.append('g')
          // wrapper
          //   .append('text')
          //   .text(d => d.datum.family.name)
          //   .style('text-anchor', 'middle')
          const circles = wrapper.append('g')
          circles
            .append('circle')
            .attr('r', 20)
            .attr('transform', 'translate(0,-15)')
            .attr('fill', d => d.datum.family.color)
            .attr('stroke', '#fff')
          circles
            .append('circle')
            .attr('r', 10)
            .attr('transform', 'translate(0,-15)')
            .attr('fill', d => d.datum.subfamily.color)
            .attr('stroke', '#fff')
        } else if (this.data[0]?.datum.entityType === 'INGREDIENT') {
          const wrapper = marksEnter.append('g')
          wrapper
            .append('defs')
            .append('clipPath')
            .attr('id', d => `image_${d.datum.id}`)
            .append('circle')
            .attr('r', 20)
            .attr('cx', 20)
            .attr('cy', 20)
          wrapper
            .append('svg:image')
            .attr('width', 40)
            .attr('href', d => d.datum.imageUrl)
            .attr('transform', 'translate(-20,-40)')
            .attr('clip-path', d => `url(#image_${d.datum.id})`)
          // wrapper
          //   .append('text')
          //   .text(d => d.datum.name)
          //   .style('text-anchor', 'middle')
        } else {
          marksEnter
            .append('text')
            .text(d => d.datum.name)
            .style('text-anchor', 'middle')
        }
      marks = marks.merge(marksEnter)
      marks.attr('transform', d => `translate(${xScale(d.date)},${yScale(d.value) - 10})`)
    },
  },
}
</script>

<style lang="stylus">
.multi-graph
  .tick
    line
      stroke: #C0C0BB
      stroke-dasharray: 5 5

  .domain
    stroke: #C0C0BB

  svg
    position: absolute

.d3-component
  padding: vw(20px)
  width: 100%
  height: vw(500px)

.tooltip
  position: absolute
  z-index: 100
  border: 1px solid $concrete
  border-radius: 3px
  background-color: white
  box-shadow: 0 3px 7px $alto
  font-family: "Lelo"
  transition: top, left 0.2s
  transform: translateY(calc(-100% - 30px))
  pointer-events: none

.fade-tooltip-enter-active,
.fade-tooltip-leave-active
  transition: opacity 0.5s

.fade-tooltip-enter,
.fade-tooltip-leave-to /* .fade-leave-active below version 2.1.8 */
  opacity: 0
</style>
<template>
  <label class="toggle__button" :class="{'active': value}" for="toggle_button">
    <div class="toggle__label">{{ label }}</div>
    <input id="toggle_button" v-model="checkedValue" type="checkbox">
    <div class="toggle__switch" :class="{'active': value}" />
  </label>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Boolean,
    label: String,
  },
  data() {
    return {
      currentState: false,
    }
  },

  computed: {
    isActive() {
      return this.value
    },

    checkedValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('change', newValue)
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
.toggle__button
  display: flex
  align-items: center
  vertical-align: middle
  cursor: pointer
  user-select: none

  input[type="checkbox"]
    position: absolute
    width: 1px
    height: 1px
    opacity: 0

  .toggle__switch
    position: relative
    margin-right: vw(10px)
    margin-left: vw(10px)
    width: vw(40px)
    height: vw(12px)
    border-radius: 6px
    background-color: #BFCBD9

    &:before
      position: absolute
      top: 50%
      left: 50%
      display: block
      width: vw(20px)
      height: vw(20px)
      border-radius: 50%
      background-color: #4D4D4D
      content: ""
      transition: transform 0.25s ease-in-out
      transform: translate(-100%, -50%)

    &.active
      &:before
        background-color: #229
        transform: translate(0, -50%)
</style>
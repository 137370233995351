// export const sessionKpis = [
//   'vSessions',
//   'vDuration',
//   'vPages',
//   'vBounceRate',
//   'vViews',
//   'vUniqueVisits',
//   'vProfile', //PETABA porqeu en los evols a veces no venia....
// ]
// export const entityKpis = [
//   'vPopIndex',
//   'vRecommended',
//   'vInteraction',
//   'vAddedToProfile',
//   'vSearch',
// ]

export function getEntityKpis(defs, entity) {
  return defs[entity.toLowerCase()].available
}

function getEntityObj(defs, entity) {
  const obj = defs[entity.toLowerCase()]
  if (!obj && Object.keys(defs).length > 0)
    console.warn(
      'type',
      entity.toLowerCase(),
      'not found in indicators\n Options:',
      Object.keys(defs).join(',')
    )
  return obj
}

export function getPopRelatedKpis(defs, entity) {
  const obj = getEntityObj(defs, entity)
  if (!obj) return []
  return obj.popIndexRelated.map(d => d.value)
}
const MAIN_TOP_VALUE = 'vPopIndex'
function sortPopIndexFirst(array) {
  var kpis = [...array]
  const index = kpis.indexOf(MAIN_TOP_VALUE)
  if (index > -1) {
    kpis.splice(index, 1)
    kpis = [MAIN_TOP_VALUE, ...kpis]
  }
  return kpis
}
export function getAvailableElements(defs, type) {
  const obj = getEntityObj(defs, type)
  if (!obj) return []
  return sortPopIndexFirst(obj.available)
}
/**
 * sorted: https://code.bestiario.org/devel/puig/DoD/issues/137
 */
export const indLabels = {
  //sessionKpis
  vSessions: 'Sessions',
  vViews: 'Views',
  vPages: 'Events per session',
  vDuration: 'Avg. Duration',
  vBounceRate: 'Bounce Rate',
  vUniqueVisits: 'Unique visitors',
  vProfile: 'Profiles created',
  vSuccessRate: 'Success rate',
  vConversionRate: 'Conversion rate',
  vAvgSessionDuration: 'Total average time',

  //entityKpis
  vRecommended: 'Recommended',
  vInteraction: 'Interacted',
  vPopIndex: 'Popularity Index',
  vAddedToProfile: 'Added to profile',
  vSearch: 'Searched',
  vLiked: 'Linked',
  vBuy: 'Clicked to buy',
  vTry: 'Tried',
  vShared: 'Shared',
}

export default {
  state: {
    id: null,
    sensors: null,
    countries: null,
    name: null,
  },
  getters: {
    retailer: state => {
      return state
    },
  },
  mutations: {
    setRetailer(state, retailer) {
      Object.assign(state, retailer)
    },
  },
  actions: {
    setRetailer(context, retailer) {
      context.commit('setRetailer', retailer)
    },
  },
}

<template>
  <div class="info-button">
    <div class="info-icon" @mouseenter="showInfo=true" @mouseleave="showInfo=false" />
    <div v-if="showInfo" class="popup">{{ $t(infoKey) }}</div>
  </div>
</template>

<script>
export default {
  props: ['infoKey'],
  data() {
    return {
      showInfo: false,
    }
  },
}
</script>

<style lang="stylus" scoped>
.info-button
  position: relative

  .info-icon
    m-icon("info", 24)
    margin-top: -0.25em
    margin-left: vw(5px)

  .popup
    m-font-size(14, 20)
    position: absolute
    top: 0
    left: vw(30)
    z-index: 9999
    padding: vw(10px)
    min-width: vw(300px)
    max-width: vw(600px)
    background: #fff
    box-shadow: 0 3px 7px $alto
</style>
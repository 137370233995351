import { group as d3_group } from 'd3-array'
import { t } from '../i18n'

export function makeTree(
  rows,
  dimension,
  {
    reduceCalcuculatedFunction = (last, next) => {
      last[dimension] = last[dimension] || 0
      last[dimension] += next[dimension]
      return last
    },
    getTotal = e => e[dimension],
  } = {}
) {
  var nested_data = d3_group(
    rows,
    d => d.retailer,
    d => d.type
  )
  let retailers = Array.from(nested_data, ([parentId, children]) => ({
    id: parentId,
    retailer: parentId,
    nodeType: 'GROUP',
    children: Array.from(children, ([id, children]) => ({
      id: parentId + '_' + id,
      label: parentId + ' ' + t(id.toLowerCase()),
      nodeType: 'SUBGROUP',
      retailer: parentId,
      children: children.map(c => ({
        ...c,
        nodeType: 'LEAF',
      })),
    })),
  }))

  let root = {
    id: 'All',
    nodeType: 'ROOT',
    children: retailers,
  }
  let sum0 = {}
  retailers.forEach(r => {
    let sum1 = {}
    r.parent = root
    r.children.forEach(t => {
      let sum2 = {}
      t.parent = r
      t.children.forEach(s => {
        s.parent = t
        s.value = s.values[dimension]
        sum2 = reduceCalcuculatedFunction(sum2, s.values)
      })
      t.value = getTotal(sum2)
      sum1 = reduceCalcuculatedFunction(sum1, sum2)
    })
    r.value = getTotal(sum1)
    sum0 = reduceCalcuculatedFunction(sum0, sum1)
  })
  root.value = getTotal(sum0)

  let types = retailers
    .map(r => {
      return r.children
    })
    .flat()

  let sensors = types
    .map(t => {
      return t.children
    })
    .flat()

  //TRAMPA total para que qeude "unpocomejor"
  // root.value = root.value / 2

  let nodes = [root, ...retailers, ...types, ...sensors]
  // nodes.forEach(n => (n.value = 100))
  nodes.reverse().forEach(n => {
    if (n.parent && n.parent !== root && n.parent.children.length === 1) {
      n.parent.children = n.children
      if (n.children) n.children.forEach(c => (c.parent = n.parent))
      n.parent = null
      // n.children[0].parent = n.parent
      n.out = true
      n.id = 'out_' + n.id
    }
  })
  nodes = nodes.filter(n => !n.out)

  return nodes
}

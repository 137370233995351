import { getIngredientsCombinations } from '@/services/api'
/**
 * return the combinations of the selected ingredient with the filter applied
 * @param {Object} topFilters
 * @param {Ingredient} ingredient
 * @returns
 */
export async function getRelated(topFilters, ingredient) {
  return getIngredientsCombinations({ ...topFilters, id: [ingredient.id] }).then(combos => {
    return combos.find(c => c.id === ingredient.id)?.combinations || []
  })
}

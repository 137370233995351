import * as api from '../services/api'
import { getAvailableElements, getPopRelatedKpis, indLabels } from '../utils/indicatorUtils'
export { indLabels }
import { mapGetters } from 'vuex'
export function translateKpi(kpi) {
  return indLabels[kpi]
}
export default {
  asyncComputed: {
    indicators: {
      get() {
        // issue #314. Filters indicators by date
        return api.getIndicators(this.topFilters)
      },
      default: {},
    },
  },
  computed: {
    ...mapGetters(['topFilters']),
    updatingIndicators() {
      return this.$asyncComputed.indicators.updating
    },
    indLabels() {
      return indLabels
    },
    sessionKpis() {
      return this.getAvailableElements('session').sort((a, b) => (a > b ? 1 : -1))
    },
  },
  methods: {
    getAvailableElements(entity) {
      return getAvailableElements(this.indicators, entity)
    },
    getPopRelatedKpis(entity) {
      return getPopRelatedKpis(this.indicators, entity)
    },
    getAllIndicatorsData(data, refData = null, date = null, entityType, nonPopRelated = false) {
      // return entityKpis.reduce((res, indId) => {
      //   res[indId] = getIndicatorData(data, indId, refData)
      //   return res
      // }, {})
      let kpis
      if (nonPopRelated) {
        kpis = Object.keys(data[0]).filter(kpi => kpi !== 'id' && kpi !== 'date')
      } else {
        kpis = this.getPopRelatedKpis(entityType)
        kpis.push('vPopIndex')
      }
      const index = kpis.indexOf('vPopIndex')
      if (index > -1) {
        kpis.splice(index, 1)
        kpis = ['vPopIndex', ...kpis]
      }

      return kpis.map(indId => {
        return this.getIndicatorData(data, indId, refData, date)
      })
    },
    // eslint-disable-next-line
    getIndicatorData(data, indId, refData = null, date = null) {
      let evol = data.map(d => {
        return {
          date: d.date,
          value: d[indId] || 0,
        }
      })
      // let vars =
      //   refData &&
      //   refData.map(d => {
      //     return {
      //       date: d.date,
      //       value: d[indId] || 0,
      //     }
      //   })

      let showValue
      let varShowValue
      if (date) {
        const currEvol = evol.find(d => d.date >= date)
        const currEvolIndex = evol.indexOf(currEvol)
        showValue = currEvol.value
        if (currEvolIndex > 0) {
          varShowValue = evol[currEvolIndex - 1].value
        } else {
          varShowValue = NaN
        }
      } else {
        showValue = evol[evol.length - 1].value
        varShowValue = evol[evol.length - 2].value
      }

      let variation = showValue / varShowValue - 1
      if (showValue === 0 && varShowValue === 0) variation = 0
      else if (varShowValue === 0) {
        variation = Infinity
      }

      return {
        id: indId,
        label: indLabels[indId],
        evolutive: evol,
        value: showValue,
        variation,
      }
    },
  },
}

import en from './lang/en.js'

import store from '@/store'

const DEFAULT_APP_LANG = 'en'

let storeInstance = store
let allStrings = {
  en,
}

export function initLang() {
  let lang = navigator.language.split('-')[0] || DEFAULT_APP_LANG
  if (Object.keys(allStrings).indexOf(lang) < 0) lang = Object.keys(allStrings)[0]

  storeInstance.dispatch('setLanguage', lang)
  return storeInstance.state.i18n.lang
}

export function t(key) {
  let lang = storeInstance.state.i18n.lang || initLang()
  // hace "fallback" a idiomas "parceidos" (es <-> es-ES)
  let availableLang =
    Object.keys(allStrings).indexOf(lang) >= 0
      ? lang
      : Object.keys(allStrings).find(l => l.indexOf(lang) >= 0 || lang.indexOf(l) >= 0)
  let aux = availableLang && allStrings[availableLang][key]
  return aux != undefined ? aux : '[' + key + ']'
}

export default {
  methods: {
    $t: t,
  },
}

<template>
  <!-- Guarrete este if, pero es la manera que no pida "nada" cuando aun no se ha inicializado el Date picker -->
  <div v-if="$store.state.productData.topFilters.from" class="product-insights">
    <SensorsOverview />
    <div class="product-insights__main content-container">
      <TrendingNow />
      <ProductRankings />
      <Spotlight type="BRAND" />
      <Spotlight type="FAMILY" />
      <Spotlight type="PERFUMIST" />
      <Spotlight type="INGREDIENT" />
      <Evolutives />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SensorsOverview from '../components/productInsights/SensorsOverview.vue'
import TrendingNow from '../components/productInsights/TrendingNow.vue'
import Evolutives from '../components/productInsights/Evolutives.vue'
import ProductRankings from '../components/productInsights/ProductRankings.vue'
// import Card from '../components/productInsights/Card.vue'
// import SpotlightDisplay from '../components/productInsights/InsightDisplay/SpotlightDisplay.vue'
// import InsightDisplay from '../components/productInsights/InsightDisplay'
// import InsightDisplayCarousel from '../components/productInsights/InsightDisplay/InsightDisplayCarousel.vue'
// import Section from '../components/productInsights/Section.vue'
// import Separator from '../components/productInsights/Separator.vue'

// import Loader from '../components/Loader.vue'
// import { getTopEntities } from '../services/api'
import Spotlight from '../components/productInsights/Spotlight.vue'
// import SubFilters from '../components/productInsights/SubFilters.vue'
export default {
  components: {
    SensorsOverview,
    TrendingNow,
    Evolutives,
    ProductRankings,
    Spotlight,
    // SubFilters,
  },
  props: {
    // filters: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  asyncComputed: {
    // brands() {
    //   return getTopEntities('BRAND', this.filters).then(data => data.slice(0, 5))
    // },
    // fragrances() {
    //   return getTopEntities('FRAGRANCE', this.filters).then(data => data.slice(0, 5))
    // },
  },
  computed: {
    // ...mapGetters(['productDataAvailable', 'productRankings', 'trendingNow']),
    ...mapGetters(['filters']),
    // brands() {
    //   return this.productRankings.brands
    // },
    // fragrances() {
    //   return this.productRankings.fragrances
    // },
    // trending() {
    //   return this.trendingNow.brand
    // },
  },
  methods: {
    // width() {
    //   return 1000
    // },
    // getColorGraph() {
    //   return 'blue'
    // },
  },
}
</script>
<style lang="stylus" scoped>
.product-insights
  position: relative
  padding: vw(20px)
  width: 100%
  height: 100%
</style>
<template>
  <div class="report">
    <page1 />
  </div>  
</template>
<script>
import Page1 from '@/components/report/Page1'
export default {
  components:{
    Page1
  }
}
</script>
<style lang="stylus">
</style>
<template>
  <div class="insight-display" :class="{ 'insight-display--compact': compact, 'insight-display--dark-background': darkBackground }">
    <div class="insight-display__thumbnail-container">
      <div class="insight-display__thumbnail">
        <slot name="thumbnail" />
      </div>
      <div class="insight-display__description">
        <slot name="description" />
      </div>
    </div>
    <!-- <div class="insight-display__trending">
      <TrendingBadge :small="compact" />
    </div> -->
    <div class="insight-display__graph-container">
      <!-- <div class="insight-display__separator">
        <Separator :margin-top="8" :color="'#B2B2B2'" />
      </div> -->
      <div class="insight-display__pop">
        <!-- <div class="insight-display__pop__title">PoP Index</div> -->
        <template v-if="insight.variations[displayKpi]">
          <Percentage class="insight-display__pop__percentage" :value="insight.variations[displayKpi].vsLastPeriod" label=" vs last period" />
          <Percentage
            class="insight-display__pop__percentage"
            :value="insight.variations[displayKpi].trend"
            label=" trend"
            @evol="showEvol"
          />
        </template>
        <template v-else><div class="insight-display__pop__loading">Loading trends...</div></template>
      </div>
      <!-- <div class="insight-display__graph">
        <InteractiveGraph
          v-if="evolutiveData"
          ref="interactiveGraphRef"
          v-model="graphValue"
          :data-props="evolutiveData"
          :ref-data-props="null"
          :display-kpi="displayKpi"
          :background-lines="false"
          :show-tick-values="true"
          :entity-type="insight.entityType"
        />
        <Loader v-else />

      </div> -->
    </div>
  </div>
</template>

<script>
// import TrendingBadge from '../TrendingBadge.vue'
import Percentage from '../Percentage.vue'
// import InteractiveGraph from '../InteractiveGraph.vue'
// import PopularityIndexDetails from '../PopularityIndexDetails.vue'
// import Separator from '../Separator.vue'
// import { getEvolutive, getTopEntities } from '../../../services/api'
// import Loader from '../../Loader.vue'
import { mapGetters } from 'vuex'
// import { transformToPreviousPeriod } from '@/utils/filterUtils'
import IndicatorMixin from '../../../mixins/IndicatorMixin'
export default {
  components: {
    // TrendingBadge,
    // InteractiveGraph,
    // PopularityIndexDetails,
    Percentage,
    // Separator,
    // Loader,
  },
  mixins: [IndicatorMixin],
  props: {
    title: String,
    trending: Boolean,
    darkBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    // variation: Number,
    insight: {
      type: Object,
      required: true,
    },
    previousInsight: {
      type: Object,
      required: false,
    },
    evolutiveKpis: {
      type: Array,
      required: false,
    },
    displayKpi: {
      type: String,
      required: false,
      default: 'vPopIndex',
    },
    compact: Boolean,
  },
  data() {
    return {
      graphValue: null,
    }
  },
  asyncComputed: {
    evolutiveData() {
      // return getEvolutive(
      //   this.insight.entityType,
      //   this.insight.id,
      //   this.filters,
      //   this.evolutiveKpis
      // )
      return null
    },
    // variation() {
    //   // return getTopEntities(this.insight.entityType, {
    //   //   ...transformToPreviousPeriod(this.filters),
    //   //   id: [this.insight.id],
    //   // }).then(res => {
    //   //   const previousInsight = res.find(d => d.id === this.insight.id)
    //   //   let prev = previousInsight && previousInsight[this.displayKpi]
    //   //   let curr = this.insight[this.displayKpi]
    //   //   return (curr - prev) / prev
    //   // })
    //   return this.insight.vsLastPeriod
    // },
  },
  computed: {
    ...mapGetters(['filters']),
  },
  methods: {
    anchorPosition() {
      if (this.graphValue === null) {
        return null
      }
      return {
        x: this.graphValue.position.x,
        y: this.$refs.interactiveGraphRef.$el.offsetTop + this.graphValue.position.y,
      }
    },
    graphWidth() {
      return this.$props.compact ? 105 : 228
    },
    graphHeight() {
      return this.$props.compact ? 30 : 50
    },
    showEvol() {
      this.$modal.show('evolutive', {
        evolConfig: {
          kpi: this.displayKpi || 'vPopIndex',
          type: this.insight.entityType,
          filters: this.filters,
          id: this.insight.id,
          values: this.evolutiveKpis || [
            'vPopIndex',
            ...this.getPopRelatedKpis(this.insight.entityType),
          ],
        },
        panelConfig: {
          entity: this.insight,
          variations: this.insight.variations[this.displayKpi],
        },
      })
    },
  },
}
</script>
<style lang="stylus" scoped>
.insight-display
  position: relative
  display: flex
  flex: 1 1 0%
  flex-direction: column
  max-width: "calc(100% - %s)" % vw(24px)
  width: 100%

.insight-display--dark-background
  background-color: lightgray

.insight-display__thumbnail-container
  display: flex
  flex: 1 1 0%
  flex-direction: column
  overflow: hidden

.insight-display__thumbnail
  display: flex
  justify-content: center
  align-items: center
  margin-right: auto
  margin-bottom: vw(15px)
  margin-left: auto
  width: vw(250px)
  height: vw(250px)

  .brand-name
    m-font-size(50, 60)
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    font-family: "Lelo"

  .thumbnail
    width: 100%
    height: 100%
    object-fit: contain

  .img-circle
    width: 100%
    height: 100%
    border-radius: 50%
    object-fit: cover

.insight-display__description
  display: flex
  flex-direction: column
  overflow: hidden

.insight-display__trending
  display: flex
  justify-content: flex-end
  align-items: flex-end

.insight-display__graph-container
  margin-top: vw(30px)

.insight-display__pop__title
  m-font-size(12, 14)

.insight-display__pop__percentage
  display: flex
  align-items: center
  padding: vw(15px) vw(10px)
  border-top: 1px solid #BDBDBD
  font-family: "Lelo"

  >>> .texts
    m-font-size(20, 27)
    font-weight: 300

  >>> .evol-button
    m-icon("evol", 26)
    flex: 0 0 vw(26px)

.insight-display__pop__title__vs
  m-font-size(12, 14)
  margin-left: vw(10px)
  text-align: right

.insight-display__pop__loading
  m-font-size(12, 14)
  margin-left: vw(10px)
  text-align: right

.insight-display__graph
  .graph
    position: relative
    display: flex
    align-items: center

.insight-display--compact
  flex-direction: row
  align-items: flex-start

  .insight-display__thumbnail-container
    $padding = vw(5px)
    display: flex
    flex: 0 0 "calc(60% - %s)" % $padding
    flex-direction: row
    align-items: center
    padding-right: $padding

  .insight-display__thumbnail
    justify-content: flex-start
    align-self: flex-start
    margin: 0
    width: auto
    height: auto

    .brand-name
      m-font-size(22, 30)
      width: auto
      height: auto
      font-family: inherit

    .thumbnail,
    .img-circle,
    .families-donut
      margin-right: vw(10px)
      width: vw(54px)
      height: vw(54px)

  .insight-display__graph-container
    // $padding = vw(5px)
    display: flex
    // flex: 0 0 "calc(40% - %s)" % $padding = vw(5px)
    flex: 0 0 40%
    flex: 1 1 0%
    flex-direction: column
    margin: 0
    // padding-left: $padding
    height: 100%

  .interactive-graph
    position: relative
    height: 115%
    height: 100%

    >>> svg
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

  .insight-display__pop__percentage
    flex-direction: column
    justify-content: end
    padding: 0
    border-top: none

    >>> .texts
      justify-content: end

  .insight-display__separator,
  .insight-display__pop__title
    display: none

  .insight-display__pop
    margin-bottom: vw(5px)

    >>> .texts
      m-font-size(12, 16)
      width: 100%

    >>> .evol-button
      m-icon("evol", 20)
      flex: 0 0 vw(20px)

  .insight-display__graph
    flex: 1 1 0%
</style>
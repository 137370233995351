<template>
  <div class="fragrance-description" :class="{ 'fragrance-description--compact': compact}">
    <div class="fragrance-description__brand-name">{{ data.brand.name }}</div>
    <v-clamp autoresize :max-lines="2" class="fragrance-description__name">{{ data.name }}</v-clamp>
    <div class="fragrance-description__family-info">
      <div class="fragrance-description__donut">
        <FamiliesDonut :data="data" :size="compact ? 'xsm' : 'sm'" />
      </div>
      <div class="fragrance-description__ing-name">{{ `${data.family.name} / ${data.subfamily.name}` }}</div>
    </div>
  </div>
</template>

<script>
import FamiliesDonut from './FamiliesDonut.vue'
import VClamp from 'vue-clamp'
export default {
  components: { FamiliesDonut, VClamp },
  props: {
    // name: String,
    // family: String,
    // subFamily: String,
    data: Object,
    compact: Boolean,
  },
}
</script>

<style lang="stylus" scoped>
.fragrance-description__brand-name
  m-font-size(13, 22)
  margin-bottom: vw(5px)
  font-family: "Lelo"

.fragrance-description__name
  m-font-size(22, 30)
  margin-bottom: vw(10px)
  height: vw(60px)
  font-family: "SangBleu"

.fragrance-description__family-info
  display: flex
  align-items: center
  overflow: hidden

.fragrance-description__ing-name
  m-ellipsis(100%)
  m-font-size(10, 13)
  margin-top: 0.1em
  font-family: "Lelo"

.fragrance-description__donut
  flex: 0 0 vw(18px)
  margin-right: vw(5px)
  width: vw(18px)
  height: vw(18px)

.fragrance-description--compact
  padding-top: 0

  .fragrance-description__brand-name
    m-font-size(10, 14)
    margin-bottom: vw(5px)

  .fragrance-description__name
    m-font-size(12, 14)
    margin-bottom: vw(5px)
    height: vw(28px)
    font-family: "Lelo"

  .fragrance-description__family-info
    transform: translateX(-1px)

    p
      m-font-size(10, 13)
</style>
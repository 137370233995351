import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AsyncComputed from 'vue-async-computed'
import DeviceDetect from './mixins/DeviceDetect'
import PrettyValues from './mixins/PrettyValues'
import i18n from './i18n'
import VCalendar from 'v-calendar'
import VModal from 'vue-js-modal'

// import dayjs from 'dayjs'
// var utc = require('dayjs/plugin/utc')
// var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
// dayjs.extend(utc)
// dayjs.extend(timezone)
// dayjs.tz.setDefault('Europe/Madrid')

Vue.mixin(DeviceDetect)
Vue.mixin(PrettyValues)
Vue.mixin(i18n)
Vue.config.productionTip = false
Vue.use(AsyncComputed)

//no me gusta nada importarlo asi, pero lo recomiendan en la docu... :(
Vue.use(VCalendar)
Vue.use(VModal)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="section">
    <div class="section__card">
      <div class="section-card__header">
        <div class="section-card__title">
          {{ $t('section_title_'+id) }}
          <info-button :info-key="'info_section_'+id" />
        </div>
      </div>
      <div v-if="buttonFilters || searchable" class="section-card__filters-wrapper">
        <div v-if="buttonFilters" class="section-card__button-filters">
          <ButtonFilter
            v-if="allFillter"
            :selected="currentType==='ALL'"
            title="TOP OF THE TOP"
            @click="$emit('change-type','ALL')"
          />
          <ButtonFilter :selected="currentType==='FRAGRANCE'" title="TOP FRAGRANCES" @click="$emit('change-type','FRAGRANCE')" />
          <ButtonFilter :selected="currentType==='BRAND'" title="TOP BRANDS" @click="$emit('change-type','BRAND')" />
          <ButtonFilter :selected="currentType==='FAMILY'" title="TOP FAMILIES" @click="$emit('change-type','FAMILY')" />
          <ButtonFilter :selected="currentType==='INGREDIENT'" title="TOP INGREDIENTS" @click="$emit('change-type','INGREDIENT')" />
        </div>
        <Search v-if="searchable" />
      </div>
      <div class="section-card__main">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFilter from '../ButtonFilter.vue'
import InfoButton from '../InfoButton.vue'
import Search from './Search.vue'

export default {
  components: { ButtonFilter, Search, InfoButton },
  props: {
    title: String,
    buttonFilters: Boolean,
    allFillter: Boolean,
    searchable: Boolean,
    currentType: String,
    id: String,
  },
  computed: {},
}
</script>

<style lang="stylus" scoped>
.section
  margin-bottom: vw(60px)
  min-height: vw(400px)

.section__outer-info
  display: flex
  justify-content: space-between

  h4
    margin: 0

.section-card__title
  display: flex
  align-items: center

.section-card__button-filters
  display: flex
  margin-bottom: vw(20px)

  >>> .button-filter
    &:not(:last-child)
      margin-right: vw(10px)

.section-card__filters-wrapper
  position: relative
  display: flex
  justify-content: center
  align-items: center

.section-card__header
  display: flex
  align-items: center
  margin-bottom: vw(20px)
  padding-bottom: vw(20px)
  border-bottom: 1px solid rgba(#000, 0.1)

  .sub-filters
    margin-left: auto

.section-card__main
  min-height: vw(250px)

.section-card__title
  m-font-size(18, 25)

  .info-icon
    margin-left: vw(10px)
</style>
<template>
  <div class="reset-password-page">
    <form
      method="post"
      @submit.prevent="onSubmit"
    >
      <div class="input-container">
        <label for="password">New password:</label>
        <input
          type="password"
          placeholder="Enter Password"
          name="password"
          required
        >
      </div>
      <div class="input-container">
        <label for="repeat-password">Repeat password:</label>
        <input
          type="password"
          placeholder="Enter Password"
          name="repeat-password"
          required
        >  
      </div>
      <div v-if="passwordMissmatch" class="error">Password mismatch</div>
      <button type="submit">Reset password</button>
    </form>
    <div v-if="resetError" class="error">Something went wrong</div>
  </div>
</template>

<script>
import { resetPassword } from '../services/api'
import { ROUTE_NAMES } from '../settings/routerSettings'
export default {
  data() {
    return {
      resetError: false,
      passwordMissmatch: false,
    }
  },
  methods: {
    onSubmit(e) {
      const newPassword = e.target.elements.password.value
      const repeatPassword = e.target.elements['repeat-password'].value
      if (newPassword !== repeatPassword) {
        this.passwordMissmatch = true
        return
      }
      resetPassword(this.$attrs.username, newPassword, this.$attrs.token)
        .then(response => {
          this.$store.dispatch('login', response.data)
          this.$router.push({ name: ROUTE_NAMES.MainPage })
        })
        .then(() => {
          this.$router.push({ name: ROUTE_NAMES.Login })
        })
        .catch(() => {
          this.resetError = true
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.reset-password-page
  display: flex
  justify-content: center
  align-items: center
  height: 100%

form
  width: vw(275px)

  label
    m-font("Lelo", "light")
    m-font-size(15, 20)
    display: block
    margin-bottom: vw(10px)

  input
    m-font("Lelo", "light")
    margin-bottom: vw(20px)
    padding: vw(5px)
    width: 100%
    border: 1px solid #000
    font-weight: 300

    &::placeholder
      color: #E0E0E0

  button
    m-font("Lelo", "light")
    m-font-size(12, 14)
    margin-top: vw(20px)
    padding-top: 1.2em
    width: 100%
    text-transform: uppercase

.error
  m-font-size(12, 16)
  color: red
</style>

<template>
  <div v-click-outside="close" class="date-range-picker">
    <div :class="open ? 'toggle toggle__opened' : 'toggle'" @click="open=!open">
      <span v-if="currentOption" :class="open ? 'option-selected' : 'option-selected option-selected__opened'">{{ prettyOption(currentOption) }}</span>
      <span class="range-selected">{{ prettyRange(internalRange) }}</span>
      <div :class="open ? 'drop-down-arrow drop-down-arrow__opened' : 'drop-down-arrow'" />
    </div>
    <div v-if="open" class="popup">
      <div class="left">
        <div class="options">
          <div
            v-for="option in rangeOptions"
            :key="option.id"
            class="range-option"
            :class="{current: currentOption === option, disabled: option.disabled}"
            @click="setOption(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <div class="right">
        <v-date-picker
          v-model="internalRange"
          is-range
          :rows="2"
          timezone="utc"
          :step="1"
          :min-date="minmaxRange.start"
          :first-day-of-week="2"
          :max-date="minmaxRange.end"
          :attributes="calendarAttributes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import ClickOutside from 'vue-click-outside'
import { setUTCHHMMSSToZero } from '../utils/filterUtils'

dayjs.Ls.en.weekStart = 1
/** @enum */
const RangeOptionEnum = {
  Today:'today',
  Yesterday:"yesterday",
  ThisWeek:"this_week",
  LastWeek:"last_week",
  ThisMonth:"this_month",
  LastMonth:"last_month"
  
  
}
export default {
  directives: {
    ClickOutside,
  },
  model: {
    prop: 'range',
    event: 'change',
  },
  props: {
    range: {
      type: Object,
    },
    minmaxRange: Object,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    calendarAttributes() {
      return [
        {
          key: 'today',
          highlight: {
            color: 'red',
            fillMode: 'outline',
            contentClass: 'italic',
          },
          dates: this.today,
        },
      ]
    },
    yesterday() {
      return {
        id: RangeOptionEnum.Yesterday,
        label: 'Yesterday',
        disabled: false,
        start: dayjs().utc().subtract(1, 'day').toDate(),
        end: dayjs().utc().subtract(1, 'day').toDate(),
      }
    },
    thisWeek() {
      const start = this.getFirstAvailableDate(dayjs().utc().startOf('week').toDate())

      return {
        id: RangeOptionEnum.ThisWeek,
        label: 'This week',
        disabled: dayjs().day() === 1,
        start,
        end: dayjs().subtract(1, 'day').toDate(),
      }
    },
    lastWeek() {
      const start = this.getFirstAvailableDate(
        dayjs().utc().startOf('week').subtract(1, 'week').toDate()
      )

      const end = this.getFirstAvailableDate(
        dayjs().utc().startOf('week').subtract(1, 'days').toDate()
      )
      return {
        id: RangeOptionEnum.LastWeek,
        label: 'Last week',
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    thisMonth() {
      const start = this.getFirstAvailableDate(dayjs().utc().startOf('month').toDate())
      const end = this.getFirstAvailableDate(dayjs().utc().subtract(1, 'day').toDate())
      return {
        id: RangeOptionEnum.ThisMonth,
        label: 'This month',
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    lastMonth() {
      const start = this.getFirstAvailableDate(
        dayjs().utc().startOf('month').subtract(1, 'month').toDate()
      )
      const end = this.getFirstAvailableDate(
        dayjs(this.today).utc().startOf('month').subtract(1, 'day').toDate()
      )
      return {
        id: RangeOptionEnum.LastMonth,
        label: 'Last month',
        disabled: end <= this.minmaxRange.start,
        start,
        end,
      }
    },
    rangeOptions() {
      const rangeOptions = [
        this.yesterday,
        this.thisWeek,
        this.lastWeek,
        this.thisMonth,
        this.lastMonth,
      ]
      return rangeOptions
    },
    internalRange: {
      get() {
        return {
          start:
            this.range.start.getTime() > this.minmaxRange.start.getTime()
              ? this.range.start
              : this.minmaxRange.start,
          end:
            this.range.end.getTime() < this.minmaxRange.end.getTime()
              ? this.range.end
              : this.minmaxRange.end,
        }
      },
      set(ob) {
        // FUCKOFF, esta "bien" pero no veo manera de "cancelar el +1" de la timezone (ojo! es +2 en verano)
        // esto de add y subtract 2 horas es para pedir siempre el from i to del top con sentido des de bcn...
        // (puesto q el backend coge la date sin horas y devuelve inclusivo el frrom i el to)
        let normalized = {
          start: setUTCHHMMSSToZero(new Date(ob.start)),
          end: setUTCHHMMSSToZero(new Date(ob.end)),
        }
        this.$emit('change', normalized)
      },
    },
    currentOption() {
      return this.rangeOptions.find(o => {
        return o.start === this.range.start && o.end === this.range.end
      })
    },
    maxDate() {
      return dayjs(this.today).endOf('month').toDate()
    },
  },
  watch: {
    // ALL OPTION DISABLED ¿?
    // minmaxRange: {
    //   handler() {
    //     // this.setOption(this.rangeOptions[1])
    //     //MENTIRA, pero por ahora "mejor" creo...
    //     // this.internalRange = {
    //     //   end: this.minmaxRange.end,
    //     //   // start: dayjs(this.minmaxRange.end)
    //     //   //   .subtract(7, 'day')
    //     //   //   .toDate(),
    //     //   start: this.minmaxRange.start,
    //     // }
    //     // ALL OPTION DISABLED ¿?
    //     // let allOpt = this.rangeOptions.find(o => o.id === RangeOptionEnum.ThisMonth)
    //     // allOpt.start = this.minmaxRange.start
    //     // allOpt.end = this.minmaxRange.end
    //   },
    //   immediate: true,
    // },
    internalRange() {
      this.open = false
    },
  },
  mounted() {
    // this.setOption(this.lastWeek)
  },
  methods: {
    setOption(o) {
      // this.$emit('change', { start: o.start, end: o.end })
      this.internalRange = { start: o.start, end: o.end }
    },
    getFirstAvailableDate(date) {
      return date < this.minmaxRange.start ? this.minmaxRange.start : date
    },
    prettyOption(o) {
      return o.label
    },
    prettyRange(r) {
      const now = new Date()
      const startLabel =
        r.start.getFullYear() !== now.getFullYear()
          ? dayjs(r.start).utc().format('MMM D, YYYY')
          : dayjs(r.start).utc().format('MMM D')
      const endLabel =
        r.end.getFullYear() !== now.getFullYear()
          ? dayjs(r.end).utc().format('MMM D, YYYY')
          : dayjs(r.end).utc().format('MMM D')
      return startLabel + ' - ' + endLabel
    },
    close() {
      this.open = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.date-range-picker
  position: relative
  background-color: #fff
  font-family: "Lelo"

  .drop-down-arrow
    position: absolute
    top: 50%
    right: 0
    width: $multiselect-height
    height: $multiselect-height
    transform: translateY(-50%)

    &:before
      m-icon("drop-down", 20)
      position: absolute
      top: 50%
      left: 50%
      content: ""
      transition: transform 0.2s
      transform: translate(-50%, -50%)

  .drop-down-arrow__opened
    &:before
      transform: rotate(180deg) translate(50%, 50%)

  .toggle
    display: flex
    align-items: center
    padding-right: $multiselect-height
    padding-left: 8px
    height: 100%
    border: 1px solid #000

  .toggle__opened
    background-color: $alabaster
    color: black

  .option-selected
    m-font-size(12, 15)
    margin-right: 10px

  .range-selected
    m-ellipsis(100%)
    m-font-size(14, 20)
    font-weight: 300

  .popup
    position: absolute
    top: 100%
    right: 0
    z-index: 10
    display: flex
    border-radius: 7px
    background-color: $mercury
    box-shadow: 0 1px 4px #bbb

    .left,
    .right
      min-width: 150px
      width: auto

    .left
      .options
        .range-option
          m-font-size(20, 22)
          padding: 15px

          &.current
            font-weight: bold

          &.disabled
            opacity: 0.5
            pointer-events: none

        .range-option:hover
          border-radius: 7px
          background-color: $alto

  >>> .italic
    font-style: italic
</style>
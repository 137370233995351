<template>
  <div class="evolutives">
    <Section
      :id="'evolutives'"

      :button-filters="true"
      :all-fillter="false"
      :searchable="false"
      :current-type="currentType"
      @change-type="currentType=$event"
    >
      <!-- <span class="graph-title">{{ currentType }}</span> -->
      <MultiGraph
        v-if="evolutivesData&& $asyncComputed.evolutivesData.success"
        :show-best-mark="true"
        :data-props="evolutivesData"
        :ref-data-props="null"
        :area="false"
      />
      <Loader v-else />
    </Section>
  </div>
</template>

<script>
import { getEvolutive } from '@/services/api'
import { mapGetters } from 'vuex'
import Section from '@/components/productInsights/Section.vue'
import MultiGraph from '@/components/productInsights/MultiGraph.vue'
import Loader from '../Loader.vue'
import { getTopEntities } from '../../services/api'
import InView from '@/mixins/InView.js'
export default {
  components: {
    Section,
    MultiGraph,
    Loader,
  },
  mixins: [InView],
  data() {
    return {
      currentType: 'FRAGRANCE',
    }
  },
  computed: {
    ...mapGetters(['filters']),
    // trending() {
    //   return this.trendingNow.brand
    // },
  },
  asyncComputed: {
    topEntities() {
      return this.isInView && getTopEntities(this.currentType, this.filters, null, { limit: 10 })
    },
    evolutivesData() {
      if (!this.topEntities || this.$asyncComputed.topEntities.updating) return false
      return getEvolutive(
        this.currentType,
        this.topEntities.map(d => d.id),
        this.filters
      ).then(this.setLinesValues)
    },
  },
  methods: {
    setLinesValues(lines) {
      lines.forEach(line => {
        line.values.forEach(d => {
          d.value = d.vPopIndex
        })
        line.datum = this.topEntities.find(e => e.id === parseInt(line.id))
      })
      return lines
    },
  },
}
</script>

<style lang="stylus" scoped>
.evolutives
  position: relative

.graph-title
  margin-top: 10px
  width: 100%
  text-align: center
  font-family: "Lelo"
  m-font-size(20, 26)
  font-weight: 400
</style>
<template>
  <div class="view ingredients">
    <ingredients-cloud :selected-pair="currentPair" @select-pair="(pair)=>currentPair=pair" />
    <ingredient-pair-panel v-if="currentPair" :pair="currentPair" @close="currentPair=null" />
  </div>
</template>

<script>
import IngredientsCloud from '@/components/IngredientsCloud.vue'
import IngredientPairPanel from '../components/IngredientPairPanel.vue'
export default {
  components: { IngredientsCloud, IngredientPairPanel },
  data() {
    return {
      currentPair: null,
    }
  },
}
</script>

<style lang="stylus" scoped>
.ingredients
  position: relative
  display: flex
  flex: 1 1 0%
  overflow: hidden

  .ingredient-pair-panel
    position: absolute
    top: vw(20px)
    right: vw(20px)
    box-shadow: 0 4px 8px rgba(#000, 0.1)
</style>

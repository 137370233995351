import i18n from './modules/i18n'
import retailer from './modules/retailer'
import productData from './modules/product-data'
import user from './modules/user'


import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18n,
    retailer,
    productData,
    user
  },
})

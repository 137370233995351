<template>
  <InsightDisplay
    :insight="data"
    :compact="compact"
    :dark-background="darkBackground"
    :evolutive-kpis="evolutiveKpis"
    :display-kpi="displayKpi"
  >
    <template v-slot:thumbnail>
      <div class="brand-name">{{ data.name }}</div>
    </template>
  </InsightDisplay>
</template>
<script>
import InsightDisplay from '../InsightDisplay'
export default {
  components: { InsightDisplay },
  props: ['data', 'darkBackground', 'evolutiveKpis', 'displayKpi', 'compact'],
}
</script>
<style lang="stylus" scoped></style>
import { ROUTE_NAMES } from './routerSettings'

/**
 * string in DB asociated with Router
 * @enum
 * */
export const requiredAllowedViews = {
  SensorEcosystem: ROUTE_NAMES.SensorEcosystem,
  EntityCompareView: ROUTE_NAMES.ProductInsights,
  ProductInsights: ROUTE_NAMES.ProductInsights,
  Ingredients: ROUTE_NAMES.Ingredients,
  Funnel: ROUTE_NAMES.Funnel,
  TopChosen: ROUTE_NAMES.TopChosen,
}
export const defaultAllowdViews = process.env.VUE_APP_DEFAULT_ALLOWED_VIEW.split(',')
/**
 * ¿User has permission to access to route with name routeName?
 * @param {{allowedViews:(keyof typeof requiredAllowedViews)[]}} user
 * @param {ROUTE_NAMES} routeName
 */
export function hasPermission (user, routeName) {
  if (!Object.values(requiredAllowedViews).includes(routeName)) return true
  if (!user) return false
  const allowedViews = user.allowedViews
  return allowedViews.includes(routeName)
}

export default {
  mounted () {
    var observer = new IntersectionObserver(changes => {
      for (const change of changes) {
        // console.log(change.time) // Timestamp when the change occurred
        // console.log(change.rootBounds) // Unclipped area of root
        // console.log(change.boundingClientRect) // target.boundingClientRect()
        // console.log(change.intersectionRect) // boundingClientRect, clipped by its containing block ancestors, and intersected with rootBounds
        // console.debug(change.intersectionRatio) // Ratio of intersectionRect area to boundingClientRect area
        // console.log(change.target) // the Element target
        if (change.intersectionRatio > 0) this.isInView = true
      }
    }, {})

    // Watch for intersection events on a specific target Element.
    observer.observe(this.$el)
  },
  data () {
    return {
      isInView: false,
    }
  },
}

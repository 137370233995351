<template>
  <div class="login-page">
    <div class="login-container">
      <div v-if="!isRecovering" class="login-inner">
        <div class="login-title">Welcome Back</div>
        <div class="login-subtitle">Welcome back, please enter your details.</div>
        <form method="post" @submit.prevent="onSubmit">
          <label for="username">Username</label>
          <input
            type="text"
            placeholder="Enter Username"
            name="username"
            autocomplete="username"
            required
          >
          <label for="password">Password</label>
          <input
            type="password"
            placeholder="Enter Password"
            name="password"
            autocomplete="current-password"
            required
          >
          <a class="forgot-password" @click="onForgotPassword">Forgot password?</a>
          <button type="submit">Login</button>
          <p v-if="invalidCredentials" class="login-error">
            Your username or password are incorrect.
          </p>
          <p v-if="loginError" class="login-error">There was an error while logging in</p>
        </form>
      </div>
      <div v-else class="recover-container">
        <div v-if="startRecoverSuccess" class="login-subtitle">
          Check your email to reset your password.
        </div>
        <div v-else class="recover-inner">
          <div class="login-title">Forgot your password?</div>
          <div class="login-subtitle">
            Submit your username and an email<br>will be sent with a link to reset yor password.
          </div>
          <form @submit.prevent="handleStartRecovery">
            <input
              type="text"
              placeholder="Enter Username"
              name="username"
              required
            >
            <button type="submit">Send email</button>
          </form>
        </div>
        <p v-if="startRecoverError" class="login-error">Something went wrong</p>
      </div>
      <!-- <div class="logo-puig" /> -->
    </div>
    <div class="login-video">
      <video
        class="background-video"
        autoplay
        muted
        loop
        playsinline
        src="@/assets/login-video.mp4"
      >
        <source src="@//assets/login-video.mp4" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
import { login, startRecover } from '../services/api'
import { ROUTE_NAMES } from '../settings/routerSettings'
export default {
  data() {
    return {
      isRecovering: false,
      loginError: false,
      invalidCredentials: false,
      startRecoverSuccess: false,
      startRecoverError: false,
    }
  },
  methods: {
    onSubmit(e) {
      const username = e.target.elements.username.value
      const password = e.target.elements.password.value
      login(username, password)
        .then(response => {
          this.invalidCredentials = false
          this.loginError = false
          this.$store.dispatch('login', response.data)
          this.$router.push({ name: ROUTE_NAMES.MainPage })
        })
        .catch(e => {
          if (e.status === 401) {
            this.invalidCredentials = true
          } else {
            this.loginError = true
          }
        })
    },
    onForgotPassword() {
      this.isRecovering = true
    },
    handleStartRecovery(e) {
      const username = e.target.elements.username.value
      this.startRecoverError = false
      this.startRecoverSuccess = false
      startRecover(username)
        .then(() => {
          this.startRecoverSuccess = true
        })
        .catch(() => {
          this.startRecoverError = true
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.login-page
  display: flex
  overflow: hidden
  width: 100%
  height: 100%

.login-container
  position: relative
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: center
  align-items: center

.login-inner
  display: flex
  flex-direction: column
  align-items: center

.login-title
  m-font-size(50, 60)
  margin-bottom: vw(25px)

.login-subtitle
  m-font-size(14, 20)
  margin-bottom: vw(40px)
  color: #828282

form
  width: vw(275px)

  label
    m-font("Lelo", "light")
    m-font-size(15, 20)
    display: block
    margin-bottom: vw(10px)

  input
    m-font("Lelo", "light")
    margin-bottom: vw(20px)
    padding: vw(5px)
    width: 100%
    border: 1px solid #000
    font-weight: 300

    &::placeholder
      color: #E0E0E0

  button
    m-font("Lelo", "light")
    m-font-size(12, 14)
    margin-top: vw(20px)
    padding-top: 1.2em
    width: 100%
    text-transform: uppercase

.forgot-password
  m-font("Lelo", 500)
  m-font-size(15, 18)
  color: #828282

// .logo-puig
// position: absolute
// right: vw(40px)
// bottom: vw(40px)
// width: vw(90px)
// height: vw(20px)
// background-image: url("~@/assets/logo-puig.svg")
// background-position: center center
// background-size: contain
// background-repeat: no-repeat
.login-video
  position: relative
  flex: 1 1 0%
  overflow: hidden

  video
    position: absolute
    top: 0
    right: 0
    height: 100%

.recover-inner
  display: flex
  flex-direction: column
  align-items: center

  .login-subtitle
    text-align: center

.error
  m-font-size(12, 16)
  color: red
</style>

<template>
  <div class="top-chosen-main">
    <loader v-if="loadingData" />
    <div v-else-if="dataAvailable" class="cards">
      <div v-for="card in values" :key="`card_${card.id}`" class="card">
        <div class="card-title">{{ $t(getCardTitleLabelKey(card)) }}</div>
        <ul class="cards-list">
          <li v-for="(value, i) in card.values" :key="`card_value_${i}`" class="cards-item">
            <div v-if="shouldUseDictionary(card)" class="cards-item-left">
              {{ $t(`funnel_card_value_label_${value.value}`) }}
            </div>
            <div v-else class="cards-item-left">{{ value.value }}</div>
            <div class="cards-item-right">{{ value.count }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      {{ $t('funnel_top_chosen_no_data') }}
    </div>
  </div>
</template>
<script>
import {
  getTopValues,
  getPacoConcepts,
  getPacoIntensities,
  getPacoFamilies,
  getPacoCatalog,
  getFamilies,
  getCartridgeNames
} from '@/services/api'
import Loader from '../Loader.vue'
export default {
  components: {
    Loader,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    contextEvents: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  asyncComputed: {
    topValues() {
      return getTopValues(this.filters)
    },
    pacoConcepts() {
      return this.usePacoInfo && getPacoConcepts()
    },
    pacoIntensities() {
      return this.usePacoInfo && getPacoIntensities()
    },
    pacoCollections() {
      return this.usePacoInfo && getPacoCatalog()
    },
    pacoFamilies() {
      return this.usePacoInfo && getPacoFamilies()
    },
    families() {
      return getFamilies()
    },
    cartridges() {
      return getCartridgeNames()
    }
  },
  computed: {
    loadingData() {
      return (
        this.$asyncComputed.topValues.updating ||
        this.$asyncComputed.pacoConcepts.updating ||
        this.$asyncComputed.pacoIntensities.updating ||
        this.$asyncComputed.pacoCollections.updating ||
        this.$asyncComputed.pacoFamilies.updating
      )
    },
    pacoAttitudes() {
      return (
        this.pacoFamilies &&
        this.pacoFamilies
          .map(f => f.pacoAttitude)
          .reduce((acc, val) => {
            const newAttitudes = []
            val.male && newAttitudes.push(val.male)
            val.female && newAttitudes.push(val.female)
            val.unisex && newAttitudes.push(val.unisex)
            return [...acc, ...newAttitudes]
          }, [])
      )
    },
    dataAvailable() {
      if (this.usePacoInfo) {
        return this.topValues && this.topValues.length > 0 && this.pacoConcepts
      }
      return this.topValues && this.topValues.length > 0
    },
    usePacoInfo() {
      return this.contextEvents.some(ce => ce.usePacoInfo)
    },
    values() {
      return this.topValues.map(card => {
        const contextEvent = this.contextEvents.find(ce => ce.key === card.key)
        if (contextEvent.usePacoInfo) {
          return this.getPacoEntity(card, contextEvent.entityType)
        }
        if (contextEvent.useClientEntities)
          switch (contextEvent.entityType) {
            case 'FAMILY_PAIR': return this.getFamilyPair(card)
            case 'CARTRIDGE_PAIR': return this.getCartridgePair(card)
            case 'CARTRIDGE': return this.getCartridgePair(card)
            default:
              return card
          }
        else
          return card
      })
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getFamilyPair(card) {
      const values = card.values.map(valueObj => {
        const ids = valueObj.value.split('_')
        return {
          ...valueObj,
          value: ids.map(id => this.families?.find(family => family.id === Number(id))?.name).join('/')
        }
      })
      return {
        ...card,
        values
      }
    },
    getCartridgePair(card) {
      if (!this.cartridges) return card
      const values = card.values.map(valueObj => {
        const ids = valueObj.value.split('_')
        return {
          ...valueObj,
          value: ids.map(id => this.cartridges?.find(cartridge => cartridge.perfume_id === Number(id))?.en ?? id).join('/')
        }
      })
      return {
        ...card,
        values
      }
    },
    getPacoEntity(card, entityType) {
      let entities
      switch (entityType) {
        case 'ATTITUDE':
          entities = this.pacoAttitudes
          break
        case 'CONCEPT':
          entities = this.pacoConcepts
          break
        case 'INTENSITY':
          entities = this.pacoIntensities
          break
        case 'COLLECTION':
          entities = this.pacoCollections
          break
        default:
          return card
      }
      return {
        ...card,
        values: card.values.map(value => {
          const entity = entities.find(e => e.id === parseInt(value.value))
          return {
            ...value,
            value: this.capitalizeFirstLetter(entity.name),
          }
        }),
      }
    },
    shouldUseDictionary(card) {
      return this.contextEvents.find(e => e.key === card.key).useDictionary
    },
    getCardTitleLabelKey(card) {
      switch (card.key) {
        case 'gender':
          return 'funnel_top_chosen_gender_title'
        case 'exploreBy':
          return 'funnel_top_chosen_explore_by_title'
        case 'addFamilyToProfile':
          return 'funnel_top_chosen_add_family_to_profile_title'
        case 'setIntensity':
          return 'funnel_top_chosen_set_intensity_title'
        case 'addIngredientToProfile':
          return 'funnel_top_chosen_add_ingredient_to_profile_title'
        case 'addAttitudeToProfile':
          return 'funnel_top_chosen_add_attitude_to_profile_title'
        case 'addConceptToProfile':
          return 'funnel_top_chosen_add_concept_to_profile_title'
        case 'buyPerfumeActionPaco':
        case 'buyPerfumeAction':
          return 'funnel_top_chosen_buy_perfume_title'
        case 'actionOptionSelected':
          return 'funnel_top_chosen_action_option_title'
        case 'collectionViewed':
          return 'funnel_top_chosen_collection_viewed_title'
        case 'viewPerfumePaco':
        case 'viewPerfume':
          return 'funnel_top_chosen_view_perfume_paco_title'
        case 'purchasing':
          return 'funnel_top_chosen_view_purchasing_title'
        case 'addCartridgePairToProfile':
          return 'funnel_top_chosen_view_cartridge_pair_title'
        case 'selectFamily':
          return 'funnel_top_chosen_view_family_title'
        case 'selectSubFamily':
          return 'funnel_top_chosen_view_subfamily_title'
        case 'likely':
          return 'funnel_top_chosen_view_likely_title'
        case 'lang':
          return 'funnel_top_chosen_view_lang_title'
        case 'addCartridgeToProfile':
          return 'funnel_top_chosen_view_addCartridgeToProfile_title'
        case 'classification':
          return 'funnel_top_chosen_view_classification_title'
        case 'removeCartridgeFromProfile':
          return 'funnel_top_chosen_view_removeCartridgeFromProfile_title'
        case 'addFamilyPairToProfile':
          return 'funnel_top_chosen_view_addFamilyPairToProfile_title'
        case 'smellPerfume':
          return 'funnel_top_chosen_view_smellPerfume_title'
        default:
          return card.key
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.top-chosen-loader
  height: vw(100px)

.top-chosen-title
  m-font-size(42, 59)
  position: relative
  margin-bottom: vw(50px)

  &:after
    display: block
    margin-top: vw(10px)
    height: 1px
    background-color: #000
    content: ""
    wisth: 100%

.cards
  display: grid
  grid-gap: vw(30px)
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

.card
  padding: vw(30px) vw(35px)
  border: 1px solid #000

.card-title
  m-font-size(18, 25)
  margin-bottom: vw(15px)
  font-weight: 500

.cards-list
  overflow-y: auto
  max-height: vw(450px)

  .comparing &
    max-height: vw(375px)

.cards-item
  display: flex
  align-items: center
  border-bottom: 1px solid #828282

  &:not(:last-child)
    margin-bottom: vw(10px)

.cards-item-left
  m-font-size(16, 20)
  flex: 1 1 0%
  margin-top: vw(5px)
  margin-right: vw(40px)
  margin-bottom: vw(5px)

.cards-item-right
  m-font-size(20, 30)
  align-self: flex-end
</style>
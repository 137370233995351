import { mapGetters } from 'vuex'
import { applyFilters, getSettingByRoute } from '../settings/filterApplySettings'

export default creteGetFilterBySettingMixin('topFilters')
export function creteGetFilterBySettingMixin(field) {
  return {
    computed: {
      ...mapGetters({ _topFiltersRaw: 'topFilters' }),
      [field]() {
        const setting = getSettingByRoute(this.$route)
        if (!setting) return {}
        return applyFilters(this._topFiltersRaw, setting.id)
      },
    },
  }
}

<template>
  <div class="spotlight-display">
    <div v-if="title" class="spotlight-display__title">{{ title }}</div>
    <div class="spotlight-display__wrap">
      <div class="spotlight-display__thumbnail-container">
        <div class="spotlight-display__thumbnail">
          <slot name="thumbnail" />
        </div>
        <div class="spotlight-display__description">
          <slot name="description" />
        </div>
      </div>
      <div v-if="indicatorsElements" class="spotlight-display__graphs">
        <IndicatorCard
          v-for="indicator in indicatorsElements"
          :key="indicator.id"
          :type="indicator.id"
          :compact="true"
          :label="indicator.label"
          :value="indicator.value"
          :vs-last-period="indicator.vsLastPeriod"
          :trend="indicator.trend"
          @showEvol="showEvol"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { getEvolutive, getTopEntities, getTopEntitiesWithVariations } from '../../../services/api'
import IndicatorCard from '../IndicatorCard.vue'
// import { transformToPreviousPeriod } from '@/utils/filterUtils'
import IndicatorMixin, { indLabels } from '../../../mixins/IndicatorMixin'
// const INDICATORS = [
//   {
//     label: 'Recommended',
//     id: 'vRecommended',
//   },
//   {
//     label: 'Interacted',
//     id: 'vInteraction',
//   },
// ]
export default {
  components: {
    IndicatorCard,
  },
  mixins: [IndicatorMixin],
  props: {
    title: String,
    type: String,
    spotlight: {
      type: Object,
      required: true,
    },
    kpis: Array,
  },
  computed: {
    ...mapGetters(['filters']),
  },
  asyncComputed: {
    indicatorsElements() {
      return this.kpis.map(kpi => {
        return {
          id: kpi,
          label: indLabels[kpi],
          value: this.spotlight[kpi],
          variation: 0,
          vsLastPeriod: this.spotlight.variations[kpi].vsLastPeriod,
          trend: this.spotlight.variations[kpi].trend,
        }
      })
      // let filters = { ...this.filters, id: [this.spotlight.id] }
      // let kpis = this.getPopRelatedKpis(this.spotlight.entityType)
      // let previousFilters = transformToPreviousPeriod(this.filters)
      // return getTopEntitiesWithVariations(
      //   this.spotlight.entityType,
      //   filters,
      //   previousFilters,
      //   kpis
      // ).then(data => {
      //   // return data[0]
      //   return kpis.map(kpi => {
      //     return {
      //       id: kpi,
      //       label: indLabels[kpi],
      //       value: data[0][kpi],
      //       variation: data[0].variations[kpi].vsLastPeriod,
      //       vsLastPeriod: data[0].variations[kpi].vsLastPeriod,
      //       trend: data[0].variations[kpi].trend,
      //     }
      //   })
      // })
      // return getEvolutive(this.spotlight.entityType, this.spotlight.id, this.filters).then(resp => {

      //   return getAllIndicatorsData(resp, null)
      // })

      // PETA PORQUE HECOTRI AUN NO ACEPTA IDS en el "ettopentities!"
      // return Promise.all([
      //   getTopEntities(this.spotlight.entityType, { ...this.filters, id: [this.spotlight.id] }),
      //   getTopEntities(this.spotlight.entityType, {
      //     ...transformToPreviousPeriod(this.filters),
      //     id: [this.spotlight.id],
      //   }),
      //   // getEvolutive(this.spotlight.entityType, this.spotlight.id, this.filters),
      // ]).then(resp => {
      //   let finals = resp[0].find(d => d.id === this.spotlight.id)
      //   let vars = resp[1].find(d => d.id === this.spotlight.id)
      //   let evols = resp[2] ?? []
      //   return this.getPopRelatedKpis(this.spotlight.entityType).map(kpi => {
      //     return {
      //       id: kpi,
      //       label: indLabels[kpi],
      //       evolutive: evols.map(d => {
      //         return {
      //           date: d.date,
      //           value: d[kpi],
      //         }
      //       }),
      //       value: finals[kpi],
      //       variation: vars ? finals[kpi] / (vars[kpi] || 0) : 0,
      //     }
      //   })
      // })
    },
  },

  methods: {
    showEvol() {
      this.$modal.show('evolutive', {
        evolConfig: {
          kpi: 'vPopIndex',
          type: this.spotlight.entityType,
          filters: this.filters,
          id: this.spotlight.id,
          values: ['vPopIndex', ...this.getPopRelatedKpis(this.spotlight.entityType)],
        },
        panelConfig: {
          entity: this.spotlight,
          // variations: this.spotlight.variations['vPopIndex'],
        },
      })
    },
  },
}
</script>
<style lang="stylus">
.spotlight-display
  display: flex
  flex: 2 1 0%
  flex-direction: column

.spotlight-display__wrap
  display: flex
  flex: 1 1 0%
  flex-direction: column

.spotlight-display__title
  m-font-size(12, 14)
  margin-bottom: vw(30px)
  font-family: "Lelo"

.spotlight-display__thumbnail-container
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: vw(50px)

.spotlight-display__thumbnail
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: vw(20px)

  .brand-name
    m-font-size(80, 90)
    text-align: center

  .families-donut
    width: vw(160px)
    height: vw(160px)

  .img-circle
    width: vw(160px)
    height: vw(160px)
    border-radius: 50%
    object-fit: cover

.spotlight-display__description
  m-font-size(14, 16)
  text-align: center

.spotlight-display__graphs
  $margin = vw(-10px)
  display: flex
  flex-wrap: wrap
  margin-top: auto
  margin-right: $margin
  margin-left: $margin

  // .indicator-card
  // height: vw(90px)
  .indicator-card--compact
    $margin = vw(10px)
    $percentage = percentage((1 / 2))
    $width = "calc(%s - (%s * 2))" % ($percentage $margin)
    flex: 0 0 $width
    margin: $margin
    border-top: none
    border-right: none
    border-left: none

    .indicator-card__content
      .percentage-container
        flex-direction: column

  .indicator-card__header
    h6
      m-font-size(12, 14)

  .indicator-card__content
    display: flex
    flex-direction: row
    justify-content: space-between

  .indicator-card__graph
    width: 70%

    // height: 45px
    .graph
      min-height: 35px
</style>
<template>
  <div v-if="cards" class="funnel-cards">
    <div
      v-for="card in filteredCards"
      :key="card.key"
      class="funnel-card"
      :class="card && card.highlight ? 'highlight':''"
    >
      <div class="funnel-card-title">{{ card.label }}</div>
      <div class="funnel-card-number">{{ card.value }}</div>
    </div>
  </div>
</template>
<script>
import { getTopEntities } from '@/services/api'
import { indLabels } from '../../utils/indicatorUtils'
import { FunnelTableColumns, findIndexField } from '../../settings/funnelSettings'
export default {
  props: {
    funnelHierarchy: {
      type: Object,
      required: true,
    },
    branchMatchingNodes: {
      type: Array,
      required: true,
    },
    funnelNodeTree: {
      type: Object,
      required: true,
    },
    tableValues: {
      type: Array,
      required: true,
    },
    funnelData: {
      type: Array,
      required: true,
    },
    successRate: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    /** Task #294: @see https://code.bestiario.org/devel/puig/DoD/-/issues/294 */
    isInstore:{
      type: Boolean,
      required: true
    }
  },
  computed: {
    rootStepData() {
      return this.funnelData.find(d => d.id === this.funnelHierarchy.data.id)
    },
    filteredCards() {
      return this.cards.filter(c => c.value)
    },
    airParfumCards(){
      const indexSessionDuration = findIndexField(FunnelTableColumns.SessionDuration,{
        isInstore: this.isInstore
      })
      return this.topEntities && [{
            label: indLabels['vSessions'],
            value: this.rootStepData ? this.rootStepData.sessions : null,
          },
          {
            label: indLabels['vBounceRate'],
            value: `${this.topEntities.vBounceRate.toFixed(2)}%`,
          },
          {
            label: indLabels['vConversionRate'],
            value: this.conversionRate,
            highlight: true,
          },
          {
            label: indLabels['vAvgSessionDuration'],
            value: new Date(this.tableValues.map(v => v[indexSessionDuration]).reduce((a, b) => a + b, 0) * 1000)
              .toISOString()
              .substring(14, 19),
          }]
    },
    cards() {
      if(this.isInstore) return this.airParfumCards
      const indexSessionDuration = findIndexField(FunnelTableColumns.SessionDuration,{
        isInstore: this.isInstore
      })
      return (
        this.topEntities && [
          {
            label: indLabels['vSessions'],
            value: this.rootStepData ? this.rootStepData.sessions : null,
          },
          {
            label: indLabels['vUniqueVisits'],
            value: this.rootStepData ? this.rootStepData.users : null,
          },
          {
            label: indLabels['vBounceRate'],
            value: `${this.topEntities.vBounceRate.toFixed(2)}%`,
          },
          {
            label: indLabels['vConversionRate'],
            value: this.conversionRate,
            highlight: true,
          },
          {
            label: indLabels['vAvgSessionDuration'],
            value: new Date(this.tableValues.map(v => v[indexSessionDuration]).reduce((a, b) => a + b, 0) * 1000)
              .toISOString()
              .substring(14, 19),
          },
        ]
      )
    },
    conversionRate() {
      // Task #294  If any sensor on the funnel has technology AIR_PARFUM,%ARRIVAL AND %LOST with respect to # Sessions
      const indexTable = findIndexField(
        this.isInstore ? FunnelTableColumns.Sessions : FunnelTableColumns.Users,
        {
          isInstore: this.isInstore
        })
      const conversionRateNodes = {
        start: this.branchMatchingNodes.find(n => n.data.conversionRateStart),
        end: this.branchMatchingNodes.find(n => n.data.conversionRateEnd),
      }
      if (!conversionRateNodes.start || !conversionRateNodes.end) return null
      const startIndex = conversionRateNodes.start.depth
      const endIndex = conversionRateNodes.end.depth
      const total = (this.tableValues[endIndex][indexTable] * 100) / this.tableValues[startIndex][indexTable]
      return `${total.toFixed(2)}%`
    },
    deepMode() {
      return this.$route.query.deep !== undefined
    },
  },
  asyncComputed: {
    async topEntities() {
      const topEntities = await getTopEntities('SESSION', this.filters)
      return topEntities[0]
    },
  },
}
</script>

<style lang="stylus" scoped>
.funnel-cards
  display: grid
  gap: vw(30px)
  grid-template-columns: repeat(5, 1fr)
  margin-bottom: vw(40px)
  overflow: hidden

  .comparing &
    grid-template-columns: repeat(3, 1fr)

.funnel-card
  border: 1px solid #000
  padding: vw(11px) vw(21px) vw(21px)

  &.highlight
    background-color: rgba(111, 207, 151, 0.2)

.funnel-card-title
  m-font-size(18, 25)
  margin-bottom: vw(10px)

.funnel-card-number
  m-font-size(36, 50)
</style>